import moment, {Moment} from "moment";

export class DisplayServices{
    public static money(value: number | string | null | undefined, decimalPlaces = 2)
    {
        if(value === null || value === undefined){
            return value;
        }
        let cleanedValue = value.toString().trim().replace(/[^0-9\.-]*/, '');
        if(isFinite(+cleanedValue) == false){
            return value;
        }

        let numberWithLimitedDecimal = (+cleanedValue).toFixed(decimalPlaces);
        if(numberWithLimitedDecimal.indexOf(".") == -1 && decimalPlaces > 0){
            numberWithLimitedDecimal = numberWithLimitedDecimal + "." + "0".repeat(decimalPlaces);
        }

        return "$" + this.numberWithCommas(numberWithLimitedDecimal);
    }

    public static date(value?: Date | Moment, format: string = "YYYY-MM-DD")
    {
        if(value === null || value === undefined){
            return value;
        }
        let dateAsMoment: Moment = value as Moment;
        if(value instanceof Date){
            dateAsMoment = moment(value);
        }
        return dateAsMoment.format(format);
    }

    public static datetime(value?: Date | Moment, format: string = "YYYY-MM-DD")
    {
        return this.date(value, "YYYY-MM-DD hh:mm:ss")
    }

    public static numberWithCommas = (x: string) => {
        const parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
}