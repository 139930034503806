import {BaseService} from "./BaseService";
import {useEffect, useState} from "react";
import {CallbackRegistration} from "../Domain/Callbacks/CallbackRegistration";
import {Callback} from "../Domain/Callbacks/Callback";
import { PackSizeInvoiceItem } from "../Domain/PackSize";
import {Tenant} from "../Domain/User";

const _forceCountUpdateListeners: any[] = [];
const _forceCountUpdate = () => {
    _forceCountUpdateListeners.forEach(x => x());
};
export function useCallbackRegistrations(tenant?: Tenant){
    const [registrations, setRegistrations] = useState<CallbackRegistration[]>();
    useEffect(() => {
        const action = async () => {
            if(!tenant) {
                setRegistrations(await BaseService.getToArray(`/api/internalinvoice/callback`, CallbackRegistration));
            }else{
                setRegistrations(await BaseService.getToArray(`/api/internalinvoice/callback/${encodeURIComponent(tenant.id)}`, CallbackRegistration));
            }
        };
        action();
        const listener = () => action();
        _forceCountUpdateListeners.push(listener);
        return () => {_forceCountUpdateListeners.removeItem((x: any) => x === listener)};
    }, [tenant])
    return registrations;
}


export async function addNewCallback(url: string, type: string) {
    const result = await BaseService.postToClass('/api/internalinvoice/callback/', Callback, {url, isActive: true, type});
    _forceCountUpdate();
    return result;
}

export async function updateCallbackRegistration(reg: CallbackRegistration) {
    const result = await BaseService.putToClass(`/api/internalinvoice/callback/${encodeURIComponent(reg.internalId)}/${reg.type}`, Callback, reg.toDTO());
    _forceCountUpdate();
    return result;
}

export class InvoiceService extends BaseService{

    static async getTriesForRegistration(reg: CallbackRegistration, onlyFailed: boolean, start: Date, end: Date) {
        return this.getToArray(`/api/internalinvoice/callback/${encodeURIComponent(reg.internalId)}/${reg.type}/history?onlyFailed=${onlyFailed}&start=${BaseService.toIsoDate(start)}&end=${BaseService.toIsoDate(end)}`, Callback);
    }

    static async retryCallback(cb: Callback) {
        await this.post(`/api/internalinvoice/callback/try/${encodeURIComponent(cb.internalId)}/${cb.type}`)
    }

    static async sendTest(reg: CallbackRegistration) {
        await this.post(`/api/internalinvoice/callback/${encodeURIComponent(reg.internalId)}/${reg.type}/test`)
    }

    static getInvoicesWithPackSizes(packsizeId: string): Promise<PackSizeInvoiceItem[]> {
        return this.getToArray(`/api/internalinvoice/containingPackSize/${encodeURIComponent(packsizeId)}`, PackSizeInvoiceItem);
    }

    static async getAttachmentsForInvoice(externalInvoiceId: string): Promise<string[]> {
        const response = await this.get(`/api/internalinvoice/attachments/${encodeURIComponent(externalInvoiceId)}`);
        return await response!.json();
    }

    static async mergePurchases(toPackSizeId: string, lineItems: PackSizeInvoiceItem[]): Promise<any> {
        return await this.post(`api/internalinvoice/packSizes/mergePurchases/?toPackSizeId=${encodeURIComponent(toPackSizeId)}`, lineItems.map(x => x.toDTO()));
    }

    static async attachmentGroupingUpload(attachmentGroupingUploadData: FormData): Promise<any> {
        return await this.postFormData(`api/internalAttachmentGrouping/attachmentGroupingUpload/`, attachmentGroupingUploadData)
    }
    
    static async addAttachmentToGrouping(multipleAttachmentGroupingUploadData: FormData): Promise<any> {
        return await this.postFormData(`api/internalAttachmentGrouping/attachmentGroupingUpload/additionalFiles`, multipleAttachmentGroupingUploadData)
    }

    static updateLineItem(row: PackSizeInvoiceItem): Promise<PackSizeInvoiceItem> {
        return this.putToClass(`api/internalinvoice/${encodeURIComponent(row.invoiceId)}/item/${encodeURIComponent(row.id)}`, row, row.toDTO());
    }
}