import React, {Dispatch, useCallback, useContext, useEffect, useState} from "react";
import {
    Attachment,
    CategoryOnlyWorkflowRow,
    RelatedWorkflows,
    RowInfo,
    UserGroupedAttachments,
    Workflow,
    WorkflowRow,
    WorkflowStage,
    WorkflowStagesThatCouldShowSingleAttachments
} from "../Domain/Workflow/Workflow";
import {BaseService} from "../Services/BaseService";
import * as FullStory from "@fullstory/browser";
import moment from "moment";
import {UserService} from "../Services/UserService";

interface WorkflowContextInterface {
    workflow?: Workflow | null;
    currentAttachment?: Attachment | null;
    stage?: WorkflowStage;
    setStage: (x: WorkflowStage, level?: number) => void;
    updateWorkflow: (w: Workflow) => Promise<void>;
    regroupWorkflow: (w: Workflow, userGroupedAttachments: Attachment[][]) => void;
    updateAttachment: (a: Attachment, w: Workflow) => void;
    stageCounts: any;
    stageAges: any;
    moveToErrorQueue: (w: Workflow) => void;
    moveToBadPictureQueue: (w: Workflow, reason: string, setterFn: Dispatch<boolean>) => Promise<void>;
    setAttachmentToBadPictureAndGetNextItem: (w: Workflow, a: Attachment, reason: string, setterFn: Dispatch<boolean>) => Promise<void>;
    missingPackSizeCount?: number;
    missingPackSizeCountL2?: number;
    getRowInfo: (supplierId: string, itemCode: string, quantity?: number, total?: number) => Promise<RowInfo>;
    isSafeToSave: boolean;
    addRow: (index?: number) => WorkflowRow;
    addCategoryOnlyRow: (index?: number) => CategoryOnlyWorkflowRow;
    deleteRow: (r: WorkflowRow) => void;
    deleteStandardPackSizeRows: () => void;
    checkForDuplicate: (workflow: Workflow) => Promise<any>;
    checkForDuplicatesInGroupings: (restId: string, attachmentGroups: Attachment[][]) => Promise<boolean>;
    stageLevel: number;
    addDevNote: (wf: Workflow, note: string) => Promise<void>;
    forcePullSpecificWorkflow: (id: string, stage: WorkflowStage) => Promise<void>;
    forceSetNextAttachment: () => void;
    getAttachmentsGroupedByMatchingInfoAsKey: (w: Workflow) => Map<string, Attachment[]>;
    getMatchingInformationFromGrouping: (attachments: Attachment[]) => { supplierId?: string | null, invoiceNumber?: string | null, deliveryDate?: moment.Moment | null, invoiceTotal?: number | null };
    doAttachmentsHaveCompleteInformationInGroup: (attachments: Attachment[]) => boolean;
    reloadWorkflow: () => Promise<void>;
    splitWorkflowImagesAndSendToMISupplierEntry: (w: Workflow) => Promise<void>;
    getEligibleStagesForErrorQueueMovement: () => Promise<WorkflowStage[]>;
    getRelatedWorkflows: (invoiceGroupingOrWorkflowId: string) => Promise<RelatedWorkflows>;
}

const doNothingDefault = () => {
}

export const WorkflowContext = React.createContext<WorkflowContextInterface>({
    setStage: doNothingDefault as any,
    updateWorkflow: doNothingDefault as any,
    regroupWorkflow: doNothingDefault as any,
    updateAttachment: doNothingDefault as any,
    stageCounts: {},
    stageAges: {},
    moveToErrorQueue: doNothingDefault as any,
    moveToBadPictureQueue: doNothingDefault as any,
    setAttachmentToBadPictureAndGetNextItem: doNothingDefault as any,
    getRowInfo: doNothingDefault as any,
    isSafeToSave: true,
    addRow: doNothingDefault as any,
    addCategoryOnlyRow: doNothingDefault as any,
    deleteRow: doNothingDefault as any,
    deleteStandardPackSizeRows: doNothingDefault as any,
    checkForDuplicate: doNothingDefault as any,
    checkForDuplicatesInGroupings: doNothingDefault as any,
    stageLevel: 0,
    addDevNote: doNothingDefault as any,
    forcePullSpecificWorkflow: doNothingDefault as any,
    forceSetNextAttachment: doNothingDefault as any,
    getAttachmentsGroupedByMatchingInfoAsKey: doNothingDefault as any,
    doAttachmentsHaveCompleteInformationInGroup: doNothingDefault as any,
    getMatchingInformationFromGrouping: doNothingDefault as any,
    reloadWorkflow: doNothingDefault as any,
    splitWorkflowImagesAndSendToMISupplierEntry: doNothingDefault as any,
    getEligibleStagesForErrorQueueMovement: doNothingDefault as any,
    getRelatedWorkflows: doNothingDefault as any
});

export const WorkflowContextProvider = ({children}: any) => {
    const [workflow, setWorkflow] = useState<Workflow | null>();
    const [level, setLevel] = useState(0);
    const [stage, setStage] = useState<WorkflowStage>();
    const [stageCounts, setStageCounts] = useState();
    const [queueAges, setQueueAges] = useState();
    const [missingPackSizeCount, setMissingPackSizeCount] = useState();
    const [missingPackSizeCountL2, setMissingPackSizeCountL2] = useState();
    const [outstandingInfoReqs, setOutstandingInfoReqs] = useState(0);
    const [rowCount, setRowCount] = useState<number>(workflow?.rows?.length || 0);
    const [categoryRowCount, setCategoryRowCount] = useState<number>(workflow?.categoryOnlyRows?.length || 0);
    const [currentAttachment, setCurrentAttachment] = useState<Attachment | null>(null);

    const getNextWorkflow = useCallback(async () => {
        if (stage) {
            // This is currently necessary to prevent optimistic concurrency errors
            // Time value should be the lowest value tested that mostly prevents the errors
            await new Promise(resolve => setTimeout(resolve, 10));
            
            const data = await BaseService.post(`/api/invoiceWorkflow/next/${stage}/${level}`);
            if (data!.status == 204) {
                setWorkflow(null);
                setRowCount(0);
                setCategoryRowCount(0);
            } else {
                const wf = new Workflow().updateFromDTO(await data!.json());
                setWorkflow(wf);
                setCurrentAttachment(null);
                setOutstandingInfoReqs(0);
                setRowCount(wf.rows.length);
                setCategoryRowCount(wf.categoryOnlyRows.length);
            }
        }
    }, [stage, level, setWorkflow, setRowCount, setCurrentAttachment, setOutstandingInfoReqs]);

    const splitWorkflowImagesAndSendToMISupplierEntry = useCallback(async (w: Workflow) => {
        const splitEndpoint = `/api/invoiceWorkflow/splitImages/${encodeURIComponent(w.id)}`;
        try {
            await BaseService.post(splitEndpoint);
        }  catch (e) {
            await addDevNote(w, JSON.stringify(e))
            throw e;
        }
        getNextWorkflow();
    }, [stage, workflow]);

    useEffect(() => {
        if (stage) {
            getNextWorkflow();
        }
    }, [level, stage, getNextWorkflow]);

    const addDevNote = async (erroredWorkflow: Workflow, note: string) => {
        await BaseService.post(`/api/InvoiceWorkflow/devNote/${encodeURIComponent(erroredWorkflow.id)}`, note)
    }

    const updateWorkflow = useCallback(async (w: Workflow) => {
        FullStory.event('WorkflowUpdated', {
            workflow_id_str: w.id,
            stage_str: w.stage,
        });
        try {
            await BaseService.putToClass(`/api/invoiceWorkflow/${encodeURIComponent(w.id)}`, w, w.toDTO());
        } catch (e) {
            await addDevNote(w, JSON.stringify(e));
            throw e;
        }
        await getNextWorkflow();
    }, [getNextWorkflow]);

    const regroupWorkflow = useCallback(async (w: Workflow, userGroupedAttachments: Attachment[][]) => {
        FullStory.event('WorkflowRegrouped', {
            workflow_id_str: w.id,
            stage_str: w.stage,
        });
        try {
            const groups = new UserGroupedAttachments();
            userGroupedAttachments.forEach(attachmentGroup => {
                if (attachmentGroup.every(group => group.pageNumber)) {
                    attachmentGroup = attachmentGroup.sort((a, b) => a.pageNumber - b.pageNumber);
                }
                groups.addNewGroup(attachmentGroup);
            });
            await BaseService.post(`/api/InvoiceWorkflow/regroupWorkflow/${encodeURIComponent(w.id)}`, groups.toDTO());
        } catch (e) {
            await addDevNote(w, JSON.stringify(e));
            throw e;
        }

        // This is currently necessary to prevent optimistic concurrency errors
        // Time value should be the lowest value tested that mostly prevents the errors
        await new Promise(resolve => setTimeout(resolve, 200));

        await getNextWorkflow();
    }, [workflow]);

    const updateAttachment = useCallback(async (a: Attachment, w: Workflow) => {
        const shouldGetNextWorkflow = a.isNewSupplier || workflow?.attachments.every(a => a.hasBeenReviewed);
        FullStory.event('AttachmentUpdated', {
            workflow_id_str: w.id,
            attachment_id_str: a.externalId,
            stage_str: w.stage,
        });
        try {
            await BaseService.putToClass(`/api/invoiceWorkflow/updateAttachment/${encodeURIComponent(w.id)}`, w, a.toDTO());

            if (shouldGetNextWorkflow) {
                setCurrentAttachment(null);
                setWorkflow(null);
                await getNextWorkflow();
            }

            setNextAttachmentThatHasNotBeenReviewedOrGetNextWf();
        } catch (e) {
            await addDevNote(w, JSON.stringify(e))
            throw e;
        }
    }, [getNextWorkflow, currentAttachment, workflow, stage]);

    const moveToErrorQueue = useCallback(async (w: Workflow) => {
        w.stage = "Error";
        await BaseService.post(`/api/invoiceWorkflow/${encodeURIComponent(w.id)}/forceError`)
        getNextWorkflow();
    }, [getNextWorkflow])

    const moveToBadPictureQueue = async (workflow: Workflow, reason: string, setterFn: Dispatch<boolean>) => {
        workflow!.stage = "PictureIssue";
        workflow!.level = 0;
        workflow!.stageReason = reason;
        setterFn(false);
        updateWorkflow(workflow!);
    }

    useEffect(() => {
        if (stage) {
            BaseService.get(`api/invoiceWorkflow/counts`).then(x => x!.json()).then(setStageCounts);
            BaseService.get(`api/invoiceWorkflow/queueAges`).then(x => x!.json()).then(setQueueAges);
            BaseService.get(`api/invoiceWorkflow/missingPacksizeCount/0`).then(x => x!.json()).then(setMissingPackSizeCount);

            // Only admins can see the PackSizeL2 queue so suppress this call for non-admins
            if (UserService.user.anyRole("SuperAdmin")) {
                BaseService.get(`api/invoiceWorkflow/missingPacksizeCount/1`).then(x => x!.json()).then(setMissingPackSizeCountL2);
            }            
        }
    }, [workflow, stage, setStageCounts, setQueueAges, setMissingPackSizeCount])

    const getRowInfo = useCallback(async (supplierId: string, itemCode: string, quantity?: number, total?: number): Promise<RowInfo> => {
        const url = BaseService.toQueryUrl('/api/invoiceWorkflow/rowInfo', {supplierId, itemCode, quantity, total});
        setOutstandingInfoReqs(x => x + 1);
        return BaseService.getToClass(url, RowInfo).finally(() => setOutstandingInfoReqs(x => Math.max(x - 1, 0)));
    }, [setOutstandingInfoReqs])

    const addRow = useCallback((index?: number) => {
        let newRow;
        if (index !== undefined) {
            newRow = workflow!.insertRowAfter(index);
        } else {
            newRow = workflow!.addNewRow();
        }
        setRowCount(workflow!.rows.length);
        return newRow;
    }, [setRowCount, workflow])

    const addCategoryOnlyRow = useCallback((index?: number) => {
        let newRow;
        if (index !== undefined) {
            newRow = workflow!.insertCategoryOnlyRowAfter(index);
        } else {
            newRow = workflow!.addNewCategoryOnlyRow();
        }
        setCategoryRowCount(workflow!.categoryOnlyRows.length);
        return newRow;
    }, [setCategoryRowCount, workflow])

    const deleteRow = useCallback((row: WorkflowRow | CategoryOnlyWorkflowRow) => {
        workflow?.removeRow(row);
        setRowCount(workflow?.rows.length || 0);
        setCategoryRowCount(workflow?.categoryOnlyRows.length || 0);
    }, [setRowCount, workflow])

    const deleteStandardPackSizeRows = useCallback(() => {
        workflow?.removeStandardPackSizeRows();
        setRowCount(workflow?.rows.length || 0);
    }, [setRowCount, workflow])

    const checkForDuplicate = useCallback((workflow: Workflow) => {
        const {invoiceTotal, supplierId, deliveryDate, invoiceNumber} = getMatchingInformationFromGrouping(workflow.attachments);
        const restId = workflow.restaurantId;
        
        if (restId && invoiceTotal && supplierId && deliveryDate && invoiceNumber) {
            return BaseService.getRawJson(`/api/invoiceWorkflow/hasDuplicate?restId=${encodeURIComponent(restId)}&supplierId=${encodeURIComponent(supplierId)}&expectedTotal=${encodeURIComponent(invoiceTotal)}&deliveryDate=${deliveryDate.format('YYYY-MM-DD')}&invoiceNumber=${encodeURIComponent(invoiceNumber)}`)
        } else {
            return Promise.resolve({});
        }
    }, [])

    const checkForDuplicatesInGroupings = useCallback(async (restId: string, attachmentGroups: Attachment[][]) => {
        for (const attachmentGroup of attachmentGroups) {
            const {invoiceTotal, supplierId, deliveryDate, invoiceNumber} = getMatchingInformationFromGrouping(attachmentGroup);
            if (restId && invoiceTotal && supplierId && deliveryDate && invoiceNumber) {
                const res = await BaseService.getRawJson(`/api/invoiceWorkflow/hasDuplicate?restId=${encodeURIComponent(restId)}&supplierId=${encodeURIComponent(supplierId)}&expectedTotal=${encodeURIComponent(invoiceTotal)}&deliveryDate=${deliveryDate.format('YYYY-MM-DD')}&invoiceNumber=${encodeURIComponent(invoiceNumber)}`);
                if (res.length > 0) {
                    return true;
                }
            }
        }
        
        return false;
    }, [])

    const updateStage = useCallback((stage: WorkflowStage, level: number = 0) => {
        setWorkflow(null);
        setCurrentAttachment(null);
        setStage(stage);
        setLevel(level);
    }, [setWorkflow, setStage, setLevel]);

    const forcePullSpecificWorkflow = useCallback(async (workflowId: string, stageToUse: WorkflowStage) => {
        const wf = await BaseService.getToClass(`/api/invoiceWorkflow/forceTake/${encodeURIComponent(workflowId)}/${stageToUse}`, Workflow);
        setWorkflow(wf);
        setCurrentAttachment(null);
        setOutstandingInfoReqs(0);
        setRowCount(wf.rows.length);
    }, [setWorkflow, setOutstandingInfoReqs, setRowCount]);

    const reloadWorkflow = useCallback(async () => {
        if (!workflow) {
            return;
        }
        
        const currentId = workflow.id;
        setWorkflow(null);
        const wf = await BaseService.getToClass(`/api/invoiceWorkflow/${encodeURIComponent(currentId)}`, Workflow);
        setWorkflow(wf);
    }, [setWorkflow, workflow]);

    const setNextAttachmentThatHasNotBeenReviewedOrGetNextWf = useCallback(() => {
        if (!workflow) {
            return;
        }
        
        if (workflow.shouldProcessAsMultiInvoice === false) {
            return;
        }

        if (currentAttachment && currentAttachment.hasBeenReviewed === false) {
            return;
        }

        if (workflow.attachments.length === 0 || workflow.attachments.every(x => x.hasBeenReviewed)) {
            
            if (workflow.stage === 'MISupplierEntry') {
                workflow.attachments.forEach(x => x.hasBeenReviewed = false);
            } else {
                getNextWorkflow();
                return;
            }
        }

        if (workflow.attachments.length === 1) {
            setCurrentAttachment(workflow.attachments[0]);
        }

        const orderedAttachments = workflow.attachments.sort((a, b) => a.pageNumber - b.pageNumber);

        const attachmentAfter = tryToFindNextAttachmentInOrder(orderedAttachments);

        if (attachmentAfter) {
            setCurrentAttachment(attachmentAfter);
            return;
        }
        setCurrentAttachment(null);
    }, [updateAttachment, updateWorkflow, workflow, currentAttachment, stage]);

    const mustGetNextAttachmentForStage = () => {
        if (!workflow || workflow.attachments.length === 0) {
            setCurrentAttachment(null);
            return;
        }

        if (stage === "NewSupplier" && workflow.attachments.length === 1) {
            const firstNewSupplierAttachment = workflow.attachments.find(x => x.isNewSupplier);
            if (firstNewSupplierAttachment) {
                setCurrentAttachment(firstNewSupplierAttachment);
                return;
            }
        }

        const anyUnReviewedAttachment = workflow?.attachments.find(x => !x.hasBeenReviewed && x.pageNumber === 2);
        if (anyUnReviewedAttachment) {
            setCurrentAttachment(anyUnReviewedAttachment);
            return;
        }
        const anyAttachment = workflow?.attachments[0];
        if (anyAttachment) {
            setCurrentAttachment(anyAttachment);
        }
    }

    const tryToFindNextAttachmentInOrder = (orderedAttachments: Attachment[]): Attachment | undefined => {
        if (currentAttachment) {
            const attachmentAfter = stage === "NewSupplier"
                ? findNextAttachmentByIsNewSupplier(orderedAttachments)
                : findNextAttachmentByPageNumberAndNotReviewed(orderedAttachments);
            if (attachmentAfter) {
                return attachmentAfter;
            }
        }
        return orderedAttachments.find(x => !x.hasBeenReviewed);
    }

    const setAttachmentToBadPictureAndGetNextItem = async (workflow: Workflow, attachment: Attachment, reason: string, setterFn: Dispatch<boolean>) => {
        currentAttachment!.badPictureReason = reason;
        currentAttachment!.isBadPicture = true;
        currentAttachment!.hasBeenReviewed = true;
        currentAttachment!.isNewSupplier = false;
        await updateAttachment(attachment, workflow);
        setterFn(false);
        setNextAttachmentThatHasNotBeenReviewedOrGetNextWf();
    }

    const findNextAttachmentByIsNewSupplier = (orderedAttachments: Attachment[]) => orderedAttachments.find(x => x.isNewSupplier);
    const findNextAttachmentByPageNumberAndNotReviewed = (orderedAttachments: Attachment[]) =>
        orderedAttachments.find(x => x.pageNumber > currentAttachment!.pageNumber && x.originalFileName === currentAttachment!.originalFileName && !x.hasBeenReviewed);

    const getAttachmentsGroupedByMatchingInfoAsKey = (wf: Workflow) => wf.attachments.reduce((res, curr) => {
        if (!curr.invoiceNumber || !curr.supplierId || !curr.deliveryDate) {
            res.set(curr.externalId, [curr]);
        } else {
            let keyString = `${curr.invoiceNumber || ''}${curr.supplierId || ''}${curr.deliveryDate?.format('YYYYMMDD') || ''}` || 'no-info';

            if (keyString === 'no-info') {
                keyString = `${curr.originalFileName}`;
            }

            res.set(keyString, [...res.get(keyString) || [], curr]);
        }
        return res;
    }, new Map<string, Attachment[]>())

    const getMatchingInformationFromGrouping = (attachments: Attachment[]) => {
        if (!attachments || attachments.length === 0) {
            return {};
        }

        let invoiceTotal: number | undefined | null = undefined;
        let supplierId: string | undefined | null = undefined;
        let deliveryDate: moment.Moment | undefined | null = undefined;
        let invoiceNumber: string | undefined | null = undefined;
        for (const a of attachments) {
            if (invoiceNumber === undefined && a.invoiceNumber) {
                invoiceNumber = a.invoiceNumber;
            }
            if (invoiceTotal === undefined && typeof a.invoiceTotal === 'number') {
                invoiceTotal = a.invoiceTotal;
            }
            if (supplierId === undefined && a.supplierId) {
                supplierId = a.supplierId;
            }
            if (deliveryDate === undefined && a.deliveryDate) {
                deliveryDate = a.deliveryDate;
            }

            if (invoiceNumber !== null && a.invoiceNumber && invoiceNumber !== a.invoiceNumber) {
                invoiceNumber = null;
            }
            if (invoiceTotal !== null && typeof a.invoiceTotal === 'number' && invoiceTotal !== a.invoiceTotal) {
                invoiceTotal = null;
            }
            if (supplierId !== null && a.supplierId && supplierId !== a.supplierId) {
                supplierId = null;
            }
            if (deliveryDate !== null && a.deliveryDate && !deliveryDate?.isSame(a.deliveryDate)) {
                deliveryDate = null;
            }
        }

        return {invoiceTotal, supplierId, deliveryDate, invoiceNumber};
    }

    const doAttachmentsHaveCompleteInformationInGroup = (attachments: Attachment[]) => {
        if (!attachments || attachments.length === 0) {
            return true;
        }

        let invoiceTotal: number | null = null;
        let supplierId: string | null = null;
        let deliveryDate: moment.Moment | null = null;
        let invoiceNumber: string | null = null;
        for (const a of attachments) {
            if (a.isBadPicture || a.hasError || a.isNewSupplier) {
                return true;
            }
            
            if (!invoiceNumber && a.invoiceNumber) {
                invoiceNumber = a.invoiceNumber;
            }
            if (invoiceTotal === null && typeof a.invoiceTotal === 'number') {
                invoiceTotal = a.invoiceTotal;
            }
            if (!supplierId && a.supplierId) {
                supplierId = a.supplierId;
            }
            if (!deliveryDate && a.deliveryDate) {
                deliveryDate = a.deliveryDate;
            }

            if (invoiceNumber && a.invoiceNumber && invoiceNumber !== a.invoiceNumber) {
                return false;
            }
            if (typeof invoiceTotal === 'number' && typeof a.invoiceTotal === 'number' && invoiceTotal !== a.invoiceTotal) {
                return false;
            }
            if (supplierId && a.supplierId && supplierId !== a.supplierId) {
                return false;
            }
            if (deliveryDate && a.deliveryDate && !deliveryDate.isSame(a.deliveryDate)) {
                return false;
            }
        }
        return invoiceTotal !== null && supplierId !== null && deliveryDate !== null && invoiceNumber !== null;
    }

    useEffect(() => {
        if (workflow && stage && WorkflowStagesThatCouldShowSingleAttachments.includes(stage)) {
            setNextAttachmentThatHasNotBeenReviewedOrGetNextWf();
        }
    }, [workflow, getNextWorkflow]);
    
    async function getEligibleStagesForErrorQueueMovement() {
        const path = `/api/invoiceWorkflow/eligibleStages/${encodeURIComponent(workflow!.id)}`;
        const res = await BaseService.get(path);
        return await res!.json() as WorkflowStage[];
    }

    async function getRelatedWorkflows(invoiceGroupingOrWorkflowId: string) {
        const path = `/api/invoiceWorkflow/workflowHistory/${encodeURIComponent(invoiceGroupingOrWorkflowId)}`;
        return await BaseService.getToClass(path, RelatedWorkflows);
    }

    return <WorkflowContext.Provider
        value={{
            workflow,
            currentAttachment,
            stage,
            setStage: updateStage,
            updateWorkflow,
            regroupWorkflow,
            updateAttachment,
            stageCounts,
            stageAges: queueAges,
            moveToErrorQueue,
            moveToBadPictureQueue,
            setAttachmentToBadPictureAndGetNextItem,
            missingPackSizeCount,
            getRowInfo,
            isSafeToSave: outstandingInfoReqs === 0,
            addRow,
            deleteRow,
            deleteStandardPackSizeRows,
            addCategoryOnlyRow,
            checkForDuplicate,
            checkForDuplicatesInGroupings,
            missingPackSizeCountL2,
            stageLevel: level,
            addDevNote,
            forcePullSpecificWorkflow,
            forceSetNextAttachment: mustGetNextAttachmentForStage,
            getAttachmentsGroupedByMatchingInfoAsKey,
            getMatchingInformationFromGrouping,
            doAttachmentsHaveCompleteInformationInGroup,
            reloadWorkflow,
            splitWorkflowImagesAndSendToMISupplierEntry,
            getEligibleStagesForErrorQueueMovement,
            getRelatedWorkflows,
        }
        }>
        {children}
    </WorkflowContext.Provider>;
}

export interface SupplierEntryInformation {
    invoiceTotal: number | null;
    supplierId: string | null;
    deliveryDate: string | null;
    invoiceNumber: string | null;
}

export const useWorkflowContext = () => useContext(WorkflowContext);
