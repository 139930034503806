import {InvoiceService} from "../../Services/InvoiceService";
import React, {ChangeEvent, useState} from "react";
import {UserService} from "../../Services/UserService";
import {Tenant} from "../../Domain/User";
import {TenantSelect} from "../../Components/Selects";

const attachmentGroupingUploadStyle = {
    display: "grid",
    gridTemplateColumns: "30px 500px 200px auto",
    gridTemplateRows: "30px 70px 70px 70px 70px auto",
    gridTemplateAreas:
        "'. .'" +
        "'. fileUpload'" +
        "'. selectTenant'" +
        "'. selectRestaurant'" +
        "'. submit'",
    columnGap: 3
}

export function AttachmentGroupingUploadPage() {

    const [selectedFile, setSelectedFile] = useState<File>();
    const [selectedFiles, setSelectedFiles] = useState<FileList>();
    const [selectedRestaurant, setSelectedRestaurant] = useState<string>();
    const [selectedTenant, setSelectedTenant] = useState<Tenant>();
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadComplete, setUploadComplete] = useState<boolean>(false);
    const [allowMultipleFiles, setAllowMultipleFiles] = useState<boolean>(false);

    const onFileUpload = async () => {
        setUploadComplete(false);
        setUploading(false);

        const formData = new FormData();

        if (selectedFile) {
            formData.append(
                "selectedFile",
                selectedFile);
        }

        if (selectedTenant) {
            formData.append(
                "selectedTenant",
                selectedTenant.id);
        }

        if (selectedRestaurant) {
            formData.append(
                "selectedRestaurant",
                selectedRestaurant);
        }

        setUploading(true);
        await InvoiceService.attachmentGroupingUpload(formData);

        setUploading(false);
        setUploadComplete(true);
    }

    const onMultipleFileUpload = async () => {
        if (!selectedFiles) {
            return;
        }

        setUploadComplete(false);
        setUploading(false);

        const formData = new FormData();

        const firstAttachment = selectedFiles[0];
        formData.append(
            "selectedFile",
            firstAttachment);

        if (selectedTenant) {
            formData.append(
                "selectedTenant",
                selectedTenant.id);
        }

        if (selectedRestaurant) {
            formData.append(
                "selectedRestaurant",
                selectedRestaurant);
        }

        setUploading(true);

        const result = await InvoiceService.attachmentGroupingUpload(formData);
        const uploadResult: AttachmentGroupingUploadResult = await result.json();

        if (selectedFiles.length === 1) {
            setUploading(false);
            setUploadComplete(true);
            return;
        }

        const formDataForAdditionalFiles = new FormData();

        for (let i = 1; i < selectedFiles.length; i++) {
            formDataForAdditionalFiles.append(
                "selectedFiles",
                selectedFiles[i]);
        }

        formDataForAdditionalFiles.append(
            "attachmentGroupingId",
            uploadResult.attachmentGroupingId);

        await InvoiceService.addAttachmentToGrouping(formDataForAdditionalFiles);

        setUploading(false);
        setUploadComplete(true);
    }

    const handleAllowMultiple = () => {
        setAllowMultipleFiles(!allowMultipleFiles);
        setSelectedFiles(undefined);
        setSelectedFile(undefined);
    }

    const onFileChange = (event: any) => {
        setSelectedFile(event.target.files[0]);
    }

    const onFileListChange = (event: any) => {
        setSelectedFiles(event.target.files);
    }

    const onSelectedRestaurantChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedRestaurant(event.target.value);
    }

    return <div style={attachmentGroupingUploadStyle}>
        <div style={{gridArea: "fileUpload"}}>
            <div style={{display: "flex", flexFlow: "row nowrap", gap: 32}}>
                <div><label>Select Invoice Image</label></div>
                <span><label>Multiple Files</label>
                <input type="checkbox" checked={allowMultipleFiles} onChange={handleAllowMultiple}/></span>
            </div>
            {allowMultipleFiles === false && <div><input type="file" onChange={onFileChange}/></div>}
            {allowMultipleFiles && <div><input type="file" multiple onChange={onFileListChange}/></div>}
        </div>

        {UserService.user.anyRole("SuperAdmin") &&
            <div style={{gridArea: "selectTenant"}}>
                <div><label>Select Tenant to Use</label></div>
                <div><TenantSelect tenant={selectedTenant} setTenant={setSelectedTenant}/></div>
            </div>
        }

        {UserService.user.anyRole("SuperAdmin") &&
            <div style={{gridArea: "selectRestaurant"}}>
                <div><label>Enter Restaurant (blank defaults to Admin)</label></div>
                <div><input type={"text"} onChange={onSelectedRestaurantChange}/></div>
            </div>
        }
        <div style={{gridArea: "submit"}}>
            <div>
                <button onClick={allowMultipleFiles ? onMultipleFileUpload : onFileUpload} disabled={uploading}>
                    Upload!
                </button>
            </div>
            <div>{uploading && 'Uploading Now'} {uploadComplete && 'Complete'}</div>
        </div>
    </div>
}

interface AttachmentGroupingUploadResult {
    attachmentGroupingId: string;
}