import React, {CSSProperties, PropsWithChildren, useState} from 'react';
import {Css} from '../Css';
import FileCopyIcon from '@mui/icons-material/FileCopy';

interface Props{
    text: string | undefined
}

export function CopyableText({ text }: PropsWithChildren<Props>) {
    const [showCopy, setShowCopy] = useState(false);

    const handleClick = () => {
        try {
            if (text !== undefined) {
                setShowCopy(true);
                navigator.clipboard.writeText(text).then(() => {
                    setShowCopy(false);
                });
            }
        }
        catch(error) {
            console.error('Error copying to clipboard:', error);
        }
    };

    const theStyle: CSSProperties = {
        display: 'inline-flex',
        alignItems: 'center',
    }

    return (
        <span
            style={{...Css.clickable(), ...theStyle}}
            onClick={handleClick}
            onMouseEnter={() => setShowCopy(true)}
            onMouseLeave={() => setShowCopy(false)}
        >
            <span>{text}</span>
            {showCopy && <FileCopyIcon fontSize='small' style={{ marginLeft: '5px' }} />}
        </span>
    );
}