import React, {useEffect, useMemo, useState} from "react";
import {useWorkflowContext} from "../../../Contexts/WorkflowContext";
import {AttachmentViewerStrip} from "../../../Components/AttachmentViewerStrip";
import {AttachmentPicture} from "../../InvoiceWorkflow/InvoiceWorkflowPage";
import {SupplierEntryFields} from "./SupplierEntryFields";
import {Supplier} from "../../../Domain/Supplier";
import {Attachment} from "../../../Domain/Workflow/Workflow";
import {useSupplier} from "../../../Services/SupplierService";
import moment from "moment";
import {Colors} from "../../../Colors";
import {BadPictureModal, ErrorReasonModal} from "../../InvoiceWorkflow/WorkflowCommonComponents";
import {CurrentGroupsModal} from "./CurrentGroupsModal";

export function MultipleInvoiceErrorQueue() {
    const {
        workflow,
        setStage,
        getAttachmentsGroupedByMatchingInfoAsKey,
        getMatchingInformationFromGrouping,
        doAttachmentsHaveCompleteInformationInGroup,
        reloadWorkflow,
        regroupWorkflow,
        checkForDuplicatesInGroupings,
    } = useWorkflowContext();

    const [deliveryDate, setDeliveryDate] = React.useState<string | undefined>();
    const [invoiceNumber, setInvoiceNumber] = React.useState<string | undefined>();
    const [invoiceTotal, setInvoiceTotal] = React.useState<number | undefined>();
    const [supplier, setSupplier] = useSupplier();
    const [selectedFocusedAttachment, setSelectedFocusedAttachment] = React.useState<Attachment | null>(null);
    const [allAttachments, setAllAttachments] = React.useState<Attachment[][]>([]);
    const [groupedAttachments, setGroupedAttachments] = React.useState<Attachment[][]>([]);
    const [currentFocusedGroup, setCurrentFocusedGroup] = React.useState<Attachment[] | null>(null);
    const [isSelectingGrouping, setIsSelectingGrouping] = React.useState(false);
    const [attachmentsWithCompleteInfo, setAttachmentsWithCompleteInfo] = React.useState<Set<Attachment>>(new Set());
    const [finalizedGroupings, setFinalizedGroupings] = React.useState<Attachment[][]>([]);
    const [badPicAttachmentIds, setBadPicAttachmentIds] = React.useState(new Set<string>());
    const [newSupplierAttachmentIds, setNewSupplierAttachmentIds] = React.useState(new Set<string>());
    const [showBadPicModal, setShowBadPicModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorAttachmentIds, setErrorAttachmentIds] = React.useState(new Set<string>());
    const [showCurrentGroupsModal, setShowCurrentGroupsModal] = useState(false);
    const [shouldBreakupGroupsOnLoad, setShouldBreakupGroupsOnLoad] = useState(false);
    const [restId, setRestId] = useState<string | undefined>(undefined);

    useEffect(() => {
        setStage("MultipleInvoiceError", 0);
    }, []);

    useEffect(() => {
        if (workflow?.attachments && workflow.attachments.length > 0) {
            createAttachmentsToGroup();
            setRestId(workflow.restaurantId);
        }
    }, [workflow?.id, workflow?.attachments]);

    useEffect(() => {
        if (allAttachments.length > 0) {
            handleSetAttachmentsThatHaveCompleteInfo();
        }
    }, [allAttachments]);

    useEffect(() => {
        if (currentFocusedGroup) {
            populateFieldsForSelectedGroup();
        }
    }, [currentFocusedGroup, finalizedGroupings]);

    useEffect(() => {
        updateAttachmentInfoForSelectedGroup();
    }, [supplier, invoiceNumber, invoiceTotal, deliveryDate]);

    const createAttachmentsToGroup = () => {
        if (!workflow) {
            return;
        }
        let attachmentGroups: Attachment[][];
        if (shouldBreakupGroupsOnLoad) {
            attachmentGroups = workflow.attachments.map(attachment => [attachment]);
            setShouldBreakupGroupsOnLoad(false);
        } else {
            const groups = getAttachmentsGroupedByMatchingInfoAsKey(workflow);
            attachmentGroups = Array.from(groups.values());
        }
        setAllAttachments(attachmentGroups);
    }

    const canFinalizeCurrentGrouping = useMemo(() =>
        currentFocusedGroup !== null && currentFocusedGroup.some(attachment => attachmentsWithCompleteInfo.has(attachment)), [attachmentsWithCompleteInfo, currentFocusedGroup, finalizedGroupings]);

    const canFinalizeParentGrouping = useMemo(() =>
        allAttachments.every(group => doAttachmentsHaveCompleteInformationInGroup(group)), [allAttachments, finalizedGroupings]);

    const currentFocusedGroupHasBadPic = useMemo(() =>
        currentFocusedGroup?.some(attachment => badPicAttachmentIds.has(attachment.externalId)) || false, [badPicAttachmentIds, currentFocusedGroup]);

    const currentFocusedGroupHasError = useMemo(() =>
        currentFocusedGroup?.some(attachment => errorAttachmentIds.has(attachment.externalId)) || false, [errorAttachmentIds, currentFocusedGroup]);

    const currentFocusedGroupHasNewSupplier = useMemo(() =>
        currentFocusedGroup?.some(attachment => newSupplierAttachmentIds.has(attachment.externalId)) || false, [newSupplierAttachmentIds, currentFocusedGroup]);

    const canAddPageToGrouping = () => selectedFocusedAttachment !== null && currentFocusedGroup !== null && !currentFocusedGroup.includes(selectedFocusedAttachment);

    if (workflow === undefined) {
        return <div>Loading...</div>;
    }
    if (workflow === null) {
        return <div>Workflow not found</div>;
    }

    function handleSetAttachmentsThatHaveCompleteInfo() {
        const attachmentsWithAllInfo = new Set<Attachment>();
        allAttachments.forEach(group => {
            if (doAttachmentsHaveCompleteInformationInGroup(group)) {
                group.forEach(attachment => attachmentsWithAllInfo.add(attachment));
            }
        });
        setAttachmentsWithCompleteInfo(attachmentsWithAllInfo);
    }

    async function populateFieldsForSelectedGroup() {
        const {
            invoiceTotal,
            invoiceNumber,
            supplierId,
            deliveryDate
        } = getMatchingInformationFromGrouping(currentFocusedGroup!);

        setInvoiceTotal(invoiceTotal || 0);
        setInvoiceNumber(invoiceNumber || "");

        if (deliveryDate) {
            setDeliveryDate(deliveryDate.format("YYYY-MM-DD"));
        } else {
            setDeliveryDate("");
        }
        if (supplierId && supplierId !== supplier?.id) {
            setSupplier(supplierId)
        } else {
            setSupplier(null);
        }
    }

    const handleSetSupplier = (supplier?: Supplier) => {
        if (supplier) {
            setSupplier(supplier);
        }
    }

    const handleSetDeliveryDate = (date?: string) => {
        if (date === undefined || moment(date).isValid()) {
            setDeliveryDate(date);
        }
    }

    const handleSetInvoiceNumber = (number?: string) => setInvoiceNumber(number);

    const handleSetInvoiceTotal = (total?: number) => setInvoiceTotal(total || 0);

    function updateAttachmentInfoForSelectedGroup() {
        if (currentFocusedGroup === null) {
            return;
        }

        /* These are mutually exclusive states maintained when things are added to any of the groups */
        const shouldSetAsBadPic = currentFocusedGroup.some(attachment => badPicAttachmentIds.has(attachment.externalId));
        const shouldSetAsError = currentFocusedGroup.some(attachment => errorAttachmentIds.has(attachment.externalId));
        const shouldSetAsNewSupplier = currentFocusedGroup.some(attachment => newSupplierAttachmentIds.has(attachment.externalId));

        currentFocusedGroup.forEach(attachment => {
            if (invoiceNumber !== attachment.invoiceNumber) {
                attachment.invoiceNumber = invoiceNumber;
            }

            if (invoiceTotal !== attachment.invoiceTotal) {
                attachment.invoiceTotal = invoiceTotal;
            }

            if (deliveryDate !== attachment.deliveryDate?.format("YYYY-MM-DD")) {
                attachment.deliveryDate = moment(deliveryDate);
            }

            if (supplier && supplier.id !== attachment.supplierId) {
                attachment.supplierId = supplier?.id;
            }

            attachment.isBadPicture = shouldSetAsBadPic;
            attachment.hasError = shouldSetAsError;
        });

        handleSetAttachmentsThatHaveCompleteInfo();
    }

    function handleFinalizeCurrentGrouping() {
        if (currentFocusedGroup) {
            const attachmentIdsInCurrentGroup = currentFocusedGroup.map(attachment => attachment.externalId);

            const newFinalizedGrouping = finalizedGroupings.filter(group => {
                const attachmentIdsInGroup = group.map(attachment => attachment.externalId);
                return !attachmentIdsInCurrentGroup.some(id => attachmentIdsInGroup.includes(id));
            });

            setFinalizedGroupings([...newFinalizedGrouping, currentFocusedGroup]);
            setCurrentFocusedGroup(null);
            setIsSelectingGrouping(false);
            handleSetAttachmentsThatHaveCompleteInfo();
        } else {
            const finalizedGroups = allAttachments.filter(group => doAttachmentsHaveCompleteInformationInGroup(group));
            setFinalizedGroupings([...finalizedGroups]);
            setCurrentFocusedGroup(null);
            setIsSelectingGrouping(false);
            handleSetAttachmentsThatHaveCompleteInfo();
        }
    }

    const handleLeftSideAttachmentClick = (attachment: Attachment) => {
        if (!isSelectingGrouping) {
            setSelectedFocusedAttachment(
                selectedFocusedAttachment === attachment ? null : attachment
            );
            return;
        }

        if (finalizedGroupings.length > 0) {
            const groupingWithAttachment = finalizedGroupings.find(group => group.includes(attachment));
            if (groupingWithAttachment) {
                handleSelectFocusedGrouping(groupingWithAttachment);
                return;
            }
        }

        const groupingWithAttachment = allAttachments.find(group => group.includes(attachment));
        if (groupingWithAttachment) {
            handleSelectFocusedGrouping(groupingWithAttachment);
        }
    }

    const onAddPage = (attachment: Attachment) => {
        addAttachmentToGrouping(attachment);
        setSelectedFocusedAttachment(null);
        updateAttachmentInfoForSelectedGroup();
    }

    const addAttachmentToGrouping = (attachment: Attachment) => {
        if (currentFocusedGroup === null || currentFocusedGroup.includes(attachment)) {
            return;
        }
        setGroupedAttachments(getGroupsWithAttachmentRemoved(attachment));
        currentFocusedGroup.push(attachment);
        setCurrentFocusedGroup([...currentFocusedGroup]);
        handleSetAttachmentsThatHaveCompleteInfo();
        updateAttachmentInfoForSelectedGroup();
    }

    const getGroupsWithAttachmentRemoved = (attachment: Attachment) => groupedAttachments.map(group => group.filter(a => a.externalId !== attachment.externalId));

    const handleSelectFocusedGrouping = (group: Attachment[]) => {
        setCurrentFocusedGroup([...group]);
        setIsSelectingGrouping(false);
    }

    const handleFinalizeParentGrouping = async () => {
        const actualFinalGrouping = createAndGetNonUseStateClosureAffectedFinalizedGroupings();

        const lowTotalMessage = 'Some of the groups have a total less than $10. Are you sure you want to finalize the groupings?';
        if (anyGroupsHaveALowTotal() && !window.confirm(lowTotalMessage)) {
            return;
        }

        const anyDuplicates = await checkForDuplicatesInGroupings(workflow.restaurantId!, actualFinalGrouping);
        if (anyDuplicates && !window.confirm('There are duplicate invoices in the grouping.  Are you sure this should be submitted?')) {
            return;
        }

        await regroupWorkflow(workflow, actualFinalGrouping);
    }

    const createAndGetNonUseStateClosureAffectedFinalizedGroupings = () => {
        const attachmentsNotInFinalizedGrouping = allAttachments.flat(1)
            .filter(attachment => !finalizedGroupings.flat(1).includes(attachment));

        if (attachmentsNotInFinalizedGrouping.length === 0) {
            return finalizedGroupings;
        }

        const attachmentsWhoHaveBeenFinalized = new Set<Attachment>();
        const listOfAttachmentsInTheirGroupToAddToFinalized = attachmentsNotInFinalizedGrouping.map(attachment => {
            if (attachmentsWhoHaveBeenFinalized.has(attachment)) {
                return [];
            }

            const attachmentsInGroup = allAttachments.find(group => group.includes(attachment));
            if (!attachmentsInGroup) {
                return [];
            }
            attachmentsInGroup.forEach(attachment => attachmentsWhoHaveBeenFinalized.add(attachment));
            return attachmentsInGroup;
        });

        return [...finalizedGroupings, ...listOfAttachmentsInTheirGroupToAddToFinalized.filter(group => group.length > 0)];
    }

    const anyGroupsHaveALowTotal = () => finalizedGroupings.some(
        group => group.some(attachment => !attachment.invoiceTotal || attachment.invoiceTotal < 10));

    const handleStartChoosingGrouping = () => setIsSelectingGrouping(!isSelectingGrouping);

    const handleResetParentGroupingWithWarning = async () => {
        if (window.confirm(
            `Are you sure you want to reset the grouping? This will remove the default created matched groups and return to the original state!`)) {
            setShouldBreakupGroupsOnLoad(true);
            await reloadWorkflow();
            setFinalizedGroupings([]);
            setCurrentFocusedGroup(null);
            setIsSelectingGrouping(false);
            setBadPicAttachmentIds(new Set());
        }
    }
    
    function refreshAttachmentIdStatusQueues() {
        setBadPicAttachmentIds(new Set(badPicAttachmentIds));
        setErrorAttachmentIds(new Set(errorAttachmentIds));
        setNewSupplierAttachmentIds(new Set(newSupplierAttachmentIds));
        handleSetAttachmentsThatHaveCompleteInfo();
    }

    const handleSetBadPic = (reason: string) => {
        if (currentFocusedGroup) {
            currentFocusedGroup.forEach(attachment => {
                badPicAttachmentIds.add(attachment.externalId);
                attachment.isBadPicture = true;
                attachment.badPictureReason = reason;
                attachment.hasError = false;
                attachment.errorReason = undefined;
                attachment.isNewSupplier = false;
                errorAttachmentIds.delete(attachment.externalId);
                newSupplierAttachmentIds.delete(attachment.externalId);
            });
            refreshAttachmentIdStatusQueues() 
        }
        setShowBadPicModal(false);
    }

    const handleSetError = (reason: string) => {
        if (currentFocusedGroup) {
            currentFocusedGroup.forEach(attachment => {
                errorAttachmentIds.add(attachment.externalId);
                attachment.hasError = true;
                attachment.errorReason = reason;
                attachment.isBadPicture = false;
                attachment.badPictureReason = undefined;
                attachment.isNewSupplier = false;
                badPicAttachmentIds.delete(attachment.externalId);
                newSupplierAttachmentIds.delete(attachment.externalId);
            });
            refreshAttachmentIdStatusQueues()
        }
        setShowErrorModal(false);
    }

    const handleEnableModal = (type: 'BadPic' | 'Error' | 'ShowGroups') => {
        switch (type) {
            case 'BadPic':
                handleShowBadPicModalOrRemoveBadPic()
                break;
            case 'Error':
                handleShowErrorModalOrRemoveError()
                break;
            case 'ShowGroups':
                setShowErrorModal(false);
                setShowBadPicModal(false);
                setShowCurrentGroupsModal(true);
                break;
        }
    }

    const handleShowBadPicModalOrRemoveBadPic = () => {
        if (!currentFocusedGroup) {
            return
        }

        if (currentFocusedGroupHasBadPic) {
            currentFocusedGroup.forEach(attachment => {
                attachment.isBadPicture = false;
                attachment.badPictureReason = undefined;
                badPicAttachmentIds.delete(attachment.externalId);
            });
            refreshAttachmentIdStatusQueues()
            return;
        }

        setShowBadPicModal(true);
        setShowErrorModal(false);
        setShowCurrentGroupsModal(false);
    }

    const handleShowErrorModalOrRemoveError = () => {
        if (!currentFocusedGroup) {
            return
        }

        if (currentFocusedGroupHasError) {
            currentFocusedGroup.forEach(attachment => {
                attachment.hasError = false;
                attachment.errorReason = undefined;
                errorAttachmentIds.delete(attachment.externalId);
            });
            refreshAttachmentIdStatusQueues()
            return;
        }

        setShowErrorModal(true);
        setShowBadPicModal(false);
        setShowCurrentGroupsModal(false);
    }

    function handleSetCurrentGroupToNewSupplier() {
        if (!currentFocusedGroup) {
            return;
        }

        if (currentFocusedGroupHasNewSupplier) {
            currentFocusedGroup.forEach(attachment => {
                attachment.hasError = false;
                attachment.errorReason = undefined;
                attachment.isBadPicture = false;
                attachment.badPictureReason = undefined;
                attachment.isNewSupplier = false;
                newSupplierAttachmentIds.delete(attachment.externalId);
            });
            refreshAttachmentIdStatusQueues()
            return;
        }

        currentFocusedGroup.forEach(attachment => {
            attachment.hasError = false;
            attachment.errorReason = undefined;
            attachment.isBadPicture = false;
            attachment.badPictureReason = undefined;
            attachment.isNewSupplier = true;
            newSupplierAttachmentIds.add(attachment.externalId);
            errorAttachmentIds.delete(attachment.externalId);
            badPicAttachmentIds.delete(attachment.externalId);
        });
        refreshAttachmentIdStatusQueues()
    }

    return (
        <div style={mainContainerStyle}>
            <div style={entryContainerStyle}>
                {currentFocusedGroup === null
                    ? <span>Please select a grouping to input data</span>
                    : <SupplierEntryFields
                        handleSetDeliveryDate={handleSetDeliveryDate}
                        handleSetInvoiceNumber={handleSetInvoiceNumber}
                        handleSetInvoiceTotal={handleSetInvoiceTotal}
                        handleSetSupplier={handleSetSupplier}
                        restId={restId}
                        supplier={supplier}
                        deliveryDate={deliveryDate || ''}
                        invoiceNumber={invoiceNumber || ''}
                        invoiceTotal={invoiceTotal || 0}
                    region={workflow.region}/>
                }
                <div style={endButtonsContainerStyle}>
                    <button onClick={handleResetParentGroupingWithWarning}
                            style={{backgroundColor: 'buttonface', height: 32}}>Reset Parent Grouping
                    </button>
                    <button disabled={!canFinalizeParentGrouping} onClick={handleFinalizeParentGrouping} style={{
                        margin: 8,
                        height: 32,
                        backgroundColor: canFinalizeParentGrouping ? Colors.darkGreen : 'buttonface'
                    }}>Finalize
                        Parent Grouping
                    </button>
                </div>
            </div>
            <div style={finalizeContainerStyle}>
                <button onClick={handleStartChoosingGrouping} style={{
                    margin: "0 12px",
                    height: 32,
                    backgroundColor: isSelectingGrouping ? Colors.yellowWarning : 'buttonface'
                }}>{isSelectingGrouping ? 'Selecting Grouping' : 'Select New Grouping'}</button>
                <div style={{
                    margin: "0 12px",
                    display: "flex",
                    gap: 4,
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <span style={{display: "grid", gridTemplateColumns: "auto auto auto auto", gap: 4}}>
                        <button disabled={!currentFocusedGroup} onClick={() => handleEnableModal('BadPic')}
                                style={{
                                    width: 140,
                                    height: 32,
                                    backgroundColor: currentFocusedGroupHasBadPic ? Colors.yellowWarning : 'buttonface'
                                }}>Bad Picture</button>
                        <button disabled={!currentFocusedGroup} onClick={() => handleEnableModal('Error')}
                                style={{
                                    width: 140,
                                    height: 32,
                                    backgroundColor: currentFocusedGroupHasError ? Colors.yellowWarning : 'buttonface'
                                }}>Error</button>
                        <button disabled={!currentFocusedGroup}
                                onClick={handleSetCurrentGroupToNewSupplier}
                                style={{
                                    width: 182,
                                    height: 32,
                                    backgroundColor: currentFocusedGroupHasNewSupplier ? Colors.yellowWarning : 'buttonface'
                                }}>New Supplier</button>
                        <button disabled={finalizedGroupings.length === 0}
                                onClick={() => handleEnableModal('ShowGroups')}
                                style={{
                                    backgroundColor: 'buttonface',
                                    width: 182,
                                    height: 32
                                }}>Show Finalized Groups</button>
                        <BadPictureModal setShowBadPicModal={setShowBadPicModal}
                                         showBadPicModal={showBadPicModal}
                                         showErrorWarning={(selectedFocusedAttachment && errorAttachmentIds.has(selectedFocusedAttachment.externalId)) || false}
                                         onSet={handleSetBadPic}/>
                        <ErrorReasonModal setShowErrorReasonModal={setShowErrorModal}
                                          showBadPicWarning={(selectedFocusedAttachment && badPicAttachmentIds.has(selectedFocusedAttachment.externalId)) || false}
                                          showErrorReasonModal={showErrorModal}
                                          onSet={handleSetError}/>
                        <CurrentGroupsModal
                            showCurrentGroupsModal={showCurrentGroupsModal}
                            handleSetShowCurrentGroupsModal={setShowCurrentGroupsModal}
                            currentGroups={finalizedGroupings}/>
                    </span>
                    <span>{workflow.id}</span>
                </div>
                <div style={{textAlign: "center"}}>(Current Grouping)</div>
            </div>
            <div style={leftContainerStyle}>
                <AttachmentViewerStrip attachments={allAttachments}
                                       direction={"column"}
                                       selectedAttachmentId={selectedFocusedAttachment?.externalId}
                                       attachmentsWithCompleteInfo={attachmentsWithCompleteInfo}
                                       setIsSelectingGrouping={isSelectingGrouping}
                                       shouldShowBorder={true}
                                       shouldShowSelectButton={true}
                                       handleAttachmentClicked={handleLeftSideAttachmentClick}
                                       finalizedGroups={finalizedGroupings}
                                       showGroupNumber={true}
                                       allAttachments={allAttachments}
                />
            </div>
            <div style={middleContainerStyle}>
                <AttachmentViewerStrip attachments={currentFocusedGroup ?? []}
                                       direction={"column"}
                                       shouldShowSelectButton={false}
                                       isLargerPdf={true}
                />
                <button onClick={() => onAddPage(selectedFocusedAttachment!)}
                        disabled={!canAddPageToGrouping()}
                        style={{margin: 4, justifySelf: "end", width: "6rem"}}>Add Page
                </button>
                <div style={{overflow: "auto", height: "40vh"}}>
                    {selectedFocusedAttachment &&
                        <AttachmentPicture attachment={selectedFocusedAttachment}
                                           zoomPercentage={100}
                                           rotationChanged={() => null}
                                           rotation={0}/>
                    }
                </div>
            </div>
            <div style={rightContainerStyle}>
                <AttachmentViewerStrip attachments={currentFocusedGroup || []}
                                       direction={"column"}
                />
                <button style={{
                    marginTop: 8,
                    marginBottom: 16,
                    marginLeft: 8,
                    marginRight: 8,
                    height: 32,
                    backgroundColor: canFinalizeCurrentGrouping ? Colors.darkGreen : 'buttonface'
                }}
                        disabled={!canFinalizeCurrentGrouping}
                        onClick={() => handleFinalizeCurrentGrouping()}>
                    Finalize Grouping
                </button>
            </div>
        </div>
    );
}

export const LargeDivHeight = "86vh";

const GridColDimensions = "15% 70% 15%";
const GridGapSpace = 1;

const mainContainerStyle: React.CSSProperties = {
    display: "grid",
    width: '100%',
    height: '100%',
    minHeight: 0,
    gridTemplateAreas:
        `"entry entry entry"
         "finalize finalize finalize"
         "left middle right"`,
    gridTemplateColumns: GridColDimensions,
    gridTemplateRows: `1fr 0.70fr ${LargeDivHeight}`,
    border: "1px solid black",
    gap: GridGapSpace,
};

const entryContainerStyle: React.CSSProperties = {
    gridArea: "entry",
    display: "grid",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid black",
    padding: "0 12px",
}

const finalizeContainerStyle: React.CSSProperties = {
    gridArea: "finalize",
    display: "grid",
    gridTemplateColumns: GridColDimensions,
    gap: GridGapSpace,
    alignItems: "center",
    border: "1px solid black",
}

const leftContainerStyle: React.CSSProperties = {
    gridArea: "left",
    display: "flex",
    flexFlow: "column nowrap",
    border: "1px solid black",
    height: '93%',
    minHeight: 0,
}

const middleContainerStyle: React.CSSProperties = {
    gridArea: "middle",
    display: "grid",
    gridTemplateRows: "48% 4% 48%",
    border: "1px solid black",
}

const rightContainerStyle: React.CSSProperties = {
    gridArea: "right",
    display: "flex",
    flexFlow: "column nowrap",
    border: "1px solid black",
    justifyContent: "start",
    height: '93%',
    minHeight: 0,
}

const endButtonsContainerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 12,
    alignItems: "center",
};