import * as React from 'react';
import {Attachment} from "../Domain/Workflow/Workflow";
import {StandardModal} from "./Modal";
import {AttachmentPicture} from "../Pages/InvoiceWorkflow/InvoiceWorkflowPage";

interface AttachmentViewerModalProps {
    attachment?: Attachment;
    showModal: boolean;
    handleCloseModal: () => void;
}

export function AttachmentViewerModal({attachment, showModal, handleCloseModal}: AttachmentViewerModalProps) {
    return (
        <StandardModal shouldShow={showModal} closeFn={handleCloseModal} header={'View Attachment'}>
            <div style={{height: '80vh', width: '70vw'}}>
                <AttachmentPicture attachment={attachment!} rotation={0} rotationChanged={() => null} zoomPercentage={100}/>
            </div>
        </StandardModal>
    );
}

interface AttachmentsViewerModalProps extends AttachmentViewerModalProps {
    attachments: Attachment[];
}

export function AttachmentsViewerModal({attachments, showModal, handleCloseModal}: AttachmentsViewerModalProps) {
    return (
        <StandardModal shouldShow={showModal} closeFn={handleCloseModal} header={'View Attachment'}>
            <div style={{height: '80vh', width: '70vw', overflow: "auto"}}>
                {attachments.map(a => (
                        <AttachmentPicture attachment={a} rotation={0} rotationChanged={() => null} zoomPercentage={100}/>
                    ))
                }
            </div>
        </StandardModal>
    );
}
