import {AbstractDomainObject} from "../AbstractDomainObject";
import moment from "moment";

export class CallbackRegistration extends AbstractDomainObject{
    id!: string;
    internalId!: string;
    url!: string;
    createdTime!: moment.Moment;
    isActive!: boolean;
    type!: string;
}