import React from "react";
import {Supplier} from "../../../Domain/Supplier";
import {SupplierSearch} from "../../../Components/SupplierSearch";
import moment from "moment/moment";
import {InvoiceEntryNumberInput} from "../../InvoiceWorkflow/WorkflowCommonComponents";
import {Colors} from "../../../Colors";

type SupplierEntryFieldsProps = {
    handleSetDeliveryDate: (x?: string | undefined) => void;
    handleSetInvoiceNumber: (x?: string | undefined) => void;
    handleSetInvoiceTotal: (n?: number | undefined) => void;
    handleSetSupplier: (x?: Supplier | undefined) => void;
    deliveryDate: string;
    invoiceNumber: string;
    invoiceTotal: number;
    supplier?: Supplier | null | undefined;
    restId: string | undefined;
    region: string;
}

export function SupplierEntryFields(
    {
        handleSetDeliveryDate, handleSetInvoiceNumber, handleSetSupplier, handleSetInvoiceTotal,
        deliveryDate, invoiceNumber, invoiceTotal, supplier, restId , region
    }: SupplierEntryFieldsProps
) {
    const checkDeliveryDate = () => {
        if (moment(deliveryDate).isBefore(moment().subtract(3, "years"))) {
            handleSetDeliveryDate('');
            alert(`You put in the date ${deliveryDate}, this is more than 3 years in the past, this is not allowed. Please check the date you entered. Or send to error Q if the date really is more than 3 years ago`)
        }
    }

    return (
        <div style={supplierEntryContainerStyle}>
            <div style={bigInputContainerStyle}>
                <span>Supplier</span>
                <SupplierSearch supplier={supplier}
                                restId={restId}
                                onChange={handleSetSupplier}
                                region={region}/>
            </div>
            <div style={dividerStyle}></div>
            <div style={inputContainerStyle}>
                <span>Delivery Date</span>
                <input
                    type="date"
                    value={deliveryDate}
                    onChange={x => handleSetDeliveryDate(x.target.value)}
                    style={{
                        border: "1px solid black",
                        backgroundColor: nullUndefOrEmpty(deliveryDate) ? Colors.danger : '',
                        color: nullUndefOrEmpty(deliveryDate) ? Colors.dangerText : '',
                    }}
                    onBlur={checkDeliveryDate}/>
            </div>
            <div style={dividerStyle}></div>
            <div style={inputContainerStyle}>
                <span>Invoice Number</span>
                <input
                    type="text"
                    value={invoiceNumber}
                    onChange={x => handleSetInvoiceNumber(x.target.value)}
                    placeholder={"Invoice Number?"}
                    style={{
                        border: "1px solid black",
                        backgroundColor: nullUndefOrEmpty(invoiceNumber) ? Colors.danger : '',
                        color: nullUndefOrEmpty(invoiceNumber) ? Colors.dangerText : '',
                    }}/>
            </div>
            <div style={dividerStyle}></div>
            <div style={inputContainerStyle}>
                <span>Invoice Total</span>
                <InvoiceEntryNumberInput
                    value={invoiceTotal}
                    onChange={handleSetInvoiceTotal}
                    placeholder="Invoice Total?"
                    style={{
                        border: "1px solid black",
                        backgroundColor: nullUndefOrEmpty(invoiceTotal) ? Colors.danger : '',
                        color: nullUndefOrEmpty(invoiceTotal) ? Colors.dangerText : '',
                    }}
                    required={true}/>
            </div>
        </div>
    );
}

const supplierEntryContainerStyle: React.CSSProperties = {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    gap: 12,
};

const inputContainerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "min-content 160px",
    alignItems: "center",
    gap: 4,
};

const bigInputContainerStyle: React.CSSProperties = {
    ...inputContainerStyle,
    gridTemplateColumns: "auto 320px",
};

const dividerStyle: React.CSSProperties = {
    backgroundColor: "black",
    height: "2rem",
    width: "2px",
};
