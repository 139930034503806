import React, {useEffect, useState} from "react";
import {useWorkflowContext} from "../../../Contexts/WorkflowContext";
import {RelatedWorkflows, Workflow} from "../../../Domain/Workflow/Workflow";
import {Colors} from "../../../Colors";
import {Icon} from "../../../Components/Icon";
import moment, {Moment} from "moment";
import {StandardModal} from "../../../Components/Modal";
import {AttachmentsViewerModal} from "../../../Components/AttachmentViewerModal";

const workflowHistoryStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateAreas: "'search' 'workflows'",
    alignContent: "start",
    justifyContent: "center",
    overflow: "auto"
}

export default function WorkflowHistory() {
    const {getRelatedWorkflows} = useWorkflowContext();
    const [searchString, setSearchString] = useState<string>();
    const [showSearch, setShowSearch] = useState<boolean>(true);
    const [showError, setShowError] = useState<boolean>(false);
    const [relatedWorkflows, setRelatedWorkflows] = useState<RelatedWorkflows | undefined>();
    
    async function handleSearch() {
        if (!searchString) {
            return;
        }

        try {
            const rwf = await getRelatedWorkflows(searchString);
            setRelatedWorkflows(rwf);
            setShowSearch(false);
            setShowError(false);
        } catch (e) {
            setShowError(true);
        }
    }

    return (
        <div style={workflowHistoryStyle}>
            <div style={{gridArea: 'search', display: "flex", flexFlow: "column nowrap", alignItems: "center"}}>
                {showError && <h3 style={{color: Colors.dangerText}}>Could not find Workflow</h3>}
                {!showSearch && <div style={{gridArea: 'search', color: Colors.dangerDark}}>
                    <Icon name={'search'} onClick={() => setShowSearch(true)}/>
                </div>}
                {showSearch && <SearchBlock handleSubmit={handleSearch} setSearchString={setSearchString}/>}
            </div>
            <div style={{gridArea: 'workflows'}}>
                {relatedWorkflows &&
                    <WorkflowBlock workflow={relatedWorkflows.originalWorkflow} isOriginalWorkflow={true}/>
                }
                <hr/>
                {relatedWorkflows && relatedWorkflows.childWorkflows.map((wf, i) => (
                    <WorkflowBlock key={i} workflow={wf} isOriginalWorkflow={false}/>
                ))
                }
            </div>

        </div>
    )
}

type SearchBlockProps = {
    setSearchString: (searchString: string) => void;
    handleSubmit: () => void;
}

function SearchBlock({setSearchString, handleSubmit}: SearchBlockProps) {
    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        handleSubmit();
    }

    return (
        <div style={{
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            margin: 8,
            gap: 4
        }}>
            <form style={{display: "flex", flexFlow: "column nowrap", gap: 8}} onSubmit={onSubmit}>
                <label htmlFor={"search"}>Search: (full invoice, attachment grouping, or workflow ID)</label>
                <div style={{display: "flex", flexFlow: "row nowrap", justifyContent: "center", gap: 4}}>
                    <input style={{width: 248}} type="search" onChange={(e) => setSearchString(e.target.value)}/>
                    <input type="submit"/>
                </div>
            </form>
        </div>
    );
}

type WorkflowBlockProps = {
    workflow: Workflow;
    isOriginalWorkflow: boolean;
}

const infoRowStyle: React.CSSProperties = {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    gap: 8
}

const infoGridStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "1fr 2fr 2fr",
    gap: 8,
    justifyItems: "start",
    alignItems: "center"
}

const infoStackStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateRows: "auto auto",
    gap: 8,
    justifyItems: "center"
}

function WorkflowBlock({workflow, isOriginalWorkflow}: WorkflowBlockProps) {
    const [showRowInfoModal, setShowRowInfoModal] = useState(false);
    const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);

    const handleCloseAttachmentsModal = () => setShowAttachmentsModal(false);

    async function toClipBoard(item: string) {
        await navigator.clipboard.writeText(item);
    }

    return (
        <div style={{display: "flex", flexFlow: "column", gap: 8, margin: 12}}>
            <div style={infoRowStyle}>
                <button onClick={() => setShowAttachmentsModal(true)}><Icon name={'photo_camera'}/></button>
                <div style={{fontSize: 24}} onClick={() => toClipBoard(workflow.id)}>{workflow.id}</div>
                <div>({isOriginalWorkflow ? 'Original' : 'Child'})</div>
                {workflow.rows.length + workflow.categoryOnlyRows.length > 0 &&
                    <button onClick={() => setShowRowInfoModal(true)}>Row Details</button>
                }
            </div>
            <div style={infoRowStyle}>
                {workflow.invoiceNumber && <InfoItem label={'InvoiceNumber'} value={workflow.invoiceNumber}/>}
                {workflow.deliveryDate && <InfoItem label={'DeliveryDate'} value={workflow.deliveryDate}/>}
                {workflow.invoiceTotal && <InfoItem label={'InvoiceTotal'} value={workflow.invoiceTotal}/>}
                {workflow.restaurantName && <InfoItem label={'RestaurantName'} value={workflow.restaurantName}/>}
                {workflow.supplierName &&
                    <InfoItem label={'SupplierName'} value={workflow.supplierName} showDivider={false}/>}
            </div>
            <div>
                <h4 style={{margin: 4, padding: 0, fontWeight: "bold", fontSize: 24}}>Current Status</h4>
                <div style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    gap: 16,
                    fontSize: 18,
                    fontWeight: "bold",
                    alignContent: "center",
                    alignItems: "center"
                }}>
                    <div>{workflow.stage}</div>
                    <div style={{
                        placeSelf: "end",
                        fontSize: 16,
                        fontWeight: "normal",
                        maxWidth: "60vw"
                    }}>{workflow.stageReason}</div>
                </div>
            </div>
            <div>
                {workflow.notes.length > 0 &&
                    <h4 style={{margin: 4, padding: 0, fontWeight: "bold", fontSize: 24}}>Notes</h4>
                }
                {workflow.notes.length > 0 &&
                    <div style={{...infoGridStyle, fontSize: 18, fontWeight: "bold"}}>
                        <div>Stage</div>
                        <div style={{placeSelf: "end"}}>Note</div>
                        <div style={{placeSelf: "end"}}>Created</div>
                    </div>
                }
                {workflow.notes.map(n => (
                    <div style={infoGridStyle}>
                        <div style={{fontWeight: "bold"}}>{n.userName}</div>
                        <div style={{maxWidth: "20vw"}}>{n.note}</div>
                        <div style={{placeSelf: "end"}}>{n.createdTime.format('YYYY-MM-DD HH:mm')}</div>
                    </div>)
                )}
            </div>
            <div>
                <h4 style={{margin: 4, padding: 0, fontWeight: "bold", fontSize: 24}}>Sessions</h4>
                {workflow.sessions.length === 0 && <div>No Sessions</div>}
                {workflow.sessions.length > 0 &&
                    <div style={{...infoGridStyle, fontSize: 18, fontWeight: "bold"}}>
                        <div>Stage</div>
                        <div style={{placeSelf: "end"}}>Start</div>
                        <div style={{placeSelf: "end"}}>End?</div>
                    </div>
                }
                {workflow.sessions.map(s => {
                    return (
                        <div style={infoGridStyle}>
                            <div style={{fontWeight: "bold"}}>{s.workflowStage}</div>
                            <div style={{placeSelf: "end"}}>{s.start.format("YYYY-MM-DD HH:mm")}</div>
                            <div style={{placeSelf: "end"}}>{s.end?.format("YYYY-MM-DD HH:mm") ?? "N/A"}</div>
                        </div>
                    )
                })}
            </div>
            <AttachmentsViewerModal attachments={workflow.attachments} 
                                    showModal={showAttachmentsModal}
                                    handleCloseModal={handleCloseAttachmentsModal}/>
            <StandardModal closeFn={setShowRowInfoModal} shouldShow={showRowInfoModal}
                           header={<div>Row Info</div>}>
                <div style={{overflow: "auto", maxHeight: "55vh"}}>
                    {workflow.rows.length > 0 && <div style={{fontWeight: "bold", fontSize: 18}}>Item Rows</div>}
                    {workflow.rows.map(r => (
                        <div style={rowGridStyle}>
                            <div>{r.desc}</div>
                            <div>{r.total}</div>
                            <div>{r.uom}</div>
                            <div>{r.pack}</div>
                            <div>{r.brand}</div>
                        </div>
                    ))}
                    {workflow.categoryOnlyRows.length > 0 &&
                        <div style={{fontWeight: "bold", fontSize: 18}}>Category Rows</div>}
                    {workflow.categoryOnlyRows.length > 0 && workflow.rows.length > 0 &&
                        <div style={{margin: 8}}></div>}
                    {workflow.categoryOnlyRows.map(r => (
                        <div style={{...rowGridStyle, gridTemplateColumns: "1fr 1fr 1f"}}>
                            <div>{r.code}</div>
                            <div>{r.description}</div>
                            <div>{r.total}</div>
                        </div>
                    ))}
                </div>
            </StandardModal>
        </div>
    );
}

const rowGridStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr repeat(3, minmax(0, 1fr))",
    gap: 8,
}

const InfoItem = ({label, value, showDivider = true}: {
    label: string,
    value: string | number | Moment,
    showDivider?: boolean
}) => (
    <>
        <div style={infoStackStyle}>
            <div style={{fontSize: 16, fontWeight: "bold"}}>{label}</div>
            <div style={{
                fontSize: 14,
                fontWeight: "lighter"
            }}>{moment.isMoment(value) ? value.format('YYYY-MM-DD') : value}</div>
        </div>
        {showDivider && <DividerBar/>}
    </>
);

const DividerBar = () => <div style={{height: '5vh', width: 1, backgroundColor: 'black'}}></div>
