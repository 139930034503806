import {Colors} from "./Colors";
import React, {CSSProperties, PropsWithChildren, useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {UserService} from "./Services/UserService";
import {useLocation, useNavigate} from "react-router";
import {WorkflowStage} from "./Domain/Workflow/Workflow";
import {WorkflowContext} from "./Contexts/WorkflowContext";
import {RestaurantService} from "./Services/RestaurantService";

type CustomWorkflowUrls = '/multipleInvoiceErrorQueue'

export function NavBar() {
    const isApiUser = UserService.user.anyRole("TenantAdmin") && UserService.user.anyRole("SuperAdmin") === false
    return <header style={{backgroundColor: Colors.darkBlue, color: Colors.white, gridArea: "nav"}}>
        {isApiUser && <ApiUserNavBar/>}
        {!isApiUser && <AdminUserNavBar/>}
    </header>
}

function ApiUserNavBar() {
    const history = useNavigate();
    return <div style={{height: "auto"}}><NavButton url={"/callbacks"}>Callbacks</NavButton>
        <NavButton url={"/users"}>User Management</NavButton>
        <div style={{display: "inline-block", float: 'right', paddingRight: 10, paddingTop: 10}}>
            <a onClick={() => {
                UserService.logout();
                history("/login")
            }}>Logout</a>
        </div>
    </div>
}

function AdminUserNavBar() {
    const history = useNavigate();
    const {stageCounts, missingPackSizeCount, missingPackSizeCountL2, stageAges} = useContext(WorkflowContext);
    const [unverifiedHouseCount, setUnverifiedHouseCount] = useState<number | undefined>(undefined);
    const [shouldShow, setShouldShow] = useState(false);
    
    function handleClick() {
        setShouldShow(!shouldShow);
    }

    useEffect(() => {
        if (unverifiedHouseCount === undefined) {
            RestaurantService.getUnverifiedHouseCount()
                .then(r => r!.json()).then(setUnverifiedHouseCount);
        }
    }, []);

    const isDataEntry = UserService.user.anyRole("DataEntry");
    const isAdmin = UserService.user.anyRole("SuperAdmin");

    return <div style={{height: "auto"}}>
        {isDataEntry && <WorkflowNavButton level={0} counts={stageCounts} stage="SupplierEntry" aveQueueAges={stageAges}>SupplierEntry</WorkflowNavButton>}
        {isDataEntry && <WorkflowNavButton level={0} counts={stageCounts} stage="MISupplierEntry" aveQueueAges={stageAges}>MI SupplierEntry</WorkflowNavButton>}
        {isDataEntry && <WorkflowNavButton level={0} counts={stageCounts} stage="ItemCodeAndPrice" aveQueueAges={stageAges}>Item Code & Price</WorkflowNavButton>}
        {isDataEntry && <WorkflowNavButton level={0} counts={stageCounts} stage="GlCode" aveQueueAges={stageAges}>GL Queue</WorkflowNavButton>}
        {isDataEntry && <WorkflowNavButton level={0} counts={stageCounts} stage="CategoryOnly" aveQueueAges={stageAges}>CatOnly</WorkflowNavButton>}
        {isDataEntry && <WorkflowNavButton level={0} stage="PackSize" aveQueueAges={stageAges} missingPackSizeCount={missingPackSizeCount}>Pack Size</WorkflowNavButton>}
        {isAdmin && <WorkflowNavButton level={1} stage="PackSize" aveQueueAges={stageAges} missingPackSizeCount={missingPackSizeCountL2}>Pack Size L2</WorkflowNavButton>}
        {isAdmin && <WorkflowNavButton level={0} counts={stageCounts} stage="NewSupplier" aveQueueAges={stageAges}>New Supplier</WorkflowNavButton>}
        {isAdmin && <WorkflowNavButton level={0} counts={stageCounts} stage="PictureIssue" aveQueueAges={stageAges}>Picture Issue</WorkflowNavButton>}
        {isAdmin && <WorkflowNavButton level={0} counts={stageCounts} stage="Error" aveQueueAges={stageAges}>Error</WorkflowNavButton>}
        {isAdmin && <WorkflowNavButton level={0} counts={stageCounts} stage="VerifyCategoryOnly" aveQueueAges={stageAges}>Verify Cat Only</WorkflowNavButton>}
        {isAdmin && <WorkflowNavButton level={0} customUrl={'/multipleInvoiceErrorQueue'} counts={stageCounts} stage="MultipleInvoiceError" aveQueueAges={stageAges}>MI Errors</WorkflowNavButton>}
        {isAdmin && <RestSuppHouseWorkflowNavButton unVerifiedCount={unverifiedHouseCount}>RestSuppHouse Verification</RestSuppHouseWorkflowNavButton>}
        {isAdmin && <DropDownNavMenu caption='Admin' onclick={handleClick} shouldShow={shouldShow}>
            <DropDownNavButton url='/packsizeMerge' onclick={handleClick}>Packsize Merge</DropDownNavButton>
            <DropDownNavButton url='/invoiceEntry' onclick={handleClick}>Invoice Entry</DropDownNavButton>
            <DropDownNavButton url='/userTimeTracking' onclick={handleClick}>User Time Tracking</DropDownNavButton>
            <DropDownNavButton url='/viewWorkflow' onclick={handleClick}>View Workflow</DropDownNavButton>
            <DropDownNavButton url='/adminTasks' onclick={handleClick}>Admin Tasks</DropDownNavButton>
            <DropDownNavButton url='/workflowHistory' onclick={handleClick}>Workflow History</DropDownNavButton>
            <DropDownNavButton url='/manageRestaurantSupplier' onclick={handleClick}>Manage Restaurant Suppliers</DropDownNavButton>
        </DropDownNavMenu>
        }
        {isAdmin && <NavButton url={"/callbacks"}>Callbacks</NavButton>}
        <div style={{display: "inline-block", float: 'right', paddingRight: 10, paddingTop: 10}}>
            <a onClick={() => {
                UserService.logout();
                history("/login")
            }}>Logout</a>
        </div>
    </div>
}

function DropDownNavMenu(props: PropsWithChildren<{ caption?: string, shouldShow?: boolean, onclick: () => void, items?: any[] }>) {
    const {caption, shouldShow, onclick, items} = props

    const buttonStyle: CSSProperties = {
        display: 'inline-grid',
        height: '100%',
        alignItems: 'center',
        padding: '10px 20px',
        boxSizing: 'border-box',
        textAlign: 'center',
        position: 'relative'
    }

    const menuStyle: CSSProperties = {
        display: 'block',
        boxSizing: 'border-box',
        background: Colors.white,
        textAlign: 'left',
        position: 'absolute',
        top: '37px',
        zIndex: 99,
        border: '1px solid black'
    }

    return <div style={buttonStyle}>
        <div style={{cursor: 'pointer'}} onClick={onclick}>{caption}</div>
        {shouldShow && <div style={menuStyle}>
            {props.children}
        </div>}
    </div>
}

function WorkflowNavButton(props: PropsWithChildren<{ level: number, stage: WorkflowStage, counts?: any, missingPackSizeCount?: number, aveQueueAges?: any, customUrl?: CustomWorkflowUrls }>) {
    const {level, stage, counts, missingPackSizeCount, aveQueueAges, customUrl} = props;
    const count = counts?.filter((x: any) => x.stage === stage)[0];
    const aveAge = aveQueueAges?.filter((x: any) => x.stage === stage)[0];
    const countToUse = missingPackSizeCount === undefined ? count && count.count || 0 : missingPackSizeCount;
    const url = customUrl || `/invoiceWorkflow/${stage}/${level}`;
    
    return <NavButton url={url} aveAge={aveAge}>
        {props.children} ({countToUse})
    </NavButton>
}

function RestSuppHouseWorkflowNavButton(props: PropsWithChildren<{ unVerifiedCount?: any}>) {
    const {unVerifiedCount} = props;
    const url = '/restaurantSupplierHouseWorkflow';
    return <NavButton url={url}>
        {props.children} ({unVerifiedCount})
    </NavButton>
}

function NavButton(props: PropsWithChildren<{ url: string, aveAge?: any }>) {
    const { pathname } = useLocation();
    const {url, aveAge} = props;
    const isActive = pathname.indexOf(url) > -1
    const buttonStyle: CSSProperties = {
        display: "inline-grid",
        height: '100%',
        alignItems: "center",
        padding: isActive ? '10px 20px' : '10px 10px',
        boxSizing: 'border-box',
        background: isActive ? Colors.lightBlue : undefined,
        textAlign: "center",
    }
    return <div style={buttonStyle}>
        <Link to={url} style={{color: Colors.white, textDecoration: "none"}}>{props.children}</Link>
        {aveAge && <div>
            {aveAge.ave}
        </div>}
    </div>
}

function DropDownNavButton(props: PropsWithChildren<{ url: string, onclick: () => void, counts?: Count[], stage?: WorkflowStage }>) {
    const { pathname } = useLocation();
    const {url, onclick, stage, counts} = props;
    const count = counts?.filter((x: any) => x.stage === stage)[0]?.count;
    const isActive = pathname.indexOf(url) > -1
    const buttonStyle: CSSProperties = {
        display: "inline-grid",
        height: '100%',
        alignItems: "center",
        padding: isActive ? '10px 20px' : '10px 10px',
        boxSizing: 'border-box',
        background: isActive ? Colors.lightBlue : undefined,
        minWidth: '200px'
    }

    return <div style={buttonStyle}>
        <Link to={url} onClick={onclick} style={{color: Colors.black, textDecoration: "none"}}>{props.children} {stage === 'MultipleInvoiceError' && `(${count || 0})`}</Link>
    </div>
}

type Count = {
    stage: WorkflowStage,
    count: number
}