import React, {PropsWithChildren, ReactNode} from "react";
import {Colors} from "../Colors";

interface Props{
    shouldShow?: boolean;
}

export function Modal({shouldShow, children}: PropsWithChildren<Props>){
    if(!shouldShow){
        return null;
    }
    return <div style={{
        display: "grid",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(10,10,10,.65)",
        justifyContent: "center",
        alignContent: "center",
        zIndex: 1000
    }}>
        {children}
    </div>
}

interface StandardModalProps extends Props {
    header?: ReactNode,
    footer?: ReactNode,
    closeFn?: (v: boolean) => void 
}

export function StandardModal(props: PropsWithChildren<StandardModalProps>){
    return <Modal {...{...props, children:undefined}}>
        <div style={{backgroundColor: Colors.white, display: "grid", gridTemplateAreas:"'header' 'body' 'footer", gridTemplateRows:"auto 1fr auto", borderRadius: 5, overflow: "hidden", minHeight: 200, minWidth: 300, boxShadow: "3px 2px 9px 3px rgba(20, 20, 20, .8)"}}>
            <div style={{gridArea: 'header', padding: 5, background: Colors.lightBlue, fontSize: "1.2em"}}>
                {props.header}
            </div>
            <div style={{gridArea: 'body', paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10}}>
                {props.children}
            </div>
            <div style={{gridArea: 'footer'}}>
                {props.footer}
                {!props.footer && props.closeFn && <div style={{display: "grid", justifyContent: "end", padding: 5}}>
                    <button onClick={() => props.closeFn?.(false)}>Close</button>
                </div>}
            </div>
        </div>
    </Modal>
}