import {Uom, UOMService} from "../Domain/UOMService";
import Select, {createFilter} from "react-select";
import React, {useEffect, useState} from "react";
import {Colors} from "../Colors";
import {filter} from "lodash";

const uomOptions = UOMService.getAllUoms()
    .filter(x => x !== "Millimeter" && x !== "Foot" && x !== "Inch" && x !== "Yard" && x !== "Centimeter" && x !== "Meter"
        && x !== "Number300Can" && x !== "Number303Can" && x !== "Unknown" && x !== "Pack")
    .map(x => {
    return {
        value: x,
        label: x
    }
});

interface Props {
    uom?: Uom,
    onChange?: (u?: Uom) => void
    required?: boolean;
}

export function UomSelect({uom, onChange, required}: Props) {
    const provided = uomOptions.filter(x => x.value === uom)[0];
    const [selectValue, setSelectedValue] = useState(provided);
    const [options, setOptions] = useState(uomOptions);
    
    const onChanged = (x: any) => {
        setSelectedValue(x);
        if (onChange) {
            onChange(x?.value)
        }
    };
    
    useEffect(() => {
        const provided = uomOptions.filter(x => x.value === uom)[0];
        setSelectedValue(provided);
    }, [uom])
    
    let styles = undefined;
    if(required && !selectValue){
        styles = {
            control:(provided: any) => {
                return {...provided, backgroundColor: Colors.danger}
            }
        };
    }
    
    const filterOptions = (searchTerm: string) => {
        const lowered = searchTerm.toLocaleLowerCase();
        let filteredOptions = uomOptions.filter(x => x.value.toLocaleLowerCase().indexOf(lowered) > -1);
        filteredOptions = [...filteredOptions.filter(x => x.value.toLocaleLowerCase().startsWith(lowered)), ...filteredOptions.filter(x => x.value.toLocaleLowerCase().startsWith(lowered) == false)]
        const exactMatch = filteredOptions.filter(x => x.value.toLocaleLowerCase() === lowered)[0];
        if(exactMatch) {
            filteredOptions = [exactMatch]
        }
        
        setOptions(filteredOptions)
    }
    
    return <Select value={selectValue} styles={styles} isClearable={false} options={options} onInputChange={filterOptions} placeholder="Select Uom" onChange={onChanged}/>
}