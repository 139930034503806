import {Colors} from "../../Colors";
import React, {useContext, useEffect, useState} from "react";
import {
    RasiTenantThatCanNotSendToVerifyCatOnly,
    WorkflowRow,
    WorkflowStage
} from "../../Domain/Workflow/Workflow";
import {DisplayServices} from "../../Services/DisplayServices";
import {BadPictureModal, InvoiceEntryNumberInput, itemCodeAndPriceGridStyle, ItemCodeQuantity, PackSizeFields} from "./WorkflowCommonComponents";
import {InfoBox} from "../../Components/InfoBox";
import {SaveButton} from "../../Components/Buttons";
import {SupplierSearch} from "../../Components/SupplierSearch";
import {Supplier} from "../../Domain/Supplier";
import {StandardModal} from "../../Components/Modal";
import {Css} from "../../Css";
import moment from "moment";
import {WorkflowContext} from "../../Contexts/WorkflowContext";
import {CategoryOnlyItemRow, categoryOnlyEntryStyle} from "./GlCodeAndCatOnlySidebar";
import {MathUtils} from "../../Services/MathUtils";

export function ErrorSidebar() {
    const [showMoveQueueModal, setShowMoveQueueModal] = useState(false);
    const [showBadPicModal, setShowBadPicModal] = useState(false);
    const {workflow, updateWorkflow, addRow, addCategoryOnlyRow, deleteStandardPackSizeRows, moveToBadPictureQueue, getEligibleStagesForErrorQueueMovement} = useContext(WorkflowContext);
    const [eligibleStages, setEligibleStages] = useState<WorkflowStage[]>([]);
    const [isLoadingEligibleStages, setIsLoadingEligibleStages] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    
    const [expectedTotal, _setExpectedTotal] = useState(workflow!.invoiceTotal);
    const setExpectedTotal = (total?: number) => {
        workflow!.invoiceTotal = total;
        _setExpectedTotal(total);
    }
    
    const [taxes, _setTaxes] = useState(workflow!.taxes);
    const setTaxes = (value?: number) => {
        _setTaxes(value);
        workflow!.taxes = value;
    }
    
    const [deliveryCharge, _setDeliveryCharge] = useState(workflow!.deliveryCharge);
    const setDeliveryCharge = (value?: number) => {
        _setDeliveryCharge(value);
        workflow!.deliveryCharge = value;
    }
    
    const [otherCharge, _setOtherCharge] = useState(workflow!.otherCharge);
    const setOtherCharge = (value?: number) => {
        _setOtherCharge(value);
        workflow!.otherCharge = value;
    }
    
    const [invoiceNumber, _setInvoiceNumber] = useState(workflow!.invoiceNumber);
    const setInvoiceNumber = (value?: string) => {
        _setInvoiceNumber(value);
        workflow!.invoiceNumber = value;
    }

    const [deliveryDate, _setDeliveryDate] = useState<string | undefined>(workflow!.deliveryDate?.format("YYYY-MM-DD"));
    const setDeliveryDate = (d: string) => {
        _setDeliveryDate(d);
        workflow!.deliveryDate = moment(d);
    }

    let presetSupplier: any = null;
    if (workflow!.supplierId) {
        presetSupplier = {
            name: workflow!.supplierName,
            id: workflow!.supplierId
        }
    }
    
    const [supplier, _setSupplier] = useState<Supplier | undefined>(presetSupplier);
    const setSupplier = (s: Supplier | undefined) => {
        _setSupplier(s);
        workflow!.supplierId = s?.id;
        workflow!.supplierName = s?.name;
    }

    const [cameFromCatOnlyOrGlQueue, _setCameFromCatOnlyOrGlQueue] = useState(false);
    const handleToggleCameFromGlOrCatOnlyQueue = () => _setCameFromCatOnlyOrGlQueue(!cameFromCatOnlyOrGlQueue);
    
    useEffect(() => {
            _setExpectedTotal(workflow!.invoiceTotal || undefined);
            _setTaxes(workflow!.taxes || undefined);
            _setDeliveryCharge(workflow!.deliveryCharge || undefined);
            _setOtherCharge(workflow!.otherCharge || undefined);
            _setDeliveryDate(workflow!.deliveryDate?.format("YYYY-MM-DD") || '');
            _setInvoiceNumber(workflow!.invoiceNumber || '');
            _setKegDepositQuantity(workflow!.kegDepositQuantity || undefined);
            _setKegDepositCharge(workflow!.kegDepositCharge || undefined);
            _setKegCreditQuantity(workflow!.kegCreditQuantity || undefined);
            _setKegCreditCharge(workflow!.kegCreditCharge || undefined);
            if (workflow!.supplierId) {
                presetSupplier = {
                    name: workflow!.supplierName,
                    id: workflow!.supplierId
                }
                _setSupplier(presetSupplier);
            }
            
            const hasEverBeenToGlQueue = workflow!.sessions.some(session => session.workflowStage === "GlCode") || workflow!.categoryOnlyRows.length > 0;
            _setCameFromCatOnlyOrGlQueue(hasEverBeenToGlQueue);
        }, [workflow]);

    useEffect(() => {
        getEligibleStagesForErrorQueueMovement().then((stages) => {
            setEligibleStages([...stages]);
            setIsLoadingEligibleStages(false);
        });
    }, [workflow])

    const updateFieldsAndGetNext = async () => {
        workflow!.supplierId = supplier?.id;
        workflow!.taxes = taxes;
        workflow!.deliveryCharge = deliveryCharge;
        workflow!.otherCharge = otherCharge;
        workflow!.invoiceTotal = expectedTotal;
        workflow!.rows = workflow!.rows.filter(x => x.itemCode || x.pack || x.size || x.total || x.desc || x.quantity);
        workflow!.categoryOnlyRows = workflow!.categoryOnlyRows.filter(x => x.total || x.code);
        workflow!.kegDepositQuantity = kegDepositQuantity;
        workflow!.kegDepositCharge = kegDepositCharge;
        workflow!.kegCreditQuantity = kegCreditQuantity;
        workflow!.kegCreditCharge = kegCreditCharge;
        
        await updateWorkflow(workflow!);
    }
    
    const [kegDepositCharge, _setKegDepositCharge] = useState(workflow!.kegDepositCharge);
    const [kegDepositQuantity, _setKegDepositQuantity] = useState(workflow!.kegDepositQuantity);
    const [kegCreditCharge, _setKegCreditCharge] = useState(workflow!.kegCreditCharge);
    const [kegCreditQuantity, _setKegCreditQuantity] = useState(workflow!.kegCreditQuantity);

    const setKegDepositQuantity = (value?: number) => {
        _setKegDepositQuantity(value);
        workflow!.kegDepositQuantity = value;
    }

    const setKegDepositCharge = (value?: number) => {
        _setKegDepositCharge(value);
        workflow!.kegDepositCharge = value;
    }

    const setKegCreditQuantity = (value?: number) => {
        if (value) {
            value = MathUtils.convertNumberToNegativeIfPositive(value);
        }
        _setKegCreditQuantity(value);
        workflow!.kegCreditQuantity = value;
    }

    const setKegCreditCharge = (value?: number) => {
        if (value) {
            value = MathUtils.convertNumberToNegativeIfPositive(value);
        }
        _setKegCreditCharge(value);
        workflow!.kegCreditCharge = value;
    }

    const saveWorkflow = () => {
        if (nullUndefOrEmpty(workflow?.supplierId)) {
            alert("Please choose a Supplier before saving.");
            return;
        }
        if (!isSaving) {
            setIsSaving(true);
            _updateWorkflow()
                .then(x => setIsSaving(false));
        }
    }

    const _updateWorkflow = async () => {
        if (nullUndefOrEmpty(workflow?.supplierId) === false) {
            workflow!.stage = "Approved";
            workflow!.stageReason = "Workflow approved from the Error queue"
            await updateFieldsAndGetNext();
        }
    };
    const [changeCounter, setChangeCounter] = useState(0);
    const changed = () => setChangeCounter(changeCounter + 1);
    const [rowToAutoFocus, setRowToAutoFocus] = useState(0);
    const [fieldToFocus, setFieldToFocus] = useState<string>();

    const moveToQueue = async (qToMoveTo: WorkflowStage) => {
        workflow!.stage = qToMoveTo;
        workflow!.stageReason = `Workflow moved to ${qToMoveTo} from the Error queue`;
        await updateFieldsAndGetNext();
        setShowMoveQueueModal(false);
    }

    const getRowNumber = (target: HTMLInputElement) => {
        let itemToCheck: any = target;
        let i = 0;
        let rowNumber = 0;
        while (itemToCheck.parentElement && i < 15) {
            i++;
            const value: any = itemToCheck.parentElement?.attributes?.getNamedItem('data-row-number')?.value;
            if (isFinite(value)) {
                rowNumber = +value;
                break;
            }
            itemToCheck = itemToCheck.parentElement;
        }
        return rowNumber;
    };

    const moveColumn = (target: HTMLInputElement, direction: number) => {
        const fieldType = target.attributes.getNamedItem("data-field-type")?.value;
        setFieldToFocus(fieldType);
        const rowNumber = getRowNumber(target);
        if (rowNumber !== null && rowNumber !== undefined) {
            setRowToAutoFocus(rowNumber + direction);
        }
    };

    const handleMoveToBadPictureQueue = async (reason: string) => await moveToBadPictureQueue(workflow!, reason, setShowBadPicModal);

    const keyDownHandler = (event: KeyboardEvent) => {
        const ctrlKeyIsDown = event.ctrlKey;
        if (event.target instanceof HTMLInputElement) {
            if (ctrlKeyIsDown && (event.key == "ArrowDown" || event.key == "Enter")) {
                moveColumn(event.target, 1);
            } else if (ctrlKeyIsDown && (event.key == "ArrowUp" || (event.key == "Enter") && event.shiftKey)) {
                moveColumn(event.target, -1);
            }
        }
        if (event.ctrlKey && ((event.altKey && event.key == "a") || (event.key == "Insert" && event.altKey == false))) {
            addRow(getRowNumber(event.target as any))
        }
        if (event.ctrlKey && event.altKey && event.key == "Insert") {
            addRow(getRowNumber(event.target as any))
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    const sendToCatOnly = () => {
        if (RasiTenantThatCanNotSendToVerifyCatOnly.includes(workflow!.tenantId)) {
            alert("This Rasi invoice needs to have line items entered and is not a category only invoice");
            return;
        }
        
        if(window.confirm(`Are you sure this invoice is category only?`)) {
            workflow!.stage = "VerifyCategoryOnly";
            workflow!.stageReason = "Workflow moved to VerifyCategoryOnly stage from Error queue";
            updateFieldsAndGetNext();
        }
    }
    
    const handleDeleteNonCatRows = () => {
        if (window.confirm(`Are you sure you want to delete all non-category rows?`)) {
            deleteStandardPackSizeRows();
        }
    }

    if (!workflow) {
        return <div/>
    }

    return <div style={{
        display: "grid",
        gridTemplateAreas: "'header' 'body' 'footer'",
        gridTemplateRows: 'auto 1fr auto',
        height: '100%',
        backgroundColor: Colors.lightGrey,
        padding: '0 3px',
        borderRight: 'solid ' + Colors.darkBlue + ' 1px',
        minHeight: 0
    }}>
        <div style={{gridArea: 'header', paddingBottom: 3, borderBottom: 'black solid 1px'}}>
            <div style={{display: "grid", padding: 6, gridTemplateColumns: "auto auto", justifyContent: "space-between"}}>
                <div>Supplier</div>
                <div><input type={"checkbox"} onChange={handleToggleCameFromGlOrCatOnlyQueue} checked={cameFromCatOnlyOrGlQueue}/>Errored from Gl or Cat Only Queue?</div>
            </div>
            <SupplierSearch onChange={setSupplier} supplier={supplier} restId={workflow!.restaurantId} region={workflow!.region}/>
            {cameFromCatOnlyOrGlQueue 
                ? <div style={categoryOnlyEntryStyle}>
                    <div style={{gridArea: 'code', textAlign: "center"}}>Code</div>
                    <div style={{gridArea: 'description', textAlign: "center"}}>Description</div>
                    <div style={{gridArea: 'total', textAlign: "center"}}>Total</div>
                </div>
                : <div style={{...itemCodeAndPriceGridStyle, marginTop: 10}}>
                    <div style={{gridArea: 'itemCode', textAlign: "center"}}>Item Code</div>
                    <div style={{gridArea: 'quantity', textAlign: "center"}}>Quantity</div>
                    <div style={{gridArea: 'total', textAlign: "center"}}>Total</div>
                </div>
            }
        </div>
        <div style={{gridArea: 'body', overflowY: 'auto', height: '100%', paddingBottom: 20, boxSizing: 'border-box'}}>
            {cameFromCatOnlyOrGlQueue
                ? workflow!.categoryOnlyRows.map(x => <CategoryOnlyItemRow row={x} key={'glcoderow' + x.rowId} isGlCode={false} changed={changed} autofocus={x.rowNumber == rowToAutoFocus} field={"total"} />) 
                : workflow!.rows.map(x => <ItemRow row={x} key={'codeandpricerow' + x.rowId} changed={changed} autofocus={x.rowNumber == rowToAutoFocus} field={fieldToFocus as any}/>)
            }
            <div>
                <button onClick={() => cameFromCatOnlyOrGlQueue ? addCategoryOnlyRow() : addRow()}>+ Row</button>
            </div>
        </div>
        <div style={{gridArea: 'footer', paddingBottom: 10}}>
            <div style={Css.columns("1fr 3fr", 10)}>
                <div>Invoice Number</div>
                <div><input type="string" value={invoiceNumber} onChange={x => setInvoiceNumber(x.target.value)} style={{backgroundColor: nullUndefOrEmpty(invoiceNumber) ? Colors.danger : '', color: nullUndefOrEmpty(invoiceNumber) ? Colors.dangerText : ''}}/></div>
                <div>Delivery Date</div>
                <div><input type="date" value={deliveryDate} onChange={x => setDeliveryDate(x.target.value)}/></div>
                <div>Expected Total</div>
                <div><InvoiceEntryNumberInput onChange={setExpectedTotal} value={expectedTotal} placeholder="Expected Total"/></div>
                <div>Taxes</div>
                <div><InvoiceEntryNumberInput onChange={setTaxes} value={taxes} placeholder="Taxes"/></div>
                <div>Delivery</div>
                <div><InvoiceEntryNumberInput onChange={setDeliveryCharge} value={deliveryCharge} placeholder="Delivery Charge"/></div>
                <div>Other</div>
                <div><InvoiceEntryNumberInput onChange={setOtherCharge} value={otherCharge} placeholder="Other Charges"/></div>
            </div>
            <div style={{marginTop: 8, marginBottom: 8}}>
                <div style={kegDepositInputRow}>
                    <div></div>
                    <div style={{...kegDepositInputContainerStyle, ...kegDepositSmallLabelStyle}}>
                        <div>Quantity</div>
                        <div>Total</div>
                    </div>
                </div>
                <div style={kegDepositInputRow}>
                    <div>Keg Deposit</div>
                    <div style={kegDepositInputContainerStyle}>
                        <InvoiceEntryNumberInput onChange={setKegDepositQuantity} value={kegDepositQuantity} placeholder="0" fieldType={"number"}/>
                        <InvoiceEntryNumberInput onChange={setKegDepositCharge} value={kegDepositCharge} placeholder="$0" fieldType={"number"}/>
                    </div>
                </div>
                <div style={kegDepositInputRow}>
                    <div>Keg Return</div>
                    <div style={kegDepositInputContainerStyle}>
                        <InvoiceEntryNumberInput onChange={setKegCreditQuantity} value={kegCreditQuantity} placeholder="0"/>
                        <InvoiceEntryNumberInput onChange={setKegCreditCharge} value={kegCreditCharge} placeholder="$0"/>
                    </div>
                </div>
            </div>
            <div style={{display: "grid", alignItems: "center", gridTemplateColumns: "auto 1fr auto"}}>
                <div>
                    <button onClick={() => setShowMoveQueueModal(true)} disabled={isLoadingEligibleStages}>{isLoadingEligibleStages ? 'LoadingStages' : 'Move To Q'}</button>
                    <button onClick={() => setShowBadPicModal(true)}>Bad Picture</button>
                </div>
                <div style={{...Css.center(), display: "grid", gridTemplateColumns: cameFromCatOnlyOrGlQueue ? "auto auto" : "auto"}}>
                    <button onClick={sendToCatOnly}>Cat Only</button>
                    {cameFromCatOnlyOrGlQueue && <button onClick={handleDeleteNonCatRows}>Delete NonCat/GL Rows</button>}
                </div>
                <SaveButton onClick={saveWorkflow} disabled={isSaving}>{isSaving ? 'Saving...' : 'Save'}</SaveButton>
            </div>
            {!workflow!.totalsMatch && <div style={{backgroundColor: Colors.danger, padding: 10, color: "white"}}>Warning: Totals do not match.
                <div style={{textAlign: "right"}}>Expected: {DisplayServices.money(workflow!.invoiceTotal)}</div>
                <div style={{textAlign: "right", textDecoration: 'underline'}}>- Actual: {DisplayServices.money(workflow!.calculatedTotal)}</div>
                <div style={{textAlign: "right"}}>{DisplayServices.money(workflow!.invoiceTotal! - workflow!.calculatedTotal)}</div>
            </div>}
        </div>
        <StandardModal closeFn={setShowMoveQueueModal} shouldShow={showMoveQueueModal} header={<div>Move to which Q:</div>}>
            <div style={{display: "grid", rowGap: 10}}>
                {eligibleStages.filter(x => x !== "Error" && x !== "DevProblem" && x !== "NotProcessable" && x !== "Approved" && x !== "PictureCleanup" && x !== "PictureIssue").map(x => {
                    return <button key={x} onClick={() => moveToQueue(x)}>{x}</button>
                })}
            </div>
        </StandardModal>

        <BadPictureModal setShowBadPicModal={setShowBadPicModal} showBadPicModal={showBadPicModal} onSet={handleMoveToBadPictureQueue} />
    </div>
}

function ItemRow(props: { row: WorkflowRow, changed: () => void, autofocus: boolean, tabHit?: boolean, field: "quantity" | "total" | undefined}) {
    const {row} = props;
    return <div style={{borderBottom: 'solid black 2px', paddingBottom: 3, marginBottom: 10}}>
        <ItemCodeQuantity {...props}/>
        {!row.packSize && <PackSizeFields {...props}/>}
        {row.packSize && <InfoBox style={{marginTop: 5}}>Pack size {row.packSize.packSizeDesc} selected by system</InfoBox>}
    </div>
}

const kegDepositInputRow: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "70% 30%",
    fontSize: 16,
    marginBottom: 4,
}

const kegDepositInputContainerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "70px 70px",
    gap: 8,
}

const kegDepositSmallLabelStyle: React.CSSProperties = {
    fontSize: 12,
}