import moment from 'moment';
import {BaseService} from "../Services/BaseService";
import _ from "lodash";

export abstract class AbstractDomainObject {
    private static regexIso8601 = /^(\d{4})-(\d{2})-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})Z?)?$/;
    private static dateKeyRegex = /.*Date$/;
    constructor(dto?: any){
        if(dto){
            this.updateFromDTO(dto);
        }
    }

    public updateFromDTO(dto: any): this{
        let updatedObject = Object.assign(this, _.cloneDeep(dto));
        const objectsSeen: any[] = [];
        AbstractDomainObject.convertDateStringsToDates(updatedObject, objectsSeen);
        return updatedObject;
    }

    protected toArray<U extends AbstractDomainObject>(dto: any, type: new (dto: any) => U): U[] {
        return BaseService.toArray(dto, type);
    }

    protected toClass<U extends AbstractDomainObject>(dto: any, type: new (dto: any) => U): U {
        return BaseService.toClass(dto, type);
    }

    private static isFieldThatEndsInDate = (field: string) => AbstractDomainObject.dateKeyRegex.test(field);

    private static pad = (n: number) => {
        return ("0" + n).slice(-2);
    };

    private static convertDateStringsToDates = (input: any, objectsSeen: any[]) => {
        if (!input || typeof input !== "object") {
            return;
        }
        for (let key in input) {
            if (!input.hasOwnProperty(key)) {
                continue;
            }
            let value = input[key];
            if (typeof value === "string" && value.match(AbstractDomainObject.regexIso8601)) {
                input[key] = moment(value);
            } else if (typeof value === "object") {
                if(objectsSeen.indexOf(value) > -1){
                    continue;
                }
                objectsSeen.push(value);
                AbstractDomainObject.convertDateStringsToDates(value, objectsSeen);
            }
        }
    };

    private static convertDatesToDateStrings = (requestData: any) => {
        // Ignore things that aren't objects.
        if (typeof requestData !== "object") return;
        for (let key in requestData) {
            if (!requestData.hasOwnProperty(key)) continue;
            let value = requestData[key];
            if (Object.prototype.toString.call(value) === '[object Date]' && isFinite(value)) {
                let dateValue = <Date>value;
                if (AbstractDomainObject.isFieldThatEndsInDate(key)) {
                    let textValue = "";
                    if(dateValue.getHours() == 0 && dateValue.getSeconds() == 0){
                        textValue = dateValue.getFullYear() + "-" + AbstractDomainObject.pad(dateValue.getMonth() + 1) + "-" + AbstractDomainObject.pad(dateValue.getDate());
                    }else{
                        textValue = dateValue.getUTCFullYear() + "-" + AbstractDomainObject.pad(dateValue.getUTCMonth() + 1) + "-" + AbstractDomainObject.pad(dateValue.getUTCDate());
                    }
                    requestData[key] = textValue;
                }
                else {
                    requestData[key] = value.toISOString();
                }
            } else if (typeof value === "object") {
                // Recurse into object
                AbstractDomainObject.convertDatesToDateStrings(value);
            }
        }
    };

    public toDTO(): any{
        return Object.assign({}, this);
    }
    
    public hasMeaningfulValue(...args: any[]){
        return args.some(o => o === null || o === undefined || o === "") == false;
    }
}

