import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {WorkflowService} from "../../Services/WorkflowService";
import {Workflow} from "../../Domain/Workflow/Workflow";
import {DisplayServices} from "../../Services/DisplayServices";
import {Css} from "../../Css";

export function WorkflowViewer() {
    const {workflowId} = useParams();
    const decodedId = decodeURIComponent(workflowId || '');
    const history = useNavigate();
    const [workflow, setWorkflow] = useState<Workflow>()

    if (workflow && workflow.id !== decodedId) {
        history(`/viewWorkflow/${encodeURIComponent(workflow!.id)}`, {replace: true});
    }

    useEffect(() => {
        if (decodedId && workflow?.id !== decodedId) {
            WorkflowService.getWorkflowById(decodedId).then(setWorkflow);
        }
    }, [decodedId])

    const loadRandomWorkflow = () => {
        WorkflowService.getRandom().then(setWorkflow)
    }

    return <div style={{...Css.fullHeight(), gridTemplateRows: 'auto auto 1fr'}}>
        <button onClick={loadRandomWorkflow}>Random!</button>
        {workflow && <>
            <div style={Css.columns("1fr 1fr 1fr 1fr", 10)}>
                <div>Total: {workflow.invoiceTotal}</div>
                <div>Invoice #: {workflow.invoiceNumber}</div>
                <div>Supplier: {workflow.supplierName}</div>
                <div>Delivery Date: {DisplayServices.date(workflow.deliveryDate)}</div>
            </div>
            <div style={{...Css.fullHeight(), overflowY: 'auto'}}>
                {workflow.attachments.map(x => <div>
                    {WorkflowService.isPdf(x) && <embed style={{width: '100%', height: '100%'}} src={WorkflowService.getImgSource(x)} type="application/pdf"/>}
                    {WorkflowService.isPdf(x) === false && <img src={WorkflowService.getImgSource(x)} width='100%'/>}
                </div>)}
            </div>
        </>}
    </div>
}