import {Colors} from "../../Colors";
import React, {useContext, useEffect, useState} from "react";
import {RasiTenantThatCanNotSendToVerifyCatOnly, WorkflowRow} from "../../Domain/Workflow/Workflow";
import {DisplayServices} from "../../Services/DisplayServices";
import {BadPictureModal, InvoiceEntryNumberInput, itemCodeAndPriceGridStyle, ItemCodeQuantity} from "./WorkflowCommonComponents";
import {SaveButton} from "../../Components/Buttons";
import {WorkflowContext} from "../../Contexts/WorkflowContext";
import {Css} from "../../Css";
import {MathUtils} from "../../Services/MathUtils";

export function ItemCodeAndPriceSidebar() {
    const [rowToAutoFocus, setRowToAutoFocus] = useState(0);
    const [fieldToFocus, setFieldToFocus] = useState<string>();
    const {workflow, updateWorkflow, isSafeToSave, addRow, moveToBadPictureQueue, splitWorkflowImagesAndSendToMISupplierEntry} = useContext(WorkflowContext);
    const [taxes, _setTaxes] = useState(workflow!.taxes);
    const [kegDepositCharge, _setKegDepositCharge] = useState(workflow!.kegDepositCharge);
    const [kegDepositQuantity, _setKegDepositQuantity] = useState(workflow!.kegDepositQuantity);
    const [kegCreditCharge, _setKegCreditCharge] = useState(workflow!.kegCreditCharge);
    const [kegCreditQuantity, _setKegCreditQuantity] = useState(workflow!.kegCreditQuantity);
    const [isSaving, setIsSaving] = useState(false);

    const setTaxes = (value?: number) => {
        _setTaxes(value);
        workflow!.taxes = value;
    }
    const [deliveryCharge, _setDeliveryCharge] = useState(workflow!.deliveryCharge);
    const setDeliveryCharge = (value?: number) => {
        _setDeliveryCharge(value);
        workflow!.deliveryCharge = value;
    }
    const [otherCharge, _setOtherCharge] = useState(workflow!.otherCharge);
    const setOtherCharge = (value?: number) => {
        _setOtherCharge(value);
        workflow!.otherCharge = value;
    }
    
    const setKegDepositQuantity = (value?: number) => {
        _setKegDepositQuantity(value);
        workflow!.kegDepositQuantity = value;
    }
    
    const setKegDepositCharge = (value?: number) => {
        _setKegDepositCharge(value); 
        workflow!.kegDepositCharge = value;
    }

    const setKegCreditQuantity = (value?: number) => {
        if (value) {
            value = MathUtils.convertNumberToNegativeIfPositive(value);
        }
        _setKegCreditQuantity(value);
        workflow!.kegCreditQuantity = value;
    }
    
    const setKegCreditCharge = (value?: number) => {
        if (value) {
            value = MathUtils.convertNumberToNegativeIfPositive(value);
        }
        _setKegCreditCharge(value);
        workflow!.kegCreditCharge = value;
    }
    
    const [showBadPicModal, setShowBadPicModal] = useState(false);
    if (workflow!.rows.length === 0) {
        workflow!.addNewRow();
    }

    useEffect(() => {
        setKegDepositCharge(undefined);
        setKegDepositQuantity(undefined);
        setKegCreditCharge(undefined);
        setKegCreditQuantity(undefined);
        setOtherCharge(undefined);
        setTaxes(undefined);
        setDeliveryCharge(undefined);
        setRowToAutoFocus(0);
        setFieldToFocus(undefined);
    }, [workflow])

    const handleMoveToBadPictureQueue = async (reason: string) => await moveToBadPictureQueue(workflow!, reason, setShowBadPicModal);
    const _updateWorkflow = async (isCatOnly: boolean = false) => {
        if (isCatOnly == false) {
            workflow!.stage = "Approved";
            workflow!.stageReason = "Workflow approved from ItemCodeAndPrice entry";
        }
        const rowsWithIssues = workflow!.rows.filter(x => x.hasItemCodeAndPriceInfo() == false);
        if (rowsWithIssues.length) {
            const msg = rowsWithIssues.map(x => `Row: ${x.rowNumber} Item Code ${x.itemCode} is missing values. Please double check`).join("\n");
            alert(msg);
            return;
        }
        if (workflow!.totalsMatch == false && isCatOnly == false) {
            if (window.confirm(`There is a difference of ${workflow!.invoiceTotal! - workflow!.calculatedTotal} between the entered total and the calculated total. Are you sure you want to continue?`) == false) {
                return;
            }
        }
        workflow!.otherCharge = otherCharge;
        workflow!.deliveryCharge = deliveryCharge;
        workflow!.taxes = taxes;
        workflow!.kegDepositQuantity = kegDepositQuantity;
        workflow!.kegDepositCharge = kegDepositCharge;
        workflow!.kegCreditQuantity = kegCreditQuantity;
        workflow!.kegCreditCharge = kegCreditCharge;
        await updateWorkflow(workflow!);
    };

    const saveWorkflow = () => {
        if (!isSaving) {
            setIsSaving(true);
            _updateWorkflow(false)
                .then(x => setIsSaving(false));
        }
    }

    const [changeCounter, setChangeCounter] = useState(0);
    const changed = () => setChangeCounter(changeCounter + 1);

    const canSave = () => {
        return getReasonForNotBeingAbleToSave() === ''
    }

    const getReasonForNotBeingAbleToSave = () => {
        if (!isSafeToSave) {
            return "Waiting for info on rows";
        }
        const rowsWithSomeData = workflow!.rows.filter(x => x.itemCode || x.total);
        const rowNumbersMissingDesc = rowsWithSomeData.filter(x => !x.desc).map(x => x.rowNumber).join(", ")
        if (rowNumbersMissingDesc !== "") {
            return `Rows ${rowNumbersMissingDesc} are missing desc`;
        }
        return "";
    }

    const getRowNumber = (target: HTMLInputElement) => {
        let itemToCheck: any = target;
        let i = 0;
        let rowNumber = 0;
        while (itemToCheck.parentElement && i < 15) {
            i++;
            const value: any = itemToCheck.parentElement?.attributes?.getNamedItem('data-row-number')?.value;
            if (isFinite(value)) {
                rowNumber = +value;
                break;
            }
            itemToCheck = itemToCheck.parentElement;
        }
        return rowNumber;
    };

    const moveColumn = (target: HTMLInputElement, direction: number) => {
        const fieldType = target.attributes.getNamedItem("data-field-type")?.value;
        setFieldToFocus(fieldType);
        const rowNumber = getRowNumber(target);
        if (rowNumber !== null && rowNumber !== undefined) {
            setRowToAutoFocus(rowNumber + direction);
        }
    };

    const handleSplitWorkflowImages = async () => {
        await splitWorkflowImagesAndSendToMISupplierEntry(workflow!);
    }

    const keyDownHandler = (event: KeyboardEvent) => {
        const ctrlKeyIsDown = event.ctrlKey;
        if (event.target instanceof HTMLInputElement) {
            if (ctrlKeyIsDown && (event.key == "ArrowDown" || event.key == "Enter")) {
                moveColumn(event.target, 1);
            } else if (ctrlKeyIsDown && (event.key == "ArrowUp" || (event.key == "Enter") && event.shiftKey)) {
                moveColumn(event.target, -1);
            }
        }
        if (event.ctrlKey && ((event.altKey && event.key == "a") || (event.key == "Insert" && event.altKey == false))) {
            addRow(getRowNumber(event.target as any))
        }
        if (event.ctrlKey && event.altKey && event.key == "Insert") {
            addRow(getRowNumber(event.target as any) - 1)
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    const checkForLastRowHit = (r: WorkflowRow) => {
        if (r.rowNumber == workflow!.rows.length - 1) {
            addRow();
        }
    };

    const sendToCatOnly = () => {
        if (RasiTenantThatCanNotSendToVerifyCatOnly.includes(workflow!.tenantId)) {
            alert("This Rasi invoice needs to have line items entered and is not a category only invoice");
            return;
        }
        
        if (window.confirm(`Are you sure this invoice is category only?`)) {
            workflow!.stage = "VerifyCategoryOnly";
            _updateWorkflow(true)
        }
    }

    if (!workflow) {
        return <div/>
    }

    return <div style={{
        display: "grid",
        gridTemplateAreas: "'header' 'body' 'footer'",
        gridTemplateRows: 'auto 1fr auto',
        height: '100%',
        backgroundColor: Colors.lightGrey,
        padding: '0 3px',
        borderRight: 'solid ' + Colors.grey + ' 1px',
        minHeight: 0
    }}>
        <div style={{gridArea: 'header', paddingBottom: 3, borderBottom: 'black solid 1px'}}>
            <div style={{...itemCodeAndPriceGridStyle}}>
                <div style={{gridArea: 'itemCode', textAlign: "center"}}>Item Code</div>
                <div style={{gridArea: 'quantity', textAlign: "center"}}>Quantity</div>
                <div style={{gridArea: 'total', textAlign: "center"}}>Total</div>
            </div>
        </div>
        <div style={{gridArea: 'body', overflowY: 'auto', height: '100%', paddingBottom: 20, boxSizing: 'border-box'}}>
            {workflow.rows.map(x => <ItemRow row={x}
                                             key={'codeandpricerow' + x.rowId}
                                             changed={changed}
                                             autofocus={x.rowNumber == rowToAutoFocus}
                                             field={fieldToFocus as any}
                                             onFocus={checkForLastRowHit}
            />)}
        </div>
        <div style={{gridArea: 'footer'}}>
            <div style={{padding: "10"}}>
                <div style={kegDepositInputRow}>
                    <div></div>
                    <div style={kegDepositInputContainerStyle}>
                        <div>Quantity</div>
                        <div>Total</div>
                    </div>
                </div>
                <div style={kegDepositInputRow}>
                    <div>Keg Deposit</div>
                    <div style={kegDepositInputContainerStyle}>
                        <InvoiceEntryNumberInput onChange={setKegDepositQuantity} value={kegDepositQuantity} placeholder="0" fieldType={"number"}/>
                        <InvoiceEntryNumberInput onChange={setKegDepositCharge} value={kegDepositCharge} placeholder="$0" fieldType={"number"}/>
                    </div>
                </div>
                <div style={kegDepositInputRow}>
                    <div>Keg Return</div>
                    <div style={kegDepositInputContainerStyle}>
                        <InvoiceEntryNumberInput onChange={setKegCreditQuantity} value={kegCreditQuantity} placeholder="0"/>
                        <InvoiceEntryNumberInput onChange={setKegCreditCharge} value={kegCreditCharge} placeholder="$0"/>
                    </div>
                </div>
            </div>
            <div>
                <InvoiceEntryNumberInput onChange={setTaxes} value={taxes} placeholder="Taxes"/>
                <InvoiceEntryNumberInput onChange={setDeliveryCharge} value={deliveryCharge} placeholder="Delivery Charge"/>
                <InvoiceEntryNumberInput onChange={setOtherCharge} value={otherCharge} placeholder="Other Charges"/>
            </div>
            <div style={{display: "grid", gridTemplateColumns: "auto auto auto auto", justifyContent: "space-between", alignItems:"center"}}>
                <div>
                    <button onClick={() => setShowBadPicModal(true)}>Bad Picture</button>
                </div>
                <div style={Css.center()}>
                    <button onClick={sendToCatOnly}>Category Only</button>
                </div>
                <div>
                    <button onClick={handleSplitWorkflowImages}>MultipleInvoices</button>
                </div>
                {!canSave() && <div style={{backgroundColor: Colors.danger, padding: 10, color: "white"}}>{getReasonForNotBeingAbleToSave()}</div>}
                {canSave() && <SaveButton onClick={saveWorkflow} disabled={isSaving}>{isSaving ? 'Saving...' : 'Save'}</SaveButton>}
            </div>
            {!workflow.totalsMatch && <div style={{backgroundColor: Colors.danger, padding: 10, color: "white"}}>Warning: Totals do not match.
                <div style={{textAlign: "right"}}>Expected: {DisplayServices.money(workflow.invoiceTotal)}</div>
                <div style={{textAlign: "right", textDecoration: 'underline'}}>- Actual: {DisplayServices.money(workflow.calculatedTotal)}</div>
                <div style={{textAlign: "right"}}>{DisplayServices.money(workflow.invoiceTotal! - Math.abs(workflow.calculatedTotal))}</div>
            </div>}
        </div>
        <BadPictureModal setShowBadPicModal={setShowBadPicModal} showBadPicModal={showBadPicModal} onSet={handleMoveToBadPictureQueue}/>
    </div>
}

function ItemRow(props: { row: WorkflowRow, changed: () => void, autofocus: boolean, field: "quantity" | "total" | undefined, onFocus: (r: WorkflowRow) => void }) {
    return <div>
        <ItemCodeQuantity {...props}/>
    </div>
}

const kegDepositInputRow: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "70% 30%",
    fontSize: 12,
    marginBottom: 4,
}

const kegDepositInputContainerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "70px 70px",
    gap: 8,
}