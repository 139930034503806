import {WorkflowNote, WorkflowSession, WorkflowStage} from "../../Domain/Workflow/Workflow";
import React, {useContext, useEffect, useState} from "react";
import {WorkflowService} from "../../Services/WorkflowService";
import {Colors} from "../../Colors";
import {WorkflowContext} from "../../Contexts/WorkflowContext";

export function PictureIssueSidebar() {
    const [note, setNote] = useState("");
    const {workflow, updateWorkflow} = useContext(WorkflowContext);
    const [reason, setReason] = useState(workflow!.stageReason);
    const [prevStage, setPrevStage] = useState<WorkflowStage>("SupplierEntry");
    const [isSaving, setIsSaving] = useState(false);
    
    useEffect(() => {
        setReason(workflow!.stageReason);
        setNote("");
        setPrevStage(findLastGoodStage(workflow!.sessions));
    }, [workflow]);
    
    const markAsBad = async () => {
        workflow!.stage = "NotProcessable";
        workflow!.stageReason = reason;
        updateWorkflow(workflow!);
    };

    function findLastGoodStage(sessions: WorkflowSession[] | undefined): WorkflowStage {
        if (sessions) {
            let copySessions = [...sessions];
            let lastStage = copySessions.pop();
            while (lastStage && lastStage?.workflowStage !== "SupplierEntry" && lastStage?.workflowStage !== "ItemCodeAndPrice") {
                lastStage = copySessions.pop();
            }
            if (lastStage) {
                return lastStage?.workflowStage;
            }
            return "SupplierEntry";
        }
        return "SupplierEntry";
    }

    const markAsOk = (stage: WorkflowStage) => {
        if (!isSaving) {
            setIsSaving(true);
            _updateWorkflow(stage)
                .then(x => setIsSaving(false));
        }
    };
    
    const _updateWorkflow = async (stage: WorkflowStage) => {
        workflow!.stage = stage;
        workflow!.stageReason = "Marked as OK from the PictureIssue queue and then set to previous workflow stage";
        const newNote = new WorkflowNote();
        newNote.note = note;
        const noteAdded = await WorkflowService.addNewNote(workflow!, newNote!);
        workflow!.notes.push(noteAdded);
        await updateWorkflow(workflow!);
    };
    
    return <div>
        <div style={{display: "grid", padding: 5, background: Colors.danger, gridTemplateRows: "auto auto", rowGap: 10}}>
            <button disabled={!reason} onClick={markAsBad}>Picture is bad</button>
            <textarea value={reason} onChange={x => setReason(x.target.value)} style={{width: '100%'}} rows={5}/>
        </div>
        <div style={{display: "grid", padding: 5, background: Colors.success, marginTop: 30, gridTemplateRows: "auto auto auto", rowGap: 10}}>
            <button disabled={!note || isSaving} onClick={() => markAsOk(prevStage)}>Send back to {prevStage}</button>
            <textarea placeholder="Note to Data Entry team" style={{width: '100%'}} rows={5} value={note} onChange={x => setNote(x.target.value)}/>
        </div>
    </div>
}