import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {WorkflowService} from "./Services/WorkflowService";
import {Css} from "./Css";
import {orderBy} from "lodash";
import {Colors} from "./Colors";
import {UserService} from "./Services/UserService";
import {useNavigate} from "react-router";

const psuedoCode = 
`public class BeInvoiceApi{
    private string tokenCache = null;
    private Date tokenAcquireTime = null;
    
    private string GetAuthToken(){
        if(tokenCache = null || tokenAquireTime.plusMinutes(14) > now){
            var results = HttpClient.Post("https://beinvoiceentry.com/api/authorize", JSON.stringify({username: "userName", password: "1234"}))
            tokenCache = results;
            tokenAcquireTime = new Date();
        }
    }
    
    public void SendNewAttachmentGrouping(File[] files){
        var token = GetAuthToken();
        HttpClient.Post("https://beinvoiceentry.com/api/attachmentGrouping", JSON.stringify({
            body:{
                Attachments: [{filename: "myFile.jpg", contents: "Base_64_encoded_file_here"}]
            },
            headers: [["Authorize", "Bearer " + token]]
        }))
    }
}`

export function HomePage() {
    const [percentiles, setPercentiles] = useState<any>();
    const history = useNavigate()
    const user = UserService.user
    
    useEffect(() => {
        if(user.anyRole("DataEntry") && user.isSuperAdmin === false){
            history("/invoiceWorkflow/SupplierEntry/0");
            return;
        }
        WorkflowService.getPercentiles().then(setPercentiles)
    }, [user]);
    
    let percentilesArray: any = [];
    if(percentiles){
        for(const key in percentiles){
            const value = percentiles[key];
            percentilesArray.push({
                percentile: key,
                value: value
            });
        }
        percentilesArray = orderBy(percentilesArray, x => x.percentile);
    }
    
    return <div style={{width: '50vw', minWidth: 300, maxWidth: 1200, margin: "50px auto 0px auto"}}>
        <div style={{marginTop: 10, marginBottom: 10}}>
            <div style={Css.textCenter()}>Rolling 30 day processing time percentiles:</div>
            {percentilesArray.length === 0 && <div style={Css.center()}>
                Loading...
            </div>}
            {percentilesArray.length > 0 && <div style={Css.columns(`repeat(${percentilesArray.length}, 1fr)`, 10)}>
                {percentilesArray.map((x : any) => <div key={x.percentile}>
                    <div style={{marginBottom: 3, borderBottom: "solid black 1px", textAlign: "center"}}>{x.percentile}%</div>
                    <div style={Css.textCenter()}>{(x.value / 60).toFixed(1)} <span style={{color: Colors.darkGrey, fontSize: '.7em'}}>hours</span></div>
                </div>)}
            </div>}
        </div>
        Welcome to the Buyers Edge Invoice Entry service. You can access the <a href="/swagger">swagger here</a>.
        <p>
            The normal scenario will be to create an api user in the <Link to="/users">users</Link> section. Then use that user in your code to post new attachment groupings. 
            You'll also want to register for callbacks for the groupings and invoices to get realtime updates. You can access it <Link to="/callbacks">here</Link>
        </p>
        <p>
            To authenticate and get a JWT to access the api, you can authenticate to the /api/authorize endpoint. Post the username and password and you'll get back a JWT token that can be passed up an Authorization header
        </p>
        <p>
            Here is some pseudo code demonstrating how to access the service using the JWT and make the initial requests.
        </p>
        <div style={{padding: 10, background: "#f5f2f0"}}>
            <pre style={{whiteSpace: "pre-wrap"}}>
                {psuedoCode}
            </pre>    
        </div>
    </div>
}