import { InvoiceService } from "../../Services/InvoiceService";
import { useParams } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { WorkflowService } from "../../Services/WorkflowService";
import { SaveButton } from "../../Components/Buttons";

export function InvoiceViewer() {
    const { invoiceId } = useParams();
    const [hasData, setHasData] = useState(false);
    const [rotation, setRotation] = useState<number>(0);
    const [zoomPercent, setZoomPercent] = useState<number>(100);
    const keyDownHandler = (event: KeyboardEvent) => {
        let handled = false;
        if (event.altKey && event.key === "ArrowRight") {
            setRotation(x => x + 90);
            handled = true;
        } else if (event.altKey && event.key === "ArrowLeft") {
            setRotation(x => x - 90);
            handled = true;
        }
        if (event.altKey && event.key === "+") {
            setZoomPercent(x => x + 10);
            handled = true;
        }
        if (event.altKey && event.key === "-") {
            setZoomPercent(x => x - 10);
            handled = true;
        }
        if (handled) {
            event.preventDefault();
        }
    };

    const [attachmentIds, setAttachmentIds] = useState<string[]>([])

    const getAttachmentIds = async (id: string) => {
        let data = await InvoiceService.getAttachmentsForInvoice(id)
        setAttachmentIds(data);
        setHasData(true);
    }

    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        };
    }, []);


    useEffect(() => {
        if (invoiceId && !hasData) {
            getAttachmentIds(invoiceId);
        }

    }, [invoiceId]);

    return <div style={{ display: 'grid', gridTemplateAreas: "'mainArea'", gridTemplateColumns: 'auto', minHeight: 0 }}>
        {invoiceId &&
            <div style={{ gridArea: "'mainArea'", minHeight: 0, overflowY: "auto", padding: 10, height: '100%' }}>
                <div style={{ height: '100%' }}>
                    <div style={{ height: '100%' }}>
                        {attachmentIds.map(x => <AttachmentPicture attachmentId={x} rotation={rotation} rotationChanged={() => setRotation(0)} zoomPercentage={zoomPercent} />)}

                    </div>
                </div>
            </div>}
    </div>
}

function AttachmentPicture(props: { attachmentId: string, rotation: number, rotationChanged: () => void, zoomPercentage: number }) {
    const { attachmentId, rotation, rotationChanged, zoomPercentage } = props;
    const [changeRev, setChangeRev] = useState(0);
    const [imgSource, setImgSource] = useState('');
    const [imgFileName, setImgFileName] = useState('');
    const imgRef = useRef<HTMLImageElement>(null);
    let rawImageWidth = null;
    if (imgRef?.current) {
        rawImageWidth = imgRef.current.width
    }
    let heightToUse: any = null;
    if (rotation == 90 || rotation == -90) {
        heightToUse = (rawImageWidth) + 'px';
    }

    const saveRotation = async () => {
        await WorkflowService.updateImgRotation(attachmentId, rotation);
        setChangeRev(changeRev + 1);
        setImgSource(imgSource + `?changeRev=${changeRev}`);
        rotationChanged();
    };

    const fetchData = async () => {
        setImgSource(WorkflowService.getImgSourceFromExternalId(attachmentId));
        await WorkflowService.getImgFileNameFromExternalId(attachmentId)
            .then((data) => {
                setImgFileName(data);

            })
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (imgFileName && imgFileName.toLowerCase().endsWith('pdf')) {
        return <embed style={{ width: '100%', height: '100%' }} src={imgSource} type="application/pdf" />
    }

    return <div>
        {rotation != 0 && <div>
            <SaveButton onClick={saveRotation}>Save Rotation</SaveButton>
        </div>}
        <img src={imgSource} ref={imgRef} style={{ width: zoomPercentage + "%", transform: 'rotate(' + rotation + "deg)", height: heightToUse }} />
    </div>

}
