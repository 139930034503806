import React, {useEffect, useState} from "react";
import {Attachment} from "../Domain/Workflow/Workflow";
import {flatMap} from "lodash";
import {Colors} from "../Colors";
import {AttachmentPicture} from "../Pages/InvoiceWorkflow/InvoiceWorkflowPage";
import {WorkflowService} from "../Services/WorkflowService";
import {AttachmentViewerModal} from "./AttachmentViewerModal";

type HorizontalImageStripProps = {
    attachments: Attachment[] | Attachment[][];
    direction: "row" | "column";
    shouldShowBorder?: boolean;
    attachmentsWithCompleteInfo?: Set<Attachment>;
    handleAttachmentClicked?: (attachment: Attachment) => void;
    selectedAttachmentId?: string | null;
    shouldShowSelectButton?: boolean;
    isLargerPdf?: boolean;
    setIsSelectingGrouping?: boolean;
    showGroupNumber?: boolean;
    finalizedGroups?: Attachment[][];
    allAttachments?: Attachment[][];
}

export function AttachmentViewerStrip({
                                          attachments,
                                          handleAttachmentClicked,
                                          direction,
                                          selectedAttachmentId,
                                          attachmentsWithCompleteInfo,
                                          shouldShowBorder = false,
                                          shouldShowSelectButton = false,
                                          isLargerPdf = false,
                                          setIsSelectingGrouping = false,
                                          showGroupNumber = false,
                                          finalizedGroups,
                                          allAttachments
                                      }: HorizontalImageStripProps) {
    const [attachmentsToShow, setAttachmentsToShow] = useState<Attachment[]>([]);
    const [attachmentForModal, setAttachmentForModal] = useState<Attachment | undefined>(undefined);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);

    useEffect(() => {
        setAttachmentsToShow(flatMap(attachments));
    }, [attachments])

    const handleAttachmentClickedForModal = (attachment: Attachment) => {
        setAttachmentForModal(attachment);
        setShowAttachmentModal(true);
    }

    const handleCloseModal = () => {
        setAttachmentForModal(undefined);
        setShowAttachmentModal(false);
    }

    const onAttachmentSelect = (attachment: Attachment) => {
        if (handleAttachmentClicked !== undefined) {
            handleAttachmentClicked(attachment);
        }
    }

    const getBorderStyle = (attachment: Attachment) => {
        if (!shouldShowBorder) {
            return {};
        }

        return attachmentsWithCompleteInfo?.has(attachment)
            ? {border: `4px solid ${Colors.darkGreen}`}
            : {border: `4px solid ${Colors.dangerDark}`};
    }

    const getGroupNumber = (attachment: Attachment) => {
        const maybeFinalizedGroupNumber = getFinalizedGroupNumber(attachment);
        if (maybeFinalizedGroupNumber !== undefined) {
            return maybeFinalizedGroupNumber;
        }
        return getGroupNumberFromAllAttachments(attachment);
    }
    
    const getGroupNumberFromAllAttachments = (attachment: Attachment) => {
        const num = allAttachments?.findIndex(group => group.includes(attachment));
        return num !== undefined && num !== -1 ? `[${num + 1}]` : undefined;
    }
    
    const getFinalizedGroupNumber = (attachment: Attachment) => {
        const num = finalizedGroups?.findIndex(group => group.includes(attachment));
        return num !== undefined && num !== -1 ? `${num + 1}` : undefined;
    }

    const getPdfDimensions = () => isLargerPdf
        ? {width: '68vw', height: '42vh'}
        : {width: '14vw', height: '17vh'};

    const attachmentIsSelected = (attachment: Attachment) => attachment.externalId === selectedAttachmentId;

    return (
        <div className="outerStripContainer"
             style={{...horizontalImageStripStyle, flexFlow: `${direction} nowrap`}}>
            {attachmentsToShow.map((attachment, i) => (
                <div
                    className="innerStripContainer"
                    style={{
                        height: "auto",
                        width: "auto",
                        outline: shouldShowBorder && attachmentIsSelected(attachment) ? `4px solid ${Colors.success}` : 'none'
                    }}
                    key={attachment.externalId}>
                    {WorkflowService.isPdf(attachment)
                        ? <div style={getBorderStyle(attachment)}>
                            <embed style={getPdfDimensions()} src={WorkflowService.getImgSource(attachment)}
                                   type="application/pdf"/>
                            {shouldShowSelectButton &&
                                <AttachmentViewerButtonRow attachment={attachment}
                                                           attachmentIsSelected={attachmentIsSelected(attachment)}
                                                           setIsSelectingGrouping={setIsSelectingGrouping}
                                                           handleSelectAttachment={onAttachmentSelect}
                                                           handleViewAttachment={handleAttachmentClickedForModal}
                                                           groupNumber={getGroupNumber(attachment)}
                                />
                            }
                        </div>
                        : <div><AttachmentPicture attachment={attachment}
                                                  zoomPercentage={100}
                                                  rotationChanged={() => null}
                                                  rotation={0}
                                                  handleAttachmentClick={() => onAttachmentSelect(attachment)}
                                                  customStyle={
                                                      !shouldShowBorder ? {}
                                                          : attachmentsWithCompleteInfo?.has(attachment)
                                                              ? {border: `4px solid ${Colors.darkGreen}`}
                                                              : {border: `4px solid ${Colors.dangerDark}`}
                                                  }
                        />
                            {shouldShowSelectButton &&
                                <AttachmentViewerButtonRow attachment={attachment}
                                                           attachmentIsSelected={attachmentIsSelected(attachment)}
                                                           setIsSelectingGrouping={setIsSelectingGrouping}
                                                           handleSelectAttachment={onAttachmentSelect}
                                                           handleViewAttachment={handleAttachmentClickedForModal}
                                                           groupNumber={getGroupNumber(attachment)}
                                />
                            }
                        </div>
                    }
                </div>
            ))}
            <AttachmentViewerModal attachment={attachmentForModal}
                                   showModal={showAttachmentModal}
                                   handleCloseModal={handleCloseModal}/>
        </div>
    );
}

type AttachmentViewerButtonRowProps = {
    attachment: Attachment;
    handleSelectAttachment: (attachment: Attachment) => void;
    handleViewAttachment: (attachment: Attachment) => void;
    attachmentIsSelected: boolean;
    setIsSelectingGrouping?: boolean;
    groupNumber?: string;
}

function AttachmentViewerButtonRow({
                                       attachment,
                                       handleSelectAttachment,
                                       handleViewAttachment,
                                       attachmentIsSelected,
                                       setIsSelectingGrouping = false,
                                       groupNumber
                                   }: AttachmentViewerButtonRowProps) {
    const [selectPicText, setSelectPicText] = useState<'SelectPic' | 'SelectGroup'>('SelectPic');

    useEffect(() => {
        setSelectPicText(setIsSelectingGrouping ? 'SelectGroup' : 'SelectPic');
    }, [setIsSelectingGrouping]);

    return (
        <div style={buttonRowStyle}>
            <button style={{backgroundColor: attachmentIsSelected ? Colors.lightBlue : 'buttonface'}}
                    onClick={() => handleSelectAttachment(attachment)}>{selectPicText}</button>
            {groupNumber !== undefined && <span>{groupNumber}</span>}
            <button style={{backgroundColor: 'buttonface'}}
                    onClick={() => handleViewAttachment(attachment)}>{'View'}</button>
        </div>
    );
}

const buttonRowStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4,
    padding: 4,
    flexFlow: "row nowrap",
}

const horizontalImageStripStyle: React.CSSProperties = {
    display: "flex",
    overflow: "auto",
    alignItems: "center",
    gap: 4,
    padding: 4,
}