import {AbstractDomainObject} from "../AbstractDomainObject";
import {Moment} from "moment";

export class Callback extends AbstractDomainObject{
    id!: string;
    createdTime!: Moment;
    tries!: CallbackTry[];
    callbackUrl!: string;
    finished!: boolean;
    internalId!: string;
    type!: string
    wasSuccessful!: boolean;
    
    updateFromDTO(dto: any): this {
        super.updateFromDTO(dto);
        this.tries = this.toArray(dto.tries, CallbackTry);
        return this;
    }
}

export class CallbackTry extends AbstractDomainObject{
    id!: string;
    createdTime!: Moment;
    resultCode!: number;
    resultMsg!: string;
    wasSuccessful!: boolean;
    jsonSent!: string;
}