import React from "react";
import {DangerButton} from "../../Components/Buttons";

export function AdminTasks() {
    const startBringOverDb = async () => {
        if(window.location.hostname.toLowerCase() != "qe.beinvoiceentry.com"){
            alert("Can not run this on anything other than QE");
            return;
        }
        if(window.confirm(`Are you really really really sure you want to do this? This will wipe out the DB on QE and replace it with current production DB?`)){
            if(window.confirm(`Are you super ultra sure you want to do this, it can be really bad if you do?`)){
                const promise = fetch("/api/InternalAdmin/bringOverDb", {method: "POST"});
                window.alert("Starting the process, it will take awhile and there will be no updates until it's done");
                await promise;
                window.alert("Done with DB update");
            }
        }
    }
    
    return <div>
        <DangerButton onClick={startBringOverDb}>Bring Over Prod Database</DangerButton>
    </div>
}
