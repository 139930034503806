import AsyncSelect from "react-select/async";
import React, {useEffect, useState} from "react";
import {Restaurant} from "../Domain/Restaurant";
import {RestaurantService} from "../Services/RestaurantService";
import {StylesConfig} from "react-select";

const RESTAURANT_INTERNAL_ID_PREFIX = 'restaurants/';

interface Props {
    restaurant?: { id: string, name: string } | null | string
    onChange: (x?: Restaurant) => void
    eRef?: any
}

export function RestaurantSearch(props: Props) {
    const [restaurant, setRestaurant] = useState<any>();
    const selectRestaurant = (x: any) => {
        setRestaurant(x)
        props.onChange(x?.value)
    }
    
    useEffect(() => {
        if (nullOrUndef(props.restaurant)) {
            setRestaurant(null);
        } else {
            if (typeof props.restaurant === 'string') {
                RestaurantService.getById(props.restaurant).then(x => {
                    setRestaurant({
                        value: x,
                        label: x.name,
                    });
                    props.onChange(x)
                })
            } else {
                setRestaurant({
                    value: props.restaurant,
                    label: props.restaurant!.name,
                })
            }
        }
    }, [props.restaurant])
    
    const selectStyle: StylesConfig = {
        control: (baseStyles) => ({
            ...baseStyles,
            width: '50vh',
        }),
    };
    
    const loadRestaurantOptions = async (x: string) => {
        if (x.startsWith(RESTAURANT_INTERNAL_ID_PREFIX)) {
            const restaurant = await RestaurantService.getById(x);
            return new Array<{value: Restaurant, label: string}>({value: restaurant, label: restaurant.name});
        }
        
        const restaurants = await RestaurantService.searchRestaurants(x);
        return restaurants.map(s => {
            let displayName = s.name;
            return {value: s, label: displayName}
        })
    };
        
    return <AsyncSelect styles={selectStyle} value={restaurant} ref={props.eRef}
                        isClearable={true} isSearchable={true}
                        onChange={selectRestaurant}
                        loadOptions={loadRestaurantOptions}/>
}