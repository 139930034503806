import React, {CSSProperties, PropsWithChildren} from "react";
import {Colors} from "../Colors";

interface Props{
    style?: CSSProperties
}

export function InfoBox({children, style}: PropsWithChildren<Props>) {
    return <div style={{borderRadius: 5, backgroundColor: Colors.info, border: "solid 1px " + Colors.darkBlue, padding: 5, ...style}}>
        {children}
    </div>
}