import React, {FocusEventHandler, RefObject, useState} from "react";

interface Props {
    supplierId: string;
    itemCode?: string;
    onChange?: (s?: string) => void;
    onBlur?: (event?:React.FocusEvent<HTMLInputElement>) => void;
    eRef?: RefObject<HTMLInputElement>;
    onFocus?: FocusEventHandler
    fieldType?: string;
}

export function ItemCodeSelect({itemCode, onChange, onBlur, eRef, onFocus, fieldType}: Props) {
    const [value, _setValue] = useState(itemCode);
    const setValue = (s?: string) => {
        _setValue(s);
        if (onChange) {
            onChange(s)
        }
    };

    return <input type="text" value={value} onChange={x => setValue(x.target.value)} data-field-type={fieldType} onBlur={onBlur} ref={eRef} onFocus={onFocus}/>
}