import React, {CSSProperties, Fragment, useEffect, useState} from "react";
import {SupplierSearch} from "../../Components/SupplierSearch";
import {useNavigate, useParams} from "react-router";
import {Supplier, SupplierHouse} from "../../Domain/Supplier";
import {Css} from "../../Css";
import {orderBy} from "lodash";

const headerStyle: CSSProperties = {cursor: 'pointer', display: 'grid', justifyContent: 'center', gridTemplateColumns: 'auto auto', alignItems: 'center'};

export function ManageSupplierPage() {
    const history = useNavigate();
    const {supplierId} = useParams();
    const [supplier, setSupplier] = useState<Supplier>();
    const [supplierName, setSupplierName] = useState<string>('');
    const [sortField, setSortField] = useState<string>('name');
    const [sortDir, setSortDir] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        setSupplierName(supplier?.name || '');
        if (supplier && supplier.id != supplierId) {
            history(`/manageSupplier/${encodeURIComponent(supplier?.id)}`, {replace: true})
        }
    }, [supplier])

    const headerClicked = (s: string) => {
        if (s == sortField) {
            setSortDir(sortDir == 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(s)
            setSortDir('asc');
        }
    }

    const houses = orderBy(supplier?.supplierHouses, sortField, sortDir);

    return <div style={Css.fullHeight()}>
        <SupplierSearch onChange={setSupplier} supplier={supplierId} includeHouses={true} region={'USA'}></SupplierSearch>
        {supplier && <div style={Css.fullHeight()}>
            <input type="text" value={supplier.name} onChange={x => setSupplierName(x.currentTarget.value)}
                   placeholder="Supplier Name"/>
            <div style={Css.clickable()}
                 onClick={() => navigator.clipboard.writeText(supplier?.externalId)}>externalId {supplier.externalId}</div>
            <div style={Css.clickable()} onClick={() => navigator.clipboard.writeText(supplier?.id)}>internal
                id {supplier.id}</div>
            <div>Rest Count {supplier.restaurantCount}</div>
            <div style={Css.clickable()}
                 onClick={() => navigator.clipboard.writeText(supplier?.platformId || '')}>Platform
                Id: {supplier.platformId}</div>
            <div style={Css.fullHeight()}>
                <div>Houses: {supplier.supplierHouses?.length || 0}</div>
                <div style={{overflowY: 'auto', width: "fit-content", ...Css.columns('auto auto auto auto auto', 10)}}>
                    <div style={headerStyle} onClick={() => headerClicked('name')}>
                        Name
                        {sortField == 'name' && <i className="material-icons">{sortDir == 'asc' ? 'arrow_drop_up' : 'arrow_drop_down'}</i>}
                    </div>
                    <div style={headerStyle} onClick={() => headerClicked('city')}>
                        City
                        {sortField == 'city' && <i className="material-icons">{sortDir == 'asc' ? 'arrow_drop_up' : 'arrow_drop_down'}</i>}
                    </div>
                    <div style={headerStyle} onClick={() => headerClicked('zipCode')}>
                        Zip
                        {sortField == 'zipCode' && <i className="material-icons">{sortDir == 'asc' ? 'arrow_drop_up' : 'arrow_drop_down'}</i>}
                    </div>
                    <div style={headerStyle} onClick={() => headerClicked('state')}>
                        State
                        {sortField == 'state' && <i className="material-icons">{sortDir == 'asc' ? 'arrow_drop_up' : 'arrow_drop_down'}</i>}
                    </div>
                    <div>SF</div>
                    {houses?.map(x => <HouseRow key={x.id} house={x}/>)}
                </div>
            </div>
        </div>}
    </div>
}

function HouseRow({house}: { house: SupplierHouse }) {
    return <Fragment>
        <div>{house.name}</div>
        <div>{house.city}</div>
        <div>{house.zipCode}</div>
        <div>{house.state}</div>
        <div><a
            href={`https://diningalliance.lightning.force.com/lightning/r/Distributor__c/${house.salesForceId}/view`}
            target="_blank">{house.salesForceId}</a></div>
    </Fragment>
}