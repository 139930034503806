export class UOMService{
    private static allUoms: Uom[] = ["Gallon",
    "Pound",
    "Quart",
    "Dozen",
    "Ounce",
    "Kilogram",
    "Each",
    "Liter",
    "Pint",
    "Gram",
    "Foot",
    "Inch",
    "Yard",
    "Centimeter",
    "Meter",
    "Milliliter",
    "Bottle750Ml",
    "Bottle375Ml",
    "Bottle15Liter",
    "Bottle175Liter",
    "Millimeter",
    "Number10Can",
    "Number300Can",
    "Number5Can",
    "Number303Can",
    "Case",
    "Unknown",
    "Pack",
    "Keg5G",
    "Keg7G",
    "Keg15G",
    "Keg50L"];

    private static shortNameMap: any = {
        Case: 'CS',
        Gallon : 'GL',
        Quart : 'QT',
        Pint : 'PT',
        Liter : 'LT',
        Milliliter : 'ML',
        Pound : 'LB',
        Kilogram : 'KG',
        Gram : 'GM',
        Ft : 'FT',
        Inch : 'IN',
        Yard : 'YD',
        Meter : 'M',
        Centimeter : 'CM',
        Millimeter : 'MM',
        Ounce : 'OZ',
        Dozen : 'DZ',
        Each : 'EA',
        Number10Can :'#10CN',
        Number5Can :'#5CN',
        Number300Can :'#300CN',
        Number303Can :'#303CN',
        Pack :'PK',
        Bottle750Ml: "750ML Bottle",
        Bottle375Ml: "375ML Bottle",
        Bottle15Liter: "1.5L Bottle",
        Bottle175Liter: "1.75L Bottle",
        Keg5G : "KEG 5.5G",
        Keg7G: "KEG 7.75G",
        Keg15G : "KEG 15.5G",
        Keg50L : "KEG 50L",
        Cup : "C",
        Teaspoon : "TSP",
        Tablespoon : "TBSP"
    };

    private static prettyNameMap = {
        'CASE': 'Case',
        'GALLON' : 'Gallon',
        'QUART' : 'Quart',
        'PINT' : 'Pint',
        'LITER' : 'Liter',
        'MILLILITER' : 'Milliliter',
        'POUND' : 'Pound',
        'KILOGRAM' : 'Kilogram',
        'GRAM' : 'Gram',
        'FT' : 'Foot',
        'FOOT' : 'Foot',
        'INCH' : 'Inch',
        'YARD' : 'Yard',
        'METER' : 'Meter',
        'CENTIMETER' : 'Centimeter',
        'MILLIMETER' : 'Millimeter',
        'OUNCE' : 'Ounce',
        'DOZEN' : 'Dozen',
        'EACH' : 'Each',
        'NUMBER10CAN' :'#10 Can',
        'NUMBER5CAN' :'#5 Can',
        'NUMBER300CAN' :'#300 Can',
        'NUMBER303CAN' :'#303 Can',
        'PACK' :'Pack',
        'BOTTLE750ML': "750ML Bottle",
        "BOTTLE375ML": "375ML Bottle",
        "BOTTLE1_5LITER": "1.5L Bottle",
        "BOTTLE1_75LITER": "1.75L Bottle",
        "KEG5G" : "KEG 5.5G",
        "KEG7G": "KEG 7.75G",
        "KEG15G" : "KEG 15.5G",
        "KEG50L" : "KEG 50L",
        "CUP" : "Cup",
        "TEASPOON" : "Teaspoon",
        "TABLESPOON" : "Tablespoon"
    };
    
    public static getAllUoms(){
        return UOMService.allUoms.slice(0);
    }

    public static getShortName(uglyUom?:Uom) {
        if(!uglyUom){
            return uglyUom;
        }
        let prettyValue = this.shortNameMap[uglyUom];
        return prettyValue?prettyValue:uglyUom;
    }
}

export type Uom = "Gallon" |
    "Pound" |
    "Quart" |
    "Dozen" |
    "Ounce" |
    "Kilogram" |
    "Each" |
    "Liter" |
    "Pint" |
    "Gram" |
    "Foot" |
    "Inch" |
    "Yard" |
    "Centimeter" |
    "Meter" |
    "Milliliter" |
    "Bottle750Ml" |
    "Bottle375Ml" |
    "Bottle15Liter" |
    "Bottle175Liter" |
    "Millimeter" |
    "Number10Can" |
    "Number300Can" |
    "Number5Can" |
    "Number303Can" |
    "Case" |
    "Unknown" |
    "Pack" |
    "Keg5G" |
    "Keg7G" |
    "Keg15G" |
    "Keg50L";