import {AbstractDomainObject} from "./AbstractDomainObject";

export class User extends AbstractDomainObject {
    id!: string;
    username!: string;
    firstName!: string;
    lastName!: string;
    email!: string;
    roles!: string[];
    tenants!: string[];
    isActive!: boolean;

    allRole(...roles: Role[]) {
        if (this.isSuperAdmin) {
            return true;
        }
        return roles.some(x => this.roles.indexOf(x) === -1) === false
    }

    anyRole(...roles: Role[]) {
        if (this.isSuperAdmin) {
            return true;
        }
        return roles.some(x => this.roles.indexOf(x) !== -1);
    }

    get isSuperAdmin() {
        return this.roles.some(x => x === "SuperAdmin");
    }
}

export class Tenant extends AbstractDomainObject {
    name!: string;
    id!: string;
}

export type Role = "TenantAdmin" | "DataEntry" | "SuperAdmin";