import {AbstractDomainObject} from "../AbstractDomainObject";
import moment from "moment";
import {Uom} from "../UOMService";
import _ from "lodash";
import {PackSize, SalesUom} from "../PackSize";
import {IBaseWorkflowRow, ICategoryOnlyWorkflowRow} from "./IBaseWorkflowRow";

export class Workflow extends AbstractDomainObject {
    id!: string;
    supplierId?: string;
    supplierName?: string;
    invoiceNumber?: string;
    deliveryDate?: moment.Moment;
    invoiceTotal?: number;
    taxes?: number;
    deliveryCharge?: number;
    otherCharge?: number;
    kegDepositQuantity?: number;
    kegDepositCharge?: number;
    kegCreditQuantity?: number;
    kegCreditCharge?: number;
    stage!: WorkflowStage;
    stageReason?: string;
    level!: number;
    notes!: WorkflowNote[];
    sessions!: WorkflowSession[];
    rows!: WorkflowRow[];
    categoryOnlyRows!: CategoryOnlyWorkflowRow[];
    attachments!: Attachment[];
    restaurantId?: string;
    restaurantIdForCustomer?: string;
    restaurantName?: string;
    supplierHouseId?: string;
    restaurantSupplierAccountNumber?: string;
    customerProvidedData?: any;
    customerNotes?: string;
    customerNotesTime?: moment.Moment;
    tenantId!: string;
    tenantName!: string;
    shouldProcessAsMultiInvoice!: boolean;
    region!: string;

    updateFromDTO(dto: any): this {
        super.updateFromDTO(dto);
        this.rows = this.toArray(dto.rows, WorkflowRow);
        this.categoryOnlyRows = this.toArray(dto.categoryOnlyRows, CategoryOnlyWorkflowRow) ?? [];
        this.notes = this.toArray(dto.notes, WorkflowNote);
        this.sessions = this.toArray(dto.sessions, WorkflowSession);
        this.attachments = this.toArray(dto.attachments, Attachment);
        this.invoiceNumber = dto.invoiceNumber; // This is needed because invoice numbers can look like dates, and thus will be auto converted to moments instead of plain strings, so manually re-assign
        return this;
    }

    toDTO(): any {
        const dto: any = Object.assign({}, this);
        dto.deliveryDate = this.deliveryDate?.format('YYYY-MM-DD');
        dto.rows = this.rows.filter(x => x.itemCode || x.quantity || x.total).map(x => x.toDTO());
        dto.categoryOnlyRows = this.categoryOnlyRows.filter(x => x.code || x.total).map(x => x.toDTO());
        dto.attachments = this.attachments.map(x => x.toDTO());
        return dto;
    }

    public addNewRow(): WorkflowRow {
        const newRow = new WorkflowRow();
        this.rows.push(newRow);
        this.setRowNumbers();
        return newRow;
    }

    public addNewCategoryOnlyRow(): CategoryOnlyWorkflowRow {
        const newRow = new CategoryOnlyWorkflowRow();
        this.categoryOnlyRows.push(newRow);
        this.setRowNumbers();
        return newRow;
    }

    private setRowNumbers() {
        this.rows.forEach((x, index) => {
            x.rowNumber = index;
        });
        this.categoryOnlyRows.forEach((x, index) => {
            x.rowNumber = index;
        });
    }

    removeRow(row: WorkflowRow | CategoryOnlyWorkflowRow) {
        if (row instanceof CategoryOnlyWorkflowRow) {
            this.categoryOnlyRows.removeItem(row);
        } else {
            this.rows.removeItem(row);
        }
        this.setRowNumbers();
    }

    removeStandardPackSizeRows() {
        this.rows = [];
    }

    get totalsMatch(): boolean {
        if (this.invoiceTotal !== null && this.invoiceTotal !== undefined) {
            const absDiff = Math.abs(this.calculatedTotal - this.invoiceTotal);
            return absDiff < .001;
        }
        return false;
    }

    get calculatedTotal(): number {
        return _.sumBy(this.rows, x => x.total ?? 0) + _.sumBy(this.categoryOnlyRows, x => x.total ?? 0) + (this.taxes ?? 0) + (this.otherCharge ?? 0) + (this.deliveryCharge ?? 0) + this.kegCreditTotal + this.kegDepositTotal;
    }

    get kegCreditTotal(): number {
        return this.kegCreditCharge ?? 0;
    }

    get kegDepositTotal(): number {
        return this.kegDepositCharge ?? 0;
    }
    
    get categoryOnlyInvoice(): boolean {
        return this.rows.length === 0 && this.categoryOnlyRows.length > 0;
    }

    insertRowAfter(rowNumber: any) {
        return this.insertRow(rowNumber, 1)
    }

    insertRowBefore(rowNumber: any) {
        return this.insertRow(rowNumber, 0)
    }
    
    insertCategoryOnlyRowAfter(rowNumber: any) {
        return this.insertCategoryOnlyRow(rowNumber, 1)
    }
    
    insertCategoryOnlyRowBefore(rowNumber: any) {
        return this.insertCategoryOnlyRow(rowNumber, 0)
    }

    private insertRow(rowNumber: any, offset: number) {
        let index = this.rows.findIndex(x => x.rowNumber == rowNumber);
        if (rowNumber === -1) {
            index = 0;
            offset = 0;
        }
        if (index > -1) {
            const newRow = new WorkflowRow();
            this.rows.splice(index + offset, 0, newRow);
            this.setRowNumbers();
            return newRow;
        }
        return this.addNewRow();
    }
    
    private insertCategoryOnlyRow(rowNumber: any, offset: number) {
        let index = this.categoryOnlyRows.findIndex(x => x.rowNumber == rowNumber);
        if (rowNumber === -1) {
            index = 0;
            offset = 0;
        }
        if (index > -1) {
            const newRow = new CategoryOnlyWorkflowRow();
            this.categoryOnlyRows.splice(index + offset, 0, newRow);
            this.setRowNumbers();
            return newRow;
        }
        return this.addNewCategoryOnlyRow();
    }
}

export class WorkflowNote extends AbstractDomainObject {
    createdTime!: moment.Moment;
    userName!: string;
    userId!: string;
    note!: string;
    source!: string;
    id!: string;
}

export class WorkflowSession extends AbstractDomainObject {
    userName!: string;
    userId!: string;
    workflowStage!: WorkflowStage;
    stageLevel!: number;
    start!: moment.Moment;
    end?: moment.Moment;
    externalId!: string;
}

export class WorkflowRow extends AbstractDomainObject implements IBaseWorkflowRow {
    rowNumber!: number;
    isCatchWeight!: boolean;
    quantity?: number;
    total?: number;
    itemCode?: string;
    desc?: string;
    packSizeId?: string;
    brand?: string;
    brandItemCode?: string;
    pack?: number;
    size?: number;
    uom?: Uom;
    salesUom?: SalesUom;
    rowId: number;
    packSize?: PackSize;
    private static rowIdCounter = 0;
    rowInfo?: RowInfo;

    constructor(dto?: any) {
        super(dto);
        this.rowId = WorkflowRow.rowIdCounter++;
    }

    updateFromDTO(dto: any): this {
        super.updateFromDTO(dto);
        if (dto.packSize) {
            this.packSize = this.toClass(dto.packSize, PackSize);
        }
        this.itemCode = dto.itemCode; //We need this because the item code can look like a date and we need to make sure it's a string, not a date
        return this;
    }

    toDTO(): any {
        const result = super.toDTO();
        delete result.packSize;
        return result;
    }

    get casePrice() {
        if (!this.total || !this.quantity) {
            return 0;
        }
        return this.total / this.quantity
    }

    get unitPrice() {
        if (!this.total || !this.quantity) {
            return 0;
        }
        if (this.packSize) {
            return this.casePrice / this.packSize.totalUnits;
        } else if (this.pack && this.size) {
            return this.casePrice / (this.pack * this.size);
        }
        return 0;
    }

    hasItemCodeAndPriceInfo() {
        if (!this.itemCode && !this.total && !this.quantity) {
            return true;
        }
        return this.hasMeaningfulValue(this.itemCode, this.total, this.quantity);
    }

    isValidPackSizeData(): boolean {
        return this.pack != null && this.size != null && this.uom != undefined;
    }
}

export class CategoryOnlyWorkflowRow extends AbstractDomainObject implements ICategoryOnlyWorkflowRow {
    rowId: number;
    rowNumber!: number;
    code?: string;
    total?: number;
    description?: string;

    private static rowIdCounter = 0;

    constructor(dto?: any) {
        super(dto);
        this.rowId = CategoryOnlyWorkflowRow.rowIdCounter++;
    }
}

export class RowInfo extends AbstractDomainObject {
    issues!: string[];
    packSizeId?: string;
    avgPrice?: number;
    description?: string;
}

export class Attachment extends AbstractDomainObject {
    externalId!: string;
    fileKey!: string;
    hasMultiplePages!: boolean;
    pageNumber!: number;
    parentAttachmentGroupingId!: string;
    supplierId?: string;
    supplierName?: string;
    invoiceNumber?: string;
    deliveryDate?: moment.Moment;
    invoiceTotal?: number;
    originalFileName?: string;
    isBadPicture?: boolean;
    badPictureReason?: string;
    hasError?: boolean;
    errorReason?: string;
    isNewSupplier?: boolean;
    hasBeenReviewed?: boolean;
    supplierHouseId?: string;
    restaurantSupplierAccountNumber?: string;
    
    updateFromDTO(dto: any): this {
        super.updateFromDTO(dto);
        this.invoiceNumber = dto.invoiceNumber; // This is needed because invoice numbers can look like dates, and thus will be auto converted to moments instead of plain strings, so manually re-assign
        return this;
    }

    toDTO(): any {
        const dto: any = Object.assign({}, this);
        dto.deliveryDate = this.deliveryDate?.isValid() ? this.deliveryDate.format('YYYY-MM-DD') : null;
        return dto;
    }
}

export class UserTimeTrackingInfo extends AbstractDomainObject {
    userName!: string;
    stage!: WorkflowStage;
    totalSessionTimeInSeconds!: number;
    averageSessionTimeInSeconds!: number;
    day!: moment.Moment;
    rowCount!: number;
    private static rowIdCounter = 0;
    rowId!: number;

    constructor(dto?: any) {
        super(dto);
        this.rowId = UserTimeTrackingInfo.rowIdCounter++;
    }
}

export class UserGroupedAttachments extends AbstractDomainObject {
    groupedAttachments: Attachment[][];
    
    constructor(dto?: any) {
        super(dto);
        this.groupedAttachments = dto?.groupedAttachments ? dto.groupedAttachments : [];
    }
    
    toDTO(): any {
        const dto: any = Object.assign({}, this);
        dto.groupedAttachments = this.groupedAttachments.map(x => x.map(y => y.toDTO()));
        return dto;
    }
    
    addNewGroup(objToAdd: Attachment | Attachment[]) {
        const newGroup = this.getObjectToAdd(objToAdd);
        this.groupedAttachments.push(newGroup);
    }
    
    resetGroups() {
        this.groupedAttachments = [];
    }
    
    private getObjectToAdd(objToAdd: Attachment | Attachment[]) {
        return Array.isArray(objToAdd) ? objToAdd : [objToAdd];
    }
}

export class RelatedWorkflows extends AbstractDomainObject {
    originalWorkflow: Workflow;
    childWorkflows: Workflow[];
    
    constructor(dto?: any) {
        super(dto);
        this.originalWorkflow = new Workflow(dto?.originalWorkflow);
        this.childWorkflows = dto?.childWorkflows.map((dto: any) => new Workflow(dto));
    }
}

export type WorkflowStage =
    "SupplierEntry"
    | "MISupplierEntry"
    | "NewSupplier"
    | "PictureCleanup"
    | "ItemCodeAndPrice"
    | "PackSize"
    | "Approved"
    | "PictureIssue"
    | "Error"
    | "DevProblem"
    | "NotProcessable"
    | "VerifyCategoryOnly"
    | "GlCode"
    | "CategoryOnly"
    | "MultipleInvoiceError";

export const AllWorkflowStages: WorkflowStage[] = ["SupplierEntry", "MISupplierEntry", "NewSupplier", "PictureCleanup", "ItemCodeAndPrice", "PackSize", "Approved", "PictureIssue", "Error", "DevProblem", "NotProcessable", "GlCode", "CategoryOnly", "VerifyCategoryOnly", "MultipleInvoiceError"];

export const WorkflowStagesThatCouldShowSingleAttachments: WorkflowStage[] = ["MISupplierEntry", "NewSupplier"];

export const RasiTenantThatCanNotSendToVerifyCatOnly = ["tenants/172-C", "tenants/173-C", "tenants/174-C", "tenants/175-C"];