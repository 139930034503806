import {CSSProperties} from "react";

export class Colors{
    // #EF6F6C
    // #463730
    static readonly dangerDark = "#931004";
    static readonly danger = "#d32212";
    static readonly dangerText = "#fff";
    static readonly lightestGrey = "#f2f2f2";
    static readonly lightGrey = "#b4c4c8";
    static readonly darkGrey = "#636060";
    static readonly attn = "#00A5CF"; 
    static readonly grey = "#373E40";
    static readonly white = "#FFF";
    static readonly darkBlue = "#314074";
    static readonly lightBlue = "#6d77d6";
    static readonly yellowWarning = "#c7ca01";
    static readonly success = "#15ba15";
    static readonly darkGreen = "green";
    static readonly info = "#d9d7b6";
    static readonly black = "#000";
    
    static validStyle(isValid: boolean): CSSProperties{
        return {
            backgroundColor: isValid ? undefined : Colors.danger,
            color: isValid ? undefined : Colors.dangerText
        }
    }
} 