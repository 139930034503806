import React, {CSSProperties, Fragment, useEffect, useState} from 'react';
import {RestaurantSearch} from '../../Components/RestaurantSearch';
import {useNavigate, useParams} from 'react-router';
import {
    Restaurant,
    RestaurantSupplier,
    UpdateRestaurantSupplierRequest
} from '../../Domain/Restaurant';
import {useRestaurant, RestaurantService} from '../../Services/RestaurantService';
import {CopyableText} from '../../Components/CopyableText';
import {EditText} from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import {CancelButton, SaveButton} from "../../Components/Buttons";
import {SupplierHouseView} from "../../Components/SupplierHouseSelect";
import {Supplier, SupplierHouse} from "../../Domain/Supplier";
import {SupplierService} from "../../Services/SupplierService";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

// Global styles
const columnStyle: CSSProperties = {
    padding: '10px',
    textAlign: 'left',
};
const largerColumnStyle: CSSProperties = {
    padding: '10px',
    minWidth: '200px',
    textAlign: 'left',
}

export function ManageRestaurantSupplierPage() {
    const history = useNavigate();
    const {restaurantId} = useParams();
    const [restaurant, setRestaurant] = useRestaurant(restaurantId);

    useEffect(() => {
        if (restaurant && restaurant.id != restaurantId) {
            history(`/manageRestaurantSupplier/${encodeURIComponent(restaurant?.id)}`, {replace: true});
        }
    }, [restaurant])

    const restSearchStyle: CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '5vh',
        paddingBottom: '10px',
    };
    
    const restInfoStyle: CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    }

    return <div>
        <div style={restSearchStyle}>
            <div>Choose a restaurant:</div>
            <div><RestaurantSearch onChange={setRestaurant} restaurant={restaurantId}/></div>
        </div>
        {restaurant && <div style={restInfoStyle}>
            <RestaurantInfo restaurant={restaurant}/>
            <SupplierHouseGrid restaurant={restaurant}/>
        </div>}
    </div>
}

function RestaurantInfo({restaurant}: { restaurant: Restaurant }) {
    const restInfoStyle: CSSProperties = {
        display: 'grid',
        gridTemplateColumns: '200px 1fr',
        textAlign: 'left',
        borderStyle: 'solid',
        gap: '8px',
    };

    return <Fragment>
        <div style={restInfoStyle}>
            <div>Tenant Id:</div><CopyableText text={restaurant.tenantId}/>
            <div>Tenant Name:</div><div>{restaurant.tenantName}</div>
            <div>Restaurant Name:</div><div>{restaurant.name}</div>
            <div>Internal Id:</div><CopyableText text={restaurant.id}/>
            <div>Restaurant Supplier Count:</div>
            <div>{restaurant.restaurantSuppliers?.length ?? 0}</div>
        </div>
    </Fragment>
}

function SupplierHouseGrid({restaurant}: { restaurant: Restaurant }) {
    const tableHeaderStyle: CSSProperties = {
        backgroundColor: 'lightblue',
        position: 'sticky',
        top: 0,
        zIndex: 1,
    };
    const tableStyle: CSSProperties = {
        width: '100%',
        borderCollapse: 'collapse',
        borderSpacing: 3,
    };
    const tableContainerStyle: CSSProperties = {
        margin: '25px',
        height: '65vh',
        overflowY: 'auto',
    }

    return <Fragment>
        <div style={tableContainerStyle}>
            <table style={tableStyle}>
                <thead style={tableHeaderStyle}>
                <tr>
                    <th style={columnStyle}/>
                    <th style={largerColumnStyle}>Supplier Id</th>
                    <th style={columnStyle}>Supplier Name</th>
                    <th style={largerColumnStyle}>Account Number</th>
                    <th style={columnStyle}>House</th>
                    <th style={columnStyle}>House Platform Id</th>
                </tr>
                </thead>
                <tbody>
                {restaurant.restaurantSuppliers && restaurant.restaurantSuppliers.map(rs => {
                    return <HouseGridRow restaurantId={restaurant.id} restaurantSupplier={rs}
                                         key={rs.supplierId + (rs.supplierHouseZip ?? "")}/>;
                })}
                </tbody>
            </table>
        </div>
    </Fragment>
}

function HouseGridRow({restaurantId, restaurantSupplier}: {restaurantId: string, restaurantSupplier: RestaurantSupplier}) {
    const [selectedSupplierHouse, setSelectedSupplierHouse] = useState<SupplierHouse | undefined>(undefined);
    const [selectedHousePlatformId, setSelectedHousePlatformId] = useState(restaurantSupplier.supplierHousePlatformId);
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | undefined>(undefined);
    const [showModifyButtons, setShowModifyButtons] = useState(false);
    const [selectedAccountNumber, setSelectedAccountNumber] = useState<string | undefined>(restaurantSupplier.accountNumber);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (nullOrUndef(restaurantSupplier?.supplierId) === false) {
            SupplierService.getById(restaurantSupplier.supplierId!)
                .then(s => {
                    setSelectedSupplier(s);
                    handleSupplierHouseSelection(s);
                });
        }
        if (selectedAccountNumber !== restaurantSupplier.accountNumber) {
            setSelectedAccountNumber(restaurantSupplier.accountNumber);
        }
    }, [selectedSupplierHouse]);

    const handleSupplierHouseSelection = (sup: Supplier) => {
        const selectedSupplierHouse = 
            sup.supplierHouses?.find(x => x.housePlatformId === restaurantSupplier.supplierHousePlatformId
                && x.zipCode === restaurantSupplier.supplierHouseZip);
        if (nullOrUndef(selectedSupplierHouse) === false) {
            setSelectedSupplierHouse(selectedSupplierHouse);
        }
    };
    
    const changeAccountNumber = (newAccountNumber: string) => {
        if (newAccountNumber != restaurantSupplier.accountNumber) {
            setSelectedAccountNumber(newAccountNumber);
            setShowModifyButtons(true);
        }
    };
    
    const changeSupplierHouse = (newSupplierHouse: SupplierHouse) => {
        if (newSupplierHouse !== null 
            && newSupplierHouse.housePlatformId !== restaurantSupplier.supplierHousePlatformId
            && newSupplierHouse.zipCode !== restaurantSupplier.supplierHouseZip) {
            
            setSelectedSupplierHouse(newSupplierHouse);
            setSelectedHousePlatformId(newSupplierHouse.housePlatformId);
            setShowModifyButtons(true);
        }
    };
    
    const handleSave = async () => {
        if (!isSaving) {
            setIsSaving(true);
            if (restaurantSupplier.accountNumber !== selectedAccountNumber
                || (restaurantSupplier.supplierHousePlatformId !== selectedSupplierHouse?.housePlatformId
                    && restaurantSupplier.supplierHouseZip !== selectedSupplierHouse?.zipCode)) {
                
                const userChoice = await showConfirmAlert()
                    .then(choice => choice);
                if (userChoice === ConfirmationChoice.Cancel){
                    setIsSaving(false);
                    return;
                }
                
                const requestBody= new UpdateRestaurantSupplierRequest(
                    restaurantId, restaurantSupplier.supplierId!, selectedSupplierHouse?.id,
                    selectedAccountNumber, userChoice === ConfirmationChoice.Yes);

                await RestaurantService.upsertRestaurantSupplier(requestBody)
                    .then(async r => {
                        restaurantSupplier = r;
                        setSelectedHousePlatformId(r.supplierHousePlatformId);
                        setIsSaving(false);
                    });
            }
            setShowModifyButtons(false);
        }
    };
    
    const handleCancel = () => {
        setSelectedAccountNumber(restaurantSupplier.accountNumber);
        if (selectedSupplier !== undefined) {
            handleSupplierHouseSelection(selectedSupplier);
            setSelectedHousePlatformId(restaurantSupplier.supplierHousePlatformId);
        }
        setShowModifyButtons(false);
    };
    
    const buttonsColumnStyle: CSSProperties = {
        padding: '5px',
    };
    const buttonsContainerStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    };
    const buttonStyle: CSSProperties = {
        padding: '10px 20px',
        margin: '3px',
    };
    const houseColumnStyle: CSSProperties = {
        padding: '10px',
        minWidth: '250px',
        textAlign: 'left',
    };
    const rowStyle: CSSProperties = {
        borderTop: 'solid',
        borderStyle: 'inset',
        borderWidth: 'thin',
    };
    
    return <Fragment>
        <tr key={restaurantSupplier.supplierId + (restaurantSupplier.supplierHouseZip ?? "")} style={rowStyle}>
            <td style={buttonsColumnStyle}>
                {showModifyButtons && (
                    <div style={buttonsContainerStyle}>
                        <SaveButton style={buttonStyle} onClick={handleSave} disabled={isSaving}>{isSaving ? 'Saving...' : 'Save'}</SaveButton>
                        <CancelButton style={buttonStyle} onClick={handleCancel} disabled={isSaving}>Cancel</CancelButton>
                    </div>
                )}
            </td>
            <td style={largerColumnStyle}><CopyableText text={restaurantSupplier.supplierId}/></td>
            <td style={columnStyle}>{restaurantSupplier.supplierName}</td>
            <td style={largerColumnStyle}>
                <EditText showEditButton={true} defaultValue={selectedAccountNumber ?? ''}
                          onSave={e => changeAccountNumber(e.value)}/>
            </td>
            <td style={houseColumnStyle}>
                <SupplierHouseView supplier={selectedSupplier}
                                   supplierHouseId={selectedSupplierHouse?.id}
                                   supplierHouse={selectedSupplierHouse}
                                   onChange={(e) => changeSupplierHouse(e as SupplierHouse)}/>
            </td>
            <td style={columnStyle}>{selectedHousePlatformId}</td>
        </tr>
    </Fragment>
}

enum ConfirmationChoice {
    Yes = 'Yes',
    No = 'No',
    Cancel = 'Cancel',
}

function showConfirmAlert(): Promise<ConfirmationChoice> {
    const defaultOptions = {
        message: 'Would you also like to retroactively update related Invoices?',
        buttons: [
            {
                label: 'Yes',
                onClick: () => ConfirmationChoice.Yes,
            },
            {
                label: 'No',
                onClick: () => ConfirmationChoice.No,
            },
            {
                label: 'Cancel',
                onClick: () => ConfirmationChoice.Cancel,
            },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
    };
    
    return new Promise((resolve) => {
        confirmAlert({
            ...defaultOptions,
            buttons: defaultOptions.buttons!.map(button => ({
                ...button,
                onClick: () => {
                    resolve(button.onClick());
                },
            })),
        });
    });
}