import React, {CSSProperties, PropsWithChildren, ReactNode} from "react";
import {Colors} from "../Colors";

interface Props{
    header: string | ReactNode
    style?: CSSProperties
    headerColor?: string
    headerTextColor?: string
}
export function Card({children, header, style, headerColor, headerTextColor}: PropsWithChildren<Props>){
    return <div style={{border: "solid #bdb3b3 1px", display: "grid", gridTemplateRows: "auto 1fr", minHeight:0, borderRadius: 10}}>
        <div style={{backgroundColor: headerColor || Colors.info, color: headerTextColor, padding: "5px 10px", borderTopLeftRadius: 10, borderTopRightRadius: 10}}>{header}</div>
        <div style={{...style}}>
            {children}
            <div style={{marginTop: 10}}/>
        </div>
    </div>
} 