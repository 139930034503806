import {AbstractDomainObject} from "./AbstractDomainObject";
import {orderBy, rest} from "lodash";
import {RestaurantSupplier} from "./Restaurant";

export class Supplier extends AbstractDomainObject{
    name!: string;
    searchName!: string;
    externalId!: string;
    id!: string;
    supplierHouses?: SupplierHouse[];
    restaurantCount?: number;
    platformId?: string;
    parentPlatformId?: string;
    website?: string;
    phone?: string;
    city?: string;
    street?: string;
    street2?: string;
    country?: string;
    zip?: string;
    state?: string;
    region!: string;
    updateFromDTO(dto: any): this {
        super.updateFromDTO(dto);
        if(dto.supplierHouses){
            this.supplierHouses = this.toArray(dto.supplierHouses, SupplierHouse);
            this.supplierHouses = orderBy(this.supplierHouses, x => x.name);
        }
        return this;
    }
}

export class SupplierHouse extends AbstractDomainObject{
    city!: string;
    address!: string;
    zipCode!: string;
    name!: string;
    id!: string;
    state!: string;
    salesForceId!: string;
    housePlatformId?: string;
    
    public ToRestaurantSupplier(supplierId: string, supplierName: string)
    {
        let restSupplier = new RestaurantSupplier();
        restSupplier.supplierId = supplierId;
        restSupplier.supplierName = supplierName;
        restSupplier.supplierHousePlatformId = this.housePlatformId;
        restSupplier.supplierHouseName = this.name;
        restSupplier.supplierHouseZip = this.zipCode;
        return restSupplier;
    }
}

// TODO: Find usages of this and use RestaurantSupplier instead.
// When comparing to the data in Raven, this is not an accurate representation of the RestaurantSupplier Domain model
export class RestSupplier extends Supplier{
    housePlatformId?: string;
    accountNumber?: string;
    houseName?: string;
}