import {UserService} from "../Services/UserService";

import React, {useState, KeyboardEvent} from 'react';
import {useNavigate, useLocation, useParams} from "react-router";
import {Colors} from "../Colors";
import {SaveButton} from "../Components/Buttons";

export function ResetPassword() {
    const params = useQuery();
    const email = params.get("email");
    const request = params.get("request");
    const [userName, setUserName] = useState(email || "");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const history = useNavigate();
    const resetPassword = async () => {
        if(request){
            if(newPassword !== confirmNewPassword){
                alert("We're sorry, but your passwords did not match, please make sure your passwords match and try again");
                return;
            }
            await UserService.resetPassword(request, newPassword);
            history("/login")
            return;
        }
        if (!userName) {
            return;
        }
        try {
            const response = await UserService.requestPasswordReset(userName);
            history("/login")
        } catch (e) {
            console.error(e);
        }
    };

    const keyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key.toLocaleLowerCase() === "enter") {
            resetPassword();
        }
    }

    return (
        <div style={{display: "grid", justifyContent: "center", alignContent: "center", background: "#d6d7db"}}>
            <div style={{borderRadius: 15, overflow: "hidden"}}>
                <div style={{backgroundColor: Colors.lightBlue, padding: 10, textAlign: "center", width: 500, minWidth: 500}}>
                    Buyers Edge Invoice Service
                </div>
                <div style={{backgroundColor: Colors.darkBlue, padding: 20}}>
                    {!request && <div>
                        <input
                            type="text"
                            value={userName}
                            onChange={e => setUserName(e.target.value)}
                            placeholder="Username"
                            style={{width: '100%', padding: 5}}
                            onKeyDown={keyDownHandler}
                        />
                    </div>}
                    {request && 
                        <div>
                            <div><input
                                type="password"
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                placeholder="Password"
                                style={{width: '100%', padding: 5}}
                                onKeyDown={keyDownHandler}
                            />
                            </div>
                            <div><input
                                type="password"
                                value={confirmNewPassword}
                                onChange={e => setConfirmNewPassword(e.target.value)}
                                placeholder="Confirm Password"
                                style={{width: '100%', padding: 5}}
                                onKeyDown={keyDownHandler}
                            />
                            </div>
                        </div>
                    }
                    <div style={{display: "grid", marginTop: 10, justifyContent: "end"}}>
                        <SaveButton onClick={resetPassword} disabled={!userName} style={{width: 100}}>Reset password</SaveButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}