import {AbstractDomainObject} from "./AbstractDomainObject";
import {Uom} from "./UOMService";
import {Moment} from "moment";

export class PackSize extends AbstractDomainObject {
    supplierName!: string;
    supplierId!: string;
    supplierHouseName?: string;
    supplierHouseId?: string;
    itemCode!: string;
    description!: string;
    brandItemCode?: string;
    brand?: string;
    externalId!: string;
    pack!: number;
    size!: number;
    uom!: Uom;
    salesUom!: SalesUom;
    isCatchWeight!: boolean;
    id?: string;
    
    get totalUnits(){
        return this.pack * this.size;
    }
    
    get packSizeDesc(){
        return `${this.pack}/${this.size} ${this.uom}`
    }
    
    updateFromDTO(dto: any): this {
        super.updateFromDTO(dto);
        this.itemCode = dto.itemCode; //We need this because the item code can look like a date and we need to make sure it's a string, not a date
        return this;
    }
}

export class PackSizeInvoiceItem extends AbstractDomainObject {
    id!: string;
    invoiceId!: string;
    packSizeId!: string;
    externalInvoiceId!: string;
    deliveryDate!: Moment;
    pack!: number;
    size!: number;
    uom!: string;
    quantity!: number;
    total!: number;
    casePrice!: number;
    
    toDTO(): any {
        const dto = super.toDTO();
        dto.deliveryDate = this.deliveryDate.format('YYYY-MM-DD');
        return dto;
    }
}

export class ItemCodeWithDesc extends AbstractDomainObject{
    itemCode?: string;
    description?: string;
 }

export type SalesUom = "Case" | "Each" | "Weight" | "Unknown";