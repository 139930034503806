export class MathUtils {
    private static mathRegex = /\d+\.?\d*[\-\*\/\+]\d+\.?\d*/;
    
    public static isMathString(s: any){
        if(!s){
            return false;
        }
        let valueToMatch = s;
        if(typeof (s) !== "string"){
            valueToMatch = s.toString();
        }
        return !!valueToMatch.match(this.mathRegex);
    }
    
    public static getMathyValue(s: any) {
        if(this.isMathString(s) == false){
            return s;
        }
        if (isNaN(s)) {
            let result: any = null;
            let multiplyIndex = s.indexOf("*");
            let plusIndex = s.indexOf("+");
            let minusIndex = s.indexOf("-");
            let divideIndex = s.indexOf("/");
            if (multiplyIndex > -1) {
                let numbers = s.split("*");
                if (this.hasTwoNumbers(numbers)) {
                    result = Number(numbers[0]) * Number(numbers[1])
                }
            } else if (plusIndex > -1) {
                let numbers = s.split("+");
                if (this.hasTwoNumbers(numbers)) {
                    result = Number(numbers[0]) + Number(numbers[1])
                }
            }
            else if (minusIndex > -1) {
                let numbers = s.split("-");
                if (this.hasTwoNumbers(numbers)) {
                    result = Number(numbers[0]) - Number(numbers[1])
                }
            }
            else if (divideIndex > -1) {
                let numbers = s.split("/");
                if (this.hasTwoNumbers(numbers)) {
                    result = Number(numbers[0]) / Number(numbers[1])
                }
            }
            if (isNaN(result) == false && result !== null) {
                return result.toFixed(2);
            }
            if (s) {
                let replacedDoubleDecimal = s.replace(/\.\./, "\.");
                if (isFinite(replacedDoubleDecimal)) {
                    return (1 * replacedDoubleDecimal).toFixed(2);
                }
            }
        }
        return null;
    }

    public static hasTwoNumbers(strings: string[]): boolean {
        if (strings.length == 2) {
            let first: any = strings[0];
            let second: any = strings[1];
            if (first.length && second.length) {
                return isFinite(first) && isFinite(second);
            }
        }
        return false;
    }
    
    public static convertNumberToNegativeIfPositive(value: number) {
        if (value && !Number.isNaN(value) && value > 0) {
            value *= -1;
        }
        return value;
    }
}