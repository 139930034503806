import * as React from 'react';
import {Attachment} from "../../../Domain/Workflow/Workflow";
import {StandardModal} from "../../../Components/Modal";
import {AttachmentPicture} from "../../InvoiceWorkflow/InvoiceWorkflowPage";
import {WorkflowService} from "../../../Services/WorkflowService";
import {useEffect} from "react";

type CurrentGroupsModalProps = {
    showCurrentGroupsModal: boolean;
    handleSetShowCurrentGroupsModal: (x: boolean) => void;
    currentGroups: Attachment[][];
}

export function CurrentGroupsModal({
                                       showCurrentGroupsModal,
                                       handleSetShowCurrentGroupsModal,
                                       currentGroups
                                   }: CurrentGroupsModalProps) {
    const [attachments, setAttachments] = React.useState<Attachment[][]>([]);
    
    useEffect(() => {
        setAttachments(currentGroups);
    }, [currentGroups]);
    
    return (
        <StandardModal shouldShow={showCurrentGroupsModal}
                       header={"Current Groups"}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", height: "70vh", width: "60vw", overflow: "auto"}}>
                {attachments.map((group, index) => {
                    return (
                        <div key={index}>
                            <div>{index}</div>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "58vw", overflow: "auto"}}>
                            {group.map(attachment => (
                                WorkflowService.isPdf(attachment)
                                    ? <embed key={attachment.fileKey} style={{width: "600px", height: "600px"}} src={WorkflowService.getImgSource(attachment)} type="application/pdf"/>
                                    : <AttachmentPicture key={attachment.fileKey} attachment={attachment} rotationChanged={() => null} rotation={0} zoomPercentage={75}/>
                                    
                            ))}
                            </div>
                        </div>
                    );
                })}
            </div>
            <button onClick={() => handleSetShowCurrentGroupsModal(false)}>Close</button>
        </StandardModal>
    );
}