import {SupplierService} from "../Services/SupplierService";
import AsyncSelect from "react-select/async";
import React, {useEffect, useState} from "react";
import {Supplier} from "../Domain/Supplier";
import {uniqBy} from "lodash";

interface Props {
    supplier?: { id: string, name: string } | null | string
    onChange: (x?: Supplier) => void
    eRef?: any
    restId?: string
    includeHouses?: boolean;
    region: string;
}

export function SupplierSearch(props: Props) {
    const [supplier, setSupplier] = useState<any>();
    const selectSupplier = (x: any) => {
        setSupplier(x)
        props.onChange(x?.value)
    }
    useEffect(() => {
        if (nullOrUndef(props.supplier)) {
            setSupplier(null);
        } else {
            if (typeof props.supplier === 'string') {
                SupplierService.getById(props.supplier).then(x => {
                    setSupplier({
                        value: x,
                        label: x.name
                    });
                    props.onChange(x)
                })
            } else {
                setSupplier({
                    value: props.supplier,
                    label: props.supplier!.name
                })
            }
        }
    }, [props.supplier])
    return <AsyncSelect value={supplier} ref={props.eRef} onChange={selectSupplier} isClearable={true} isSearchable={true} loadOptions={async (x: string) => {
        let suppliersToShow = await SupplierService.searchSuppliers(x, props.region, props.restId, props.includeHouses);
        
        if (!props.includeHouses) {
            suppliersToShow = uniqBy(suppliersToShow, x => x.id);
        }
        
        return suppliersToShow.map(s => {
            let displayName = s.name;
            if (s.restaurantCount !== undefined && s.restaurantCount !== null) {
                displayName += " - " + s.restaurantCount;
            }
            return {value: s, label: displayName}
        })
    }}/>
}