import React, {useEffect, useState} from "react";
import {UserService} from "../Services/UserService";
import {Tenant} from "../Domain/User";
import {orderBy} from "lodash";

export function TenantSelect({tenant, setTenant}: {tenant: Tenant | undefined, setTenant: (t: Tenant | undefined) => void}) {
    const [selectedTenant, setSelectedTenant] = useState<Tenant>();
    const [allTenants, setAllTenants] = useState<Tenant[]>([]);

    useEffect(() => {
        if (UserService.user.anyRole("SuperAdmin")) {
            UserService.getAllTenants().then(tenants => {
                setAllTenants(orderBy(tenants, x => x.name.toLowerCase()));
            })
        }
    }, []);

    const _setTenant = (t: Tenant | undefined) => {
        setSelectedTenant(t);
        setTenant(t);
    }

    return <select name="tenantSelect" value={selectedTenant?.id} onChange={event => _setTenant(allTenants.find(x => x.id == event.target.value))}>
        {
            allTenants.map((e, key) => {
                return <option key={key} value={e.id}>{e.name} ({e.id})</option>
            })
        }
    </select>
}