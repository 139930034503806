import {ItemCodeSelect} from "../../Components/ItemCodeSelect";
import {Colors} from "../../Colors";
import React, {
    CSSProperties,
    Dispatch,
    KeyboardEventHandler,
    RefObject, SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import {RowInfo, WorkflowNote, WorkflowRow, WorkflowSession} from "../../Domain/Workflow/Workflow";
import {Uom, UOMService} from "../../Domain/UOMService";
import {WorkflowService} from "../../Services/WorkflowService";
import {MathUtils} from "../../Services/MathUtils";
import {UomSelect} from "../../Components/UomSelect";
import {DisplayServices} from "../../Services/DisplayServices";
import {Icon} from "../../Components/Icon";
import TimeAgo from 'react-timeago';
import {SaveButton} from "../../Components/Buttons";
import {StandardModal} from "../../Components/Modal";
import {WorkflowContext} from "../../Contexts/WorkflowContext";

export const itemCodeAndPriceGridStyle = {
    display: "grid",
    gridTemplateAreas: "'itemCode quantity total del'",
    gridTemplateColumns: '3fr 1fr 1fr',
    columnGap: 3
};

interface ItemCodeProps {
    row: WorkflowRow;
    changed: () => void;
    autofocus: boolean;
    field: "quantity" | "total" | "description" | "itemCode" | undefined;
    onFocus?: (row: WorkflowRow) => void;
}

const lastRowInfoPromise: any = {};

export function ItemCodeQuantity({row, changed, autofocus, field, onFocus}: ItemCodeProps) {
    const doNothing = () => {
    };

    const {workflow, getRowInfo} = useContext(WorkflowContext);

    const onFocusToUse = onFocus || doNothing;

    const setQuantity = (n?: number) => {
        row.quantity = n;
        changed();
    };
    const setTotal = (n?: number) => {
        row.total = n;
        changed();
    };
    const [desc, _setDesc] = useState<string>(row.desc || "");
    const setDesc = (s: string) => {
        _setDesc(s);
        row.desc = s;
        changed();
    };
    const [itemCode, _setItemCode] = useState<string | undefined>(row.itemCode);
    const setItemCode = (n?: string) => {
        row.itemCode = n;
        _setItemCode(n);
        changed();
    };

    const handleKeyDown: any = (e: KeyboardEvent, value: number, updateFn: (x: any) => void) => {
        const isNumberKey = isFinite(e.key as any);
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            e.preventDefault();
        } else if (isNumberKey && e.ctrlKey) {
            const valToUse = isFinite(value) ? value : "";
            updateFn(valToUse + "" + e.key);
            e.preventDefault();
        }
    };

    const [rowInfo, setRowInfo] = useState<RowInfo | null>(null);
    const handleBlur = async () => {

        if (!row.itemCode || !workflow!.supplierId) {
            setRowInfo(null);
        }
        if (row.itemCode && workflow!.supplierId) {
            const doAction = async () => {
                const promise = getRowInfo(workflow!.supplierId!, row.itemCode!, row.quantity, row.total);
                lastRowInfoPromise[row.rowId] = promise;
                const info = await promise;
                const promiseIsMostRecentPromise = promise === lastRowInfoPromise[row.rowId];
                if (promiseIsMostRecentPromise || !lastRowInfoPromise[row.rowId]) {
                    row.rowInfo = info;
                    setRowInfo(info);
                    setDesc(desc || info.description || '');
                    delete lastRowInfoPromise[row.rowId];
                    changed();
                }
            }
            let tries = 0;
            while (tries < 2) {
                try {
                    tries++;
                    await doAction();
                    break;
                } catch (e) {
                    setRowInfo(null);
                    if (tries > 1) {
                        console.error(e);
                    }
                }
            }
        }
    }

    const quantityInput = useRef<HTMLInputElement>(null);
    const totalInput = useRef<HTMLInputElement>(null);
    const itemCodeInput = useRef<HTMLInputElement>(null);
    const descInput = useRef<HTMLInputElement>(null);


    useEffect(() => {
        const action = async () => {
            if (workflow!.supplierId && row.itemCode && row.total && row.quantity) {
                const info = await getRowInfo(workflow!.supplierId!, row.itemCode, row.quantity, row.total);
                setRowInfo(info);
            }
        };
        action();
    }, []);

    useEffect(() => {
        if (autofocus) {
            if (quantityInput?.current && field === "quantity") {
                quantityInput.current.focus();
            } else if (totalInput?.current && field === "total") {
                totalInput.current.focus();
            } else if (descInput?.current && field == "description") {
                descInput.current.focus();
            } else if (itemCodeInput?.current && field === undefined) {
                itemCodeInput.current.focus();
            }
        }
    }, [autofocus, field]);


    return <div>
        <div style={{...itemCodeAndPriceGridStyle, marginTop: 5}} id={'row' + row.rowNumber}
             data-row-number={row.rowNumber}>
            <ItemCodeSelect supplierId={workflow!.supplierId!} onChange={setItemCode} itemCode={itemCode}
                            eRef={itemCodeInput} fieldType={"itemCode"} onBlur={handleBlur}
                            onFocus={() => onFocusToUse(row)}/>
            <InvoiceEntryNumberInput style={{gridArea: 'quantity'}} onChange={setQuantity} value={row.quantity}
                                     placeholder="Quantity" eRef={quantityInput} onFocus={() => onFocusToUse(row)}
                                     onKeyDown={x => handleKeyDown(x, row.quantity, setQuantity)} onBlur={handleBlur}
                                     fieldType="quantity" required={true}/>
            <InvoiceEntryNumberInput style={{gridArea: 'total'}} onChange={setTotal} value={row.total}
                                     placeholder="Total" eRef={totalInput} onFocus={() => onFocusToUse(row)}
                                     onKeyDown={x => handleKeyDown(x, row.total, setTotal)} onBlur={handleBlur}
                                     fieldType="total" required={true}/>
        </div>
        {rowInfo && !rowInfo.description && <div style={{marginTop: 5}}>
            <input type="text" style={{...Colors.validStyle(!!desc), width: '100%', padding: 5}}
                   onBlur={handleBlur} data-field-type={"description"}
                   placeholder="Description" value={desc}
                   ref={descInput} onChange={x => setDesc(x.target.value)}/>
        </div>}
        {rowInfo && rowInfo.issues.length > 0 && <div style={{marginTop: 5}}>
            {rowInfo.issues.map(i => <div style={{backgroundColor: Colors.danger, color: Colors.dangerText, padding: 5}}
                                          key={i}>{i}</div>)}
        </div>}
    </div>
}

interface PackSizeProps {
    row: WorkflowRow;
    changed?: () => void;
    shouldAutoFocus?: boolean
}

export const packSizeGridStyle = {display: "grid", gridTemplateColumns: "1fr 1fr 2fr auto", columnGap: 3};

export function PackSizeFields({row, changed, shouldAutoFocus}: PackSizeProps) {
    const packRef = useRef<any>();

    const [uom, _setUom] = useState<Uom | undefined>(row.uom);
    const setUom = (n?: Uom) => {
        row.uom = n;
        _setUom(n);
        if (changed) {
            changed();
        }
    };

    const [pack, _setPack] = useState(row.pack);
    const setPack = (n?: number) => {
        row.pack = n;
        _setPack(n);
        if (changed) {
            changed();
        }
    };
    const [size, _setSize] = useState(row.size);
    const setSize = (n?: number) => {
        row.size = n;
        _setSize(n);
        if (changed) {
            changed();
        }
    };

    const [isCatchWeight, _setIsCatchWeight] = useState(row.isCatchWeight || false);
    const setIsCatchWeight = (n: boolean) => {
        row.isCatchWeight = n;
        _setIsCatchWeight(n);
        if (changed) {
            changed();
        }
    };

    const [shouldEditQuantity, setShouldEditQuantity] = useState(false);
    const [newQuantity, setNewQuantity] = useState<number>();
    const updateQuantity = () => {
        row.quantity = newQuantity;
        setShouldEditQuantity(false);
    }

    const [shouldEditTotal, setShouldEditTotal] = useState(false);
    const [newTotal, setNewTotal] = useState<number>();
    const updateTotal = () => {
        row.total = newTotal;
        setShouldEditTotal(false);
    }

    useEffect(() => {
        _setIsCatchWeight(row.isCatchWeight || false);
        _setSize(row.size);
        _setPack(row.pack);
        _setUom(row.uom);
        if (packRef.current && shouldAutoFocus) {
            packRef.current.focus()
        }
    }, [row, packRef, shouldAutoFocus])

    let rowStyle: CSSProperties = {
        marginTop: 5
    };

    return <div style={rowStyle}>
        <div style={{display: 'grid', gridTemplateColumns: '5fr auto auto auto', columnGap: 10}}>
            <div style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '.75em'}}
                 title={row.desc}>({row.itemCode}) {row.desc}</div>
            <div>
                {!shouldEditQuantity &&
                    <div onClick={() => setShouldEditQuantity(true)} style={{cursor: "pointer"}}>Q:{row.quantity}</div>}
                {shouldEditQuantity &&
                    <div><InvoiceEntryNumberInput style={{width: "5em"}} onChange={setNewQuantity} value={row.quantity}
                                                  placeholder={row.quantity + ""}/>
                        <Icon name={"check"} onClick={updateQuantity}/>
                        <Icon name={"cancel"} onClick={() => setShouldEditQuantity(false)}/>
                    </div>}
            </div>
            <div>
                {!shouldEditTotal && <div style={{textAlign: 'right', cursor: "pointer"}}
                                          onClick={() => setShouldEditTotal(true)}>T:{DisplayServices.money(row.total)}</div>}
                {shouldEditTotal &&
                    <div><InvoiceEntryNumberInput style={{width: "5em"}} onChange={setNewTotal} value={row.total}
                                                  placeholder={DisplayServices.money(row.total) + ""}/>
                        <Icon name={"check"} onClick={updateTotal}/>
                        <Icon name={"cancel"} onClick={() => setShouldEditTotal(false)}/>
                    </div>}
            </div>
            <div
                style={{textAlign: 'right'}}>{row.pack && row.size && `${DisplayServices.money(row.unitPrice)}/${UOMService.getShortName(row.uom)}`}</div>
        </div>
        <div style={{...packSizeGridStyle, marginTop: 5}}>
            <InvoiceEntryNumberInput placeholder="Pack" value={pack} onChange={setPack} required={true} eRef={packRef}/>
            <InvoiceEntryNumberInput placeholder="Size" value={size} onChange={setSize} required={true}/>
            <UomSelect uom={uom} onChange={setUom} required={true}/>
            <input type='checkbox' checked={isCatchWeight} onChange={x => setIsCatchWeight(x.target.checked)}/>
        </div>
    </div>
}

interface InvoiceEntryNumberInput {
    onChange: (n?: number) => void | Dispatch<SetStateAction<number>>;
    value?: number | string | null;
    required?: boolean;
    placeholder?: string
    style?: CSSProperties;
    eRef?: RefObject<HTMLInputElement>;
    onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: () => void
    onKeyDown?: KeyboardEventHandler;
    fieldType?: string;
    disabled?: boolean;
}

export function InvoiceEntryNumberInput({
                                            onChange, value, required, placeholder, style,
                                            eRef, onBlur, onFocus, onKeyDown, fieldType, disabled
                                        }: InvoiceEntryNumberInput) {
    const [valueToUse, setValueToUse] = useState(nullOrUndef(value) ? '' : value);
    const [isDisabled, setIsDisabled] = useState(false);
    
    useEffect(() => {
        setValueToUse(nullOrUndef(value) ? '' : value)
    }, [value]);
    
    useEffect(() => {
        setIsDisabled(disabled || false)
    }, [disabled]);
    const onChangeToUse = (v: any) => {
        setValueToUse(v);
        if (onChange) {
            if (isFinite(v)) {
                onChange(v === "" ? undefined : +v)
            }
        }
    };
    const noValueSet = nullUndefOrEmpty(valueToUse);
    const styleToUse = {
        ...{
            width: '100%',
            backgroundColor: required && noValueSet ? Colors.danger : '',
            color: required && noValueSet ? Colors.dangerText : '',
            paddingLeft: 3,
            border: 'none'
        }, ...style
    };
    const onBlurToUse = () => {
        if (onBlur) {
            onBlur();
        }
        if (MathUtils.isMathString(valueToUse)) {
            onChangeToUse(MathUtils.getMathyValue(valueToUse))
        }
    };
    return <input disabled={isDisabled} style={styleToUse} type="text" placeholder={placeholder} value={valueToUse as any}
                  onChange={x => onChangeToUse(x.target.value)}
                  ref={eRef} onFocus={onFocus} onKeyDown={onKeyDown} data-field-type={fieldType} onBlur={onBlurToUse}/>
}

export function SidebarHeader() {
    const [showNotes, setShowNotes] = useState(true);
    const [showHistory, setShowHistory] = useState(false);
    const [newNote, setNewNote] = useState<WorkflowNote>();
    const {workflow, moveToErrorQueue} = useContext(WorkflowContext);
    const [showErrorReasonModal, setShowErrorReasonModal] = useState(false);
    if (!workflow) {
        return <div/>
    }

    const handleShowNotes = () => {
        setShowHistory(false);
        setShowNotes(!showNotes);
    }

    const handleShowHistory = () => {
        setShowNotes(false);
        setShowHistory(!showHistory);
    }
    const addNote = async () => {
        const note = await WorkflowService.addNewNote(workflow, newNote!);
        workflow.notes.push(note);
        setNewNote(undefined);
    };
    const sendToError = async (reason: string) => {
        var errorNote = new WorkflowNote();
        errorNote.note = reason;
        const note = await WorkflowService.addNewNote(workflow, errorNote);
        workflow.notes.push(note);
        moveToErrorQueue(workflow);
        setShowErrorReasonModal(false);
    };

    return <div style={{backgroundColor: Colors.white, borderBottom: "solid black 1px"}}>
        <span style={{cursor: workflow.notes.length ? "pointer" : "", color: showNotes ? Colors.success : "black"}}
              onClick={handleShowNotes}>Notes ({workflow.notes.length}) {!!workflow.notes.length &&
            <Icon name={showNotes ? "expand_less" : "expand_more"}/>}</span>
        <Icon style={{marginLeft: 10}} onClick={() => setNewNote(new WorkflowNote())} name="note_add"/>
        <Icon style={{marginLeft: 10}} onClick={() => setShowErrorReasonModal(true)} name="error"/>
        <span style={{marginLeft: '3vw', cursor: "pointer", color: showHistory ? Colors.success : "black"}}
              onClick={handleShowHistory}>History <Icon name={showHistory ? "expand_less" : "expand_more"}/> ({workflow.sessions.length})</span>
        {showNotes && <div>
            {workflow.notes.map(x => <Note key={x.id} note={x}/>)}
        </div>}
        {showHistory && <div>
            {workflow.sessions.map((session, i) => <SessionHistory key={i} session={session}/>)}
        </div>}
        {newNote && <div>
            <input type="text" value={newNote.note} onChange={x => newNote.note = x.target.value}/>
            <SaveButton onClick={addNote}>Save</SaveButton> <Icon onClick={() => setNewNote(undefined)} name="cancel"/>
        </div>}
        <ErrorReasonModal setShowErrorReasonModal={setShowErrorReasonModal} showErrorReasonModal={showErrorReasonModal}
                          onSet={sendToError}/>
    </div>
}

function SessionHistory(props: { session: WorkflowSession }) {
    const {session} = props;
    return (
        <>
            <div><span style={{fontWeight: "bold", marginRight: 4}}>Username:</span><span>{session.userName}</span>
            </div>
            <div><span
                style={{fontWeight: "bold", marginRight: 4}}>WorkflowStage:</span><span>{session.workflowStage}</span>
            </div>
            <div><span style={{
                fontWeight: "bold",
                marginRight: 4
            }}>Start:</span><span>{session.start.format('YYYY/MM/DD h:mm:ss a')}</span></div>
            <div><span style={{
                fontWeight: "bold",
                marginRight: 4
            }}>End:</span><span>{session.end?.format('YYYY/MM/DD h:mm:ss a') ?? "Current"}</span></div>
            <div style={{height: 1, width: '100%', background: "black"}}></div>
        </>
    );
}

function Note(props: { note: WorkflowNote }) {
    const {note} = props;
    return <div>{note.userName} ({note.source}) - <TimeAgo date={note.createdTime.toDate()}/> said: {note.note}</div>
}

export function BadPictureModal(props: { showBadPicModal: boolean, setShowBadPicModal: (v: boolean) => void, onSet: (s: string) => void, showErrorWarning?: boolean }) {
    const [reason, setReason] = useState("Invoice image is too blurry, please delete and resnap the full invoice.");
    const [customReason, setCustomReason] = useState("");
    useEffect(() => {
        setReason("Invoice image is too blurry, please delete and resnap the full invoice.");
        setCustomReason("");
    }, [props.showBadPicModal])
    return <StandardModal shouldShow={props.showBadPicModal} closeFn={props.setShowBadPicModal} header="Bad Picture?">
        {props.showErrorWarning && <div style={{color: Colors.dangerDark}}>Setting this status will remove the Error status!</div>}
        <select value={reason} onChange={x => setReason(x.target.value)}>
            <option value="Invoice image is too blurry, please delete and resnap the full invoice.">
                Picture too blurry
            </option>
            <option
                value="Item codes or prices are cut off, please delete and resnap the invoice with all information visible.">
                Missing Item Codes/Prices
            </option>
            <option
                value="Multiple invoices within the same picture grouping, please delete these and resnap the invoices within their own grouping.">
                Multiple Invoices
            </option>
            <option value="Missing Page(s), please delete and resnap all pages under the same group">
                Missing Page
            </option>
            <option>Statement, not invoice</option>
            <option>Not an invoice</option>
            <option
                value="Unable to determine Supplier/Missing Supplier, please resnap the invoice with the supplier clearly visible or tell us who the supplier is.">
                Unable to determine Supplier/Missing Supplier
            </option>
            <option
                value="Delivery date cut off or unclear, please resubmit the invoice with all information visible or notate the delivery date.">
                Delivery date cut off or unclear
            </option>
            <option>Other</option>
        </select>
        {reason === "Other" &&
            <div><textarea value={customReason} onChange={x => setCustomReason(x.target.value)}/></div>}
        <div>
            <button style={{position: 'absolute', marginTop: '107px'}}
                    onClick={() => props.onSet(customReason || reason)}>Set as bad picture
            </button>
        </div>
    </StandardModal>
}

export function ErrorReasonModal(props: { showErrorReasonModal: boolean, setShowErrorReasonModal: (v: boolean) => void, onSet: (s: string) => void, showBadPicWarning?: boolean }) {
    const [reason, setReason] = useState("");
    useEffect(() => {
        setReason("");
    }, [props.showErrorReasonModal])

    return <StandardModal shouldShow={props.showErrorReasonModal} closeFn={props.setShowErrorReasonModal}
                          header="Unable to be processed?">

        <div><label>State reason for this error</label></div>
        {props.showBadPicWarning && <div style={{color: "red"}}>This invoice cannot be a BadPic and Error.  This will remove the BadPic status.</div>}
        <div><textarea value={reason} onChange={x => setReason(x.target.value)}/></div>
        <div>
            <button onClick={() => props.onSet(reason)}>Set as unable to be processed</button>
        </div>
    </StandardModal>
}

type RadioButtonGroupProps = {
    options: string[];
    selected: string | undefined;
    setSelected: (s: any) => void;
    shouldDisplayRow?: boolean;
}
export function RadioButtonGroup(props: RadioButtonGroupProps) {
    const {options, selected, setSelected, shouldDisplayRow} = props;
    
    const displayDirection = shouldDisplayRow ? "row" : "column";
    
    return (
        <div style={{display: "flex", flexDirection: displayDirection, flexWrap: "wrap"}}>
            {options.map((x, i) => <div key={i} style={{marginRight: 10}}>
                <input type="radio" checked={selected === x} onChange={() => setSelected(x)}
                         id={`radio-${x}-${i}`}/>
                <label htmlFor={`radio-${x}-${i}`}>{x}</label>
            </div>)}
        </div>
    )
}