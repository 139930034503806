export function toSelectOption<T extends any>(x: T, mapper?: (x: T) => string): {value: T, label: string}{
    let mapperToUse = (v: T) => {
        // @ts-ignore
        if(v.name){
            // @ts-ignore
            return v.name;
        }
        // @ts-ignore
        if(v.label){
            // @ts-ignore
            return v.label;
        }
        if(typeof v === "string"){
            return v;
        }
        return "Unknown - no mapper provided and was not string and could not find name or label field";
    }
    if(mapper){
       mapperToUse = mapper; 
    }
    return {
        value: x,
        label: mapperToUse(x)
    }
}