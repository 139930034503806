import React, {CSSProperties, PropsWithChildren} from "react";
import {Colors} from "../Colors";
import {Icon} from "./Icon";

interface Props{
    color?: string,
    textColor?: string,
    onClick?: () => void
    disabled?: boolean
    style?: CSSProperties
    borderColor?: string
}

export function DangerButton(props: PropsWithChildren<Props>){
    return <BeButton color={Colors.danger} borderColor={Colors.dangerDark} textColor={Colors.dangerText} {...props}/>
}

export function CancelButton(props: PropsWithChildren<Props>){
    return <BeButton color={Colors.lightGrey} borderColor={Colors.darkGrey} {...props}/>
}

export function SaveButton(props: PropsWithChildren<Props>){
    return <BeButton color={Colors.success} borderColor={Colors.darkGreen} {...props}/>
}

export function BeButton({color, borderColor, style, textColor, onClick, disabled, children}: PropsWithChildren<Props>){
    const cursor = disabled || !onClick ? "default" : "pointer";
    const colorToUse = disabled ? Colors.white : (textColor || Colors.black);
    const bgColorToUse = disabled ? Colors.grey : (color || Colors.lightBlue);
    const styleToUse = {
        cursor: cursor,
        border: "solid 1px " + (borderColor || Colors.darkBlue),
        background: bgColorToUse, 
        color: colorToUse, 
        padding: "5px 10px", 
        borderRadius: 5, 
        ...style 
    };
    return <button style={styleToUse} onClick={onClick} disabled={disabled}>
        {children}
    </button>
}


interface ToggleButtonProps extends Props{
    toggleValue: boolean;
    setToggleValue: (b: boolean) => void;
} 

export function ExpandButton({toggleValue, setToggleValue, ...otherProps}: PropsWithChildren<ToggleButtonProps>){
    return <button {...otherProps} onClick={() => setToggleValue(!toggleValue)}>
        {otherProps.children}
        <Icon name={["expand_less", "expand_more"]} toggleCheck={toggleValue}/>
    </button>
}