export class GlobalExtensions{
    public static setupGlobalExtensions() {
        this.modifyArrayPrototypeWithExtensions();
        window.nullOrUndef = this.nullOrUndef;
        window.nullUndefOrEmpty = this.nullUndefOrEmpty;
    }
        
    public static modifyArrayPrototypeWithExtensions(){
        (<any>Array.prototype).removeItem = function(elementToRemove: any){
            if(typeof elementToRemove == "function"){
                let itemsToRemove = this.filter((x: any) => elementToRemove(x) === true);
                itemsToRemove.forEach((x: any) => this.removeItem(x));
                return itemsToRemove.length > 0;
            }else {
                let indexOfElement = this.indexOf(elementToRemove);
                if (indexOfElement > -1) {
                    this.splice(indexOfElement, 1);
                    return true;
                }
                return false;
            }
        };
    }

    public static nullOrUndef(...args: any[]){
        return args.some(x => x === undefined || x === null);
    }

    public static nullUndefOrEmpty(...args: any[]){
        return args.some(x => x === undefined || x === null || x === "");
    }
}

declare global {
    interface Array<T> {
        removeItem(o: T): boolean;
        removeItem(fn: (t: T) => boolean): boolean;
    }
    
    function nullOrUndef(...args: any[]): boolean;
    function nullUndefOrEmpty(...args: any[]): boolean;
}