import {BaseService} from "./BaseService";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {
    Restaurant,
    RestaurantSupplier, UnverifiedRestaurantSupplierHouse,
    UpdateRestaurantSupplierRequest
} from "../Domain/Restaurant";

export class RestaurantService extends BaseService {
    public static async searchRestaurants(searchTerm: string): Promise<Restaurant[]> {
        let url = `api/internalrestaurant?searchString=${encodeURIComponent(searchTerm)}`;
        return this.getToArray(url, Restaurant);
    }

    public static async getNextUnverifiedRestSuppHouse(): Promise<UnverifiedRestaurantSupplierHouse> {
        let url = `api/internalrestaurant/nextunverifiedhouse`;
        return this.getToClass(url, UnverifiedRestaurantSupplierHouse);
    }
    
    public static async getUnverifiedHouseCount(): Promise<Response | undefined> {
        let url = `api/internalrestaurant/unverifiedhousecount`;
        return await this.get(url);
    }    

    public static async upsertRestaurantSupplier(updateRestSupplierRequest: UpdateRestaurantSupplierRequest): Promise<RestaurantSupplier> {
        let url = `api/internalrestaurant/upsertrestaurantsupplier`;
        return await this.postToClass(url, RestaurantSupplier, updateRestSupplierRequest);
    }

    public static async updateRestaurantSupplier(updateRestSupplierRequest: UpdateRestaurantSupplierRequest): Promise<RestaurantSupplier> {
        let url = `api/internalrestaurant/updaterestaurantsupplier`;
        return await this.postToClass(url, RestaurantSupplier, updateRestSupplierRequest);
    }
    
    static getById(restaurantId: string) {
        return this.getToClass(`api/internalrestaurant/${encodeURIComponent(restaurantId)}`, Restaurant);
    }
}

export function useRestaurant(restaurantOrId: Restaurant | string | undefined) {
    const [restaurant, setRestaurant] = useState<Restaurant | null>(null);

    useEffect(() => {
        if (restaurantOrId === undefined || restaurantOrId === restaurant || restaurantOrId === restaurant?.id) {
            return;
        }

        if (restaurantOrId instanceof Restaurant) {
            setRestaurant(restaurantOrId);
        } else {
            RestaurantService.getById(restaurantOrId).then(r => setRestaurant(r));
        }
    }, [restaurantOrId]);

    return [restaurant, setRestaurant] as [Restaurant | null, Dispatch<SetStateAction<Restaurant | string | null | undefined>>];
}