import React, {useState} from 'react';
import {useNavigate} from "react-router";
import {UserService} from "../Services/UserService";
import {Colors} from "../Colors";
import {BeButton, CancelButton} from "../Components/Buttons";
import {Card} from "../Components/Card";

export function Signup() {
    const [userName, setUserName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [tenant, setTenant] = useState("");
    const [userType, setUserType] = useState<string>();
    const history = useNavigate();
    
    const createUser = async() => {
        const user = await UserService.createNewUser(userName, firstName, lastName, password, email, userType!, tenant);
        history("/")
    };
    
    return <div style={{display: "grid", justifyContent: "center", alignContent: "center", background: "#d6d7db"}}>
        <Card header="Create New User" style={{background: Colors.white}} headerColor={Colors.darkBlue} headerTextColor={Colors.white}>
            <div style={{width: 500, minWidth: 500}}>
                {!userType && <div style={{textAlign: "center", padding: "20px 0"}}>
                    <div style={{marginBottom: 10}}>
                        What type of user do you want to create?
                    </div>
                    <BeButton onClick={() => setUserType("DataEntry")}>Data Entry</BeButton> <BeButton onClick={() => setUserType("Api")}>API Access</BeButton>
                </div>}
                {userType && <div style={{padding: 20}}>
                    <div style={{display: "grid", gridTemplateRows: "1fr 1fr 1fr 1fr 1fr", rowGap: 10}}>
                        <input
                            type="text"
                            placeholder="Username"
                            value={userName}
                            onChange={e => setUserName(e.target.value)}
                            style={{width: '100%', padding: 5}}
                        />
                        <input
                            type="text"
                            placeholder="First Name"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            style={{width: '100%', padding: 5}}
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            style={{width: '100%', padding: 5}}
                        />
                        <input
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            style={{width: '100%', padding: 5}}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            style={{width: '100%', padding: 5}}
                        />
                        {userType === "Api" && <input
                            type="text"
                            placeholder="Tenant Name"
                            value={tenant}
                            onChange={e => setTenant(e.target.value)}
                            style={{width: '100%', padding: 5}}
                        />}
                    </div>
                    <div style={{display: "grid", marginTop: 10, gridTemplateColumns: "1fr auto"}}>
                        <div>
                        <CancelButton onClick={() => setUserType(undefined)}>Change user type</CancelButton>
                        </div>
                        <BeButton onClick={() => createUser()} style={{width: 100}}>Create User</BeButton>
                    </div>
                </div>}
            </div>
        </Card>
    </div>
}