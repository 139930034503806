export class Address {
    street?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    website?: string;
    phone?: string;

    isValidAddress(): boolean {
        return !(this.street === null || this.street === undefined
            || this.city === null || this.city === undefined
            || this.state === null || this.state === undefined
            || this.zip === null || this.zip === undefined);
    }
}