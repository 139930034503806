import {useSupplier} from '../../Services/SupplierService';
import React, {useState} from 'react';
import {SupplierHouse} from '../../Domain/Supplier';
import {BeButton, SaveButton} from '../../Components/Buttons';
import {Css} from '../../Css';
import {UnverifiedRestaurantSupplierHouse, UpdateRestaurantSupplierHouseRequest} from '../../Domain/Restaurant';
import {RestaurantService} from '../../Services/RestaurantService';
import {SupplierHouseSelect} from "../../Components/SupplierHouseSelect";

export function RestaurantSupplierHouseSidebar(props: { 
        unverifiedHouse: UnverifiedRestaurantSupplierHouse, 
        getNextWorkflow: () => Promise<void>,
    }) {
    
    const [supplier, setSupplier] =
        useSupplier(props.unverifiedHouse?.restaurantSupplier.supplierId);
    const [supplierHouse, setSupplierHouse] =
        useState<SupplierHouse | undefined>(undefined);
    const [accountNumber, setAccountNumber] = useState<string | undefined>(undefined);
    const [allowDcnEdit, setAllowDcnEdit] =
        useState(nullOrUndef(props.unverifiedHouse?.restaurantSupplier.accountNumber));
    const [isSaving, setIsSaving] = useState(false);
    const [canSave, setCanSave] = useState(false);
    
    if (!props.unverifiedHouse) {
        return <div></div>
    }

    const dcnEntered = async (s: string) => {
        if (s && s.length > 0) {
            setAccountNumber(s);
        }
    };
    
    const houseSelected = (sh: SupplierHouse | undefined) => {
        setSupplierHouse(sh);
        setCanSave(true);
    }

    const updateUnverifiedRestSuppHouse = async (supplierHouseId?: string) => {
        if (isSaving) {
            return;
        }
        setIsSaving(true);
        try {
            let acctNumber = props.unverifiedHouse.restaurantSupplier.accountNumber;
            if (nullOrUndef(accountNumber) === false && accountNumber !== acctNumber){
                acctNumber = accountNumber;
            }
            
            const requestBody= new UpdateRestaurantSupplierHouseRequest(
                props.unverifiedHouse!.restaurantId,
                props.unverifiedHouse!.restaurantSupplier.supplierId!,
                props.unverifiedHouse!.restaurantSupplier.externalId!,
                supplierHouseId,
                acctNumber);
            
            await RestaurantService.updateRestaurantSupplier(requestBody)
                .then(async r => {
                    setIsSaving(false);
                    setCanSave(false);
                    await props.getNextWorkflow();
                    setSupplierHouse(undefined);
                    setAccountNumber(undefined);
                    setAllowDcnEdit(false);
                });
        } catch (e) {
            setIsSaving(false);
            throw e;
        }
    };

    return <div>
        <div>
            <div>
                {supplier && <SupplierHouseSelect
                    supplier={supplier}
                    supplierHouse={supplierHouse}
                    onChange={(sh) => houseSelected(sh)}
                />}
            </div>
            <div style={{marginTop: 20}}>
                {allowDcnEdit && <EnterDcn dcnEntered={dcnEntered}/>}
            </div>
            <div style={{marginTop: 20}}>
                <div>
                    <SaveButton disabled={!canSave || isSaving}
                                onClick={() => updateUnverifiedRestSuppHouse(supplierHouse?.id)}>Save
                    </SaveButton>
                </div>
            </div>
            <div style={{marginTop: 300}}>
                <div>
                    <SaveButton disabled={isSaving}
                                onClick={() => updateUnverifiedRestSuppHouse()}>Skip (Mark as Verified)
                    </SaveButton>
                </div>
            </div>
        </div>
    </div>
}

function EnterDcn({dcnEntered, customerAccountNumber}: { dcnEntered: (s: string) => void, customerAccountNumber?: string }) {
    return <div>
        <div>
            <div>DCN</div>
            <div style={Css.columns('1fr auto')}>
                <div>
                    <input type='text' value={customerAccountNumber}
                            onChange={x => dcnEntered(x.target.value)}
                            placeholder='DCN/Account Number'
                            style={{width: '100%'}}/>
                </div>
                <div>{!customerAccountNumber && <BeButton onClick={() => dcnEntered('NONE')}>None</BeButton>}</div>
            </div>
        </div>
    </div>
}
