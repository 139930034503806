import {BaseService} from "./BaseService";
import {Tenant, User} from "../Domain/User";
import {useEffect, useState} from "react";
import * as FullStory from "@fullstory/browser";

let _user: User;
const _forceCountUpdateListeners: any[] = [];
const _forceCountUpdate = () => {
    _forceCountUpdateListeners.forEach(x => x());
};

export function useAllUsers() {
    const [users, setUsers] = useState<User[]>()
    useEffect(() => {
        const action = async () => {
            setUsers(undefined);
            const users = await BaseService.getToArray(`/api/user/all`, User);
            setUsers(users);
        }
        _forceCountUpdateListeners.push(action);
        action();
        return () => {
            _forceCountUpdateListeners.removeItem(action)
        }
    }, []);
    return users;
}

export class UserService extends BaseService{
    static async createNewUser(userName: string,
                               firstName: string,
                               lastName: string,
                               password: string,
                               email: string, 
                               userType: string, 
                               tenant?: string){
        const user = await this.postToClass("/api/user", User, {
            userName,
            firstName,
            lastName,
            password,
            email,
            userType,
            tenantName: tenant
        });
        _user = user;
        return user;
    }
    
    static async login(username: string, password: string){
        _user = await this.postToClass("/api/user/login", User, {UserNameOrEmail: username, Password: password})
        this.updateFS();
    }

    static async fillInUser(){
        if(!_user) {
            const data = await fetch("/api/user");
            if(data.status == 200){
                _user = new User().updateFromDTO(await data.json());
                this.updateFS();
            }else{
                if(window.location.pathname.toLocaleLowerCase().indexOf("resetpassword") !== -1){
                    return;
                }else if(window.location.pathname.toLocaleLowerCase().indexOf("login") === -1) {
                    window.location.pathname = "/login";
                }
            }
        }
    }
    
    static updateFS(){
        let displayName = `${_user.firstName || ''} ${_user.lastName ||''}`
        if(displayName === ' '){
            displayName = _user.username;
        }
        FullStory.identify(UserService.user.id, {
            displayName: displayName,
            email: _user.email,
            roles_strs: _user.roles,
            tenants_strs: _user.tenants
        });
    }
    
    static get user(){
        if(_user == null){
            throw "User was not loaded"
        }
        return _user;
    }

    static async logout(){
        await this.get("/api/user/logout");
    }

    static async addUserToTenant(userName: string,
                               firstName: string,
                               lastName: string,
                               password: string,
                               email: string,
                             roles: string[]){
        const user = await this.postToClass("/api/user/addUser", User, {
            userName,
            firstName,
            lastName,
            password,
            email,
            userType: "Api",
            roles
        });
        _forceCountUpdate();
        return user;
    }

    static async changeUserActiveStatus(user: User, activeStatus: boolean) {
        await this.putToClass(`/api/user/${encodeURIComponent(user.id)}/active/${activeStatus}`, user);
        _forceCountUpdate();
        return user;
    }

    static async changeUserRoles(user: User) {
        await this.putToClass(`/api/user/${encodeURIComponent(user.id)}`, user, user.toDTO());
        _forceCountUpdate();
        return user;
    }

    static async requestPasswordReset(userName: string) {
        return this.post(`/api/user/requestResetPassword`, userName)
    }

    static async resetPassword(requestId: string, newPassword: string) {
        return this.post(`/api/user/resetPassword/${requestId}`, newPassword);
    }

    static async getAllTenants(): Promise<Tenant[]> {
       
        return await this.getToArray('/api/user/getAllTenants', Tenant);
    }
}