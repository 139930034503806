import React, {DragEvent, useEffect, useState} from "react";
import {WorkflowService} from "../../Services/WorkflowService";
import {AllWorkflowStages, UserTimeTrackingInfo, WorkflowStage} from "../../Domain/Workflow/Workflow";
import _, {groupBy, orderBy} from "lodash";
import moment from "moment";
import {Css} from "../../Css";
import {Colors} from "../../Colors";


const keyNameForSelectedStages = "selectedStages";
const keyNameForStageOrder = "stageOrder";
export function UserTimeTracking() {
    const [selectedStages, setSelectedStages] = useState<WorkflowStage[]>(JSON.parse(localStorage.getItem(keyNameForSelectedStages) as any) || AllWorkflowStages)
    const [stageOrder, setStageOrder] = useState<WorkflowStage[]>(JSON.parse(localStorage.getItem(keyNameForStageOrder) as any) || AllWorkflowStages)
    
    const toggleStage = (stage: WorkflowStage) => {
        let stagesToSet = [];
        if(selectedStages.includes(stage)){
            stagesToSet = selectedStages.filter(x => x !== stage);
        }else{
            stagesToSet = [...selectedStages, stage];
        }
        setSelectedStages(stagesToSet);
        localStorage.setItem(keyNameForSelectedStages, JSON.stringify(stagesToSet));
    };

    const handleDrop = (ev: DragEvent, stageDroppedOn: WorkflowStage) => {
        const newStageOrder = stageOrder.slice(0);
        const droppedStageName = ev.dataTransfer.getData("text") as WorkflowStage;
        const indexOfItemToRemove = newStageOrder.indexOf(droppedStageName);
        newStageOrder.splice(indexOfItemToRemove, 1)
        newStageOrder.splice(newStageOrder.indexOf(stageDroppedOn), 0, droppedStageName)
        setStageOrder(newStageOrder)
        localStorage.setItem(keyNameForStageOrder, JSON.stringify(newStageOrder));
        const selectedStagesInOrder = newStageOrder.map(x => selectedStages.includes(x) ? x : undefined).filter(x => x !== undefined) as any;
        setSelectedStages(selectedStagesInOrder);
        localStorage.setItem(keyNameForSelectedStages, JSON.stringify(selectedStagesInOrder));
    }
    const handleDragStart = (ev: DragEvent, stageName: WorkflowStage) =>  {
        ev.dataTransfer.clearData();
        ev.dataTransfer.setData("text/plain", stageName);
        ev.dataTransfer.dropEffect = 'move';
    }
    
    const dragOver = (ev: DragEvent) => {
        ev.preventDefault();
    }

    return <div style={{
        display: 'grid',
        gridTemplateAreas: "'mainArea'",
        gridTemplateColumns: 'auto',
        minHeight: 0,
        overflowY: 'auto'
    }}>
        <div>
            <div>
                {stageOrder.map(x => <span key={x} style={{marginLeft: 10, cursor: 'pointer'}} onClick={() => toggleStage(x)} onDrop={e => handleDrop(e, x)} onDragStart={e => handleDragStart(e, x)} draggable="true" onDragOver={dragOver}>
                    <input type="checkbox" title={x} readOnly={true} checked={selectedStages.includes(x)}/> <label style={{cursor: 'pointer'}}>{x}</label>
                </span>)}
            </div>
            <TimeTrackingSection header="Today" key='today' from={moment()} to={moment().add(1, 'days')} selectedStages={selectedStages}/>
            <TimeTrackingSection header="Yesterday" key='yesterday' from={moment().add(-1, 'days')} to={moment()} selectedStages={selectedStages}/>
            <TimeTrackingSection header="This Week" key='thisweek' from={moment().add(-7, 'days')} to={moment()} selectedStages={selectedStages}/>
        </div>
    </div>
}

function TimeTrackingSection(props: {
    header: string,
    from: moment.Moment,
    to: moment.Moment,
    selectedStages: WorkflowStage[],
}) {

    const [userGroupedData, setUserGroupedData] = useState<UserGroupedData[]>([]);
    const [timeTrackingInfo, setTimeTrackingInfo] = useState<UserTimeTrackingInfo[] | undefined>()
    const [isLoading, setIsLoading] = useState(true);
    const [orderByCol, setOrderByCol] = useState('username');
    const [sortDir, setSortDir] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        WorkflowService.getTimeTrackingInfo(props.from, props.to).then(x => {
            setTimeTrackingInfo(x);
            setIsLoading(false);
        })
    }, []);

    useEffect(() => {
        const uniqueVals: string[] = [];
        if (timeTrackingInfo) {
            timeTrackingInfo.map(data => {
                if (uniqueVals.indexOf(data.userName) === -1) {
                    uniqueVals.push(data.userName)
                }
            })
            const byUserName = groupBy(timeTrackingInfo, x => x.userName);
            const groupData: UserGroupedData[] = [];
            for (const username in byUserName) {
                const data = byUserName[username];
                const stagesData = _(data)
                    .groupBy('stage')
                    .map((row, id) => ({
                        stage: id,
                        rowCount: _.sumBy(row, 'rowCount'),
                        totalSessionTimeInSeconds: _.sumBy(row, 'totalSessionTimeInSeconds'),
                        avgTime: (_.sumBy(row, 'totalSessionTimeInSeconds') / Math.max(_.sumBy(row, 'rowCount'), 1))
                    }))
                    .value();
                groupData.push({
                    username: username,
                    stagesData: stagesData
                })
            }
            setUserGroupedData(groupData);
        }
    }, [timeTrackingInfo]);

    if (isLoading) {
        return <div style={Css.center()}>Loading...</div>
    }

    const sortedData = orderBy(userGroupedData, [x => {
        if(orderByCol == 'username'){
            return x.username;
        }
        return x.stagesData.filter(x => x.stage === orderByCol)[0]?.avgTime;
    }, x => x.username], sortDir)
    
    const toggleHeader = (headerName: string) => {
        if(headerName == orderByCol){
            setSortDir(sortDir == 'asc' ? 'desc' : 'asc');
        }else {
            setOrderByCol(headerName);
            setSortDir('asc');
        }
    }

    return <div style={{margin: '25px'}}>
        <table style={{margin: '25px', borderCollapse: 'collapse', borderSpacing: 3}}>
            <thead style={{backgroundColor: 'lightblue'}}>
            <tr>
                <td style={{width: '200px', cursor: 'pointer'}} onClick={() => toggleHeader('username')}>{props.header}</td>
                {props.selectedStages.map(x => <td key={'header' + x} style={{width: '110px', cursor: 'pointer', textAlign: 'center', padding: "0 5px"}} onClick={() => toggleHeader(x)}>{x}</td>)}
            </tr>
            </thead>
            <tbody style={{borderCollapse: 'collapse'}}>
            {sortedData && sortedData.map(x => <TimeTrackingRow key={'TimeTrackingRow' + x.username} data={x} stages={props.selectedStages}/>)}
            </tbody>
        </table>
    </div>
}

function TimeTrackingRow({data, stages}: {
    data: UserGroupedData
    stages: WorkflowStage[]
}) {

    return <tr style={{border: '1px solid black'}}>
        <td style={{fontWeight: 'bold', border: 'solid black 1px'}}>{data.username}</td>
        {stages.map(stage => {
            const match = data.stagesData.filter(x => x.stage === stage)[0]
            if (!match) {
                return <td key={data.username + stage} style={{...Css.textCenter(), border: 'solid black 1px'}}>N/A</td>
            }
            return <td key={data.username + stage} style={{border: 'solid black 1px', padding: '5px 5px'}}>
                <div style={Css.columns('auto 1fr')}>
                    <span style={{fontSize: '.7em', color: Colors.darkGrey}}>Count</span> 
                    <div style={Css.textRight()}>{match.rowCount}</div>
                </div>
                <div style={Css.columns('auto 1fr')}>
                    <span style={{fontSize: '.7em', color: Colors.darkGrey}}>Avg/Row</span>
                    <div style={Css.textRight()}>{match.avgTime.toFixed(2)}</div>
                </div>
            </td>
        })}
    </tr>
}

interface UserGroupedData {
    username: string;
    stagesData: {
        stage: string,
        rowCount: number,
        totalSessionTimeInSeconds: number,
        avgTime: number,
    }[]
}