import React, {CSSProperties, MouseEventHandler} from "react";
import {Colors} from "../Colors";

interface Props {
    name: IconNames | IconNames[],
    toggleCheck?: boolean,
    style?: CSSProperties,
    onClick?: MouseEventHandler<HTMLElement>
    color?: string | string[]
    size?: number | string
}

export function Icon({name, toggleCheck, style, onClick, color, size}: Props) {
    let nameToUse = name;
    if (Array.isArray(name)) {
        if (toggleCheck) {
            nameToUse = name[0];
        } else {
            nameToUse = name[1];
        }
    }
    let colorToUse = color || Colors.black;
    if (Array.isArray(color)) {
        if (toggleCheck) {
            colorToUse = color[0];
        } else {
            colorToUse = color[1];
        }
    }
    const styleToUse: CSSProperties = {
        verticalAlign: "middle", 
        color: colorToUse as string, 
        cursor: onClick ? 'pointer' : '',
        fontSize: size,
        ...style
    };
    return <i style={styleToUse} className="material-icons" onClick={onClick}>{nameToUse}</i>
}


//Get more choices here https://material.io/resources/icons/?style=baseline, these are just the common ones we use. 
export type IconNames =
    "note_add"
    | "warning"
    | "error"
    | "notification_important"
    | "expand_more"
    | "expand_less"
    | "delete"
    | "play_arrow"
    | "stop"
    | "cancel"
    | "check"
    | "refresh"
    | "add"
    | "person_add"
    | "edit"
    | "call"
    | "photo_camera"
    | "search"
    | "save";