import Select from "react-select";
import React, {CSSProperties, Fragment, useEffect, useState} from "react";
import {Supplier, SupplierHouse} from "../Domain/Supplier";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

interface Props {
    supplier: Supplier | undefined;
    supplierHouseId?: string | undefined;
    supplierHouse?: SupplierHouse | undefined;
    onChange: (sh?: SupplierHouse) => void;
    editMode?: boolean;
}

type OptionType = {
    value: SupplierHouse;
    label: any;
};

const defaultOptionType: OptionType = {
    value: new SupplierHouse(),
    label: 'Select Supplier House',
};

const formatOptionLabel = (option: any) => (
    <SupplierHouseDetail house={option.value}/>
);

export function SupplierHouseView(props: Props) {
    const [editMode, setEditMode] = useState(false);
    
    const handleEditClick = () => {
        setEditMode(true);
    };

    const handleCancelEdit = () => {
        setEditMode(false);
    };

    const handleSave = (newSelectedSupplierHouse: SupplierHouse | undefined) => {
        if (newSelectedSupplierHouse !== undefined
                && newSelectedSupplierHouse.id !== props.supplierHouseId) {
            setEditMode(false);
            props.onChange(newSelectedSupplierHouse);
        }
    };
    
    const viewStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
    };

    return (
        <Fragment>
            {!editMode ?
                <div style={viewStyle}>
                    <div style={{width: '250px'}}>
                        <SupplierHouseDetail house={props.supplierHouse}/>
                    </div>
                    <div>
                        <IconButton onClick={handleEditClick}>
                            <EditIcon />
                        </IconButton>
                    </div>
                </div>
            : (
                <div style={viewStyle}>
                    <div style={{width: '250px'}}>
                        <SupplierHouseSelect
                            supplier={props.supplier}
                            supplierHouse={props.supplierHouse}
                            onChange={handleSave}
                        />
                    </div>
                    <div>
                        <IconButton onClick={handleCancelEdit}>
                            <CancelIcon />
                        </IconButton>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export function SupplierHouseSelect(props: Props) {
    const [selectedHouseOption, setSelectedHouseOption] = 
        useState<OptionType | undefined>(undefined);
    const [theOptions, setTheOptions] = 
        useState<OptionType[] | undefined>(undefined);
    
    useEffect(() => {
        if (nullOrUndef(props.supplierHouse)) {
            setSelectedHouseOption(defaultOptionType);
        }
        else if (props.supplierHouse instanceof SupplierHouse) {
            setSelectedHouseOption({value: props.supplierHouse, label: formatOptionLabel});
        }
    }, [props.supplierHouse])

    useEffect(() => {
        if (nullUndefOrEmpty(props.supplier?.supplierHouses) === false) {
            const houseOptions = props.supplier!.supplierHouses!.map(s => {
                return {value: s, label: formatOptionLabel};
            })
            setTheOptions(houseOptions);
        }
    }, [props.supplier]);

    const filterOption = (candidate: any, searchTerm: any) => {
        const loweredSearchTerm = searchTerm.toLocaleLowerCase();
        const sh = candidate.value as SupplierHouse;
        return (sh.name.toLocaleLowerCase().indexOf(loweredSearchTerm!) !== -1
            || sh.city.toLocaleLowerCase().indexOf(loweredSearchTerm!) !== -1
            || sh.zipCode.toLocaleLowerCase().indexOf(loweredSearchTerm!) !== -1
            || sh.state.toLocaleLowerCase().indexOf(loweredSearchTerm!) !== -1);
    };
    const updateSelectedValue = (option: any) => {
        if (nullOrUndef(option) === false) {
            setSelectedHouseOption(option);
            props.onChange(option!.value);
        }
    };
    const getSelectedValue = () => {
        if (nullOrUndef(selectedHouseOption)) {
            return defaultOptionType;
        }
        return selectedHouseOption!;
    };
    const getOptions = () => {
        return theOptions;
    };

    return <Fragment>
        <div>
            <Select value={getSelectedValue()} placeholder="Select Supplier House"
                    isSearchable={true}
                    onChange={updateSelectedValue}
                    formatOptionLabel={formatOptionLabel}
                    filterOption={filterOption}
                    options={getOptions()}/>
        </div>
    </Fragment>
}

function SupplierHouseDetail({house}: { house: SupplierHouse | undefined }) {
    const houseStyle: CSSProperties = {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        flexDirection: 'column',
    };

    if (house === null || house === undefined) {
        return <Fragment/>;
    }
    
    if (nullOrUndef(house.city) && nullOrUndef(house.name)) {
        return <Fragment>
            <div>Select a Supplier House</div>
        </Fragment>;
    }
    
    return <Fragment>
        <div style={houseStyle}>
            <div>{house.name}</div>
            <div>{house.address}</div>
            <div>{house.city}, {house.state} {house.zipCode}</div>
        </div>
    </Fragment>
}