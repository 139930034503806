import {CSSProperties} from "react";
import {Property} from "csstype";

export class Css{
    static columns(colDef: Property.GridTemplateColumns<string>, gap?: number | string) : CSSProperties {
        return {
            display: "grid",
            gridTemplateColumns: colDef,
            columnGap: gap
        }
    }

    static rows(rowDef?: Property.GridTemplateRows<string>, gap?: number | string) : CSSProperties {
        return {
            display: "grid",
            gridTemplateRows: rowDef,
            rowGap: gap
        }
    }

    static center() : CSSProperties {
        return {
            display:"grid",
            justifyContent: "center",
            alignContent: "center",
            height: "100%"
        }
    }

    static right(): CSSProperties {
        return {
            display: "grid",
            justifyContent: "end"
        }
    }

    static textCenter(): CSSProperties {
        return {
            textAlign: 'center'
        }
    }
    
    static textRight(): CSSProperties {
        return {
            textAlign: 'right'
        }
    }

    static clickable(): CSSProperties {
        return {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    }

    static fullHeight(): CSSProperties {
        return {
            height: '100%',
            minHeight: 0,
            display: 'grid',
        }
    }
}