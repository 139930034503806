import moment from "moment";

export interface ErrorInfo{
    requestUrl?: string;
    code: number;
    codeName: string;
    msg?: string;
}
export type ErrorListener = (msg: ErrorInfo) => void;
export class BaseService{
    private static errorListeners: ErrorListener[] = [];
    
    public static addErrorListener(listener: ErrorListener){
        this.errorListeners.push(listener);
    }
    
    public static async getToArray<U extends IUpdateFromDto<U>>(url: string, type: new (dto: any) => U): Promise<U[]> {
        const response = await this.fetch(url);
        const data = await response!.json();
        return this.toArray(data, type);
    }

    public static async getToClass<U extends IUpdateFromDto<U>>(url: string, type: new (dto: any) => U): Promise<U> {
        const response = await this.fetch(url);
        const data = await response!.json();
        return this.toClass(data, type);
    }

    public static async postToClass<U extends IUpdateFromDto<U>>(url: string, typeOrInstance: (new (dto: any) => U) | IUpdateFromDto<U>, body?: any): Promise<U> {
        const response = await this.post(url, body);
        const data = await response!.json();
        if(typeof typeOrInstance === "function"){
            return this.toClass(data, typeOrInstance)
        }
        return typeOrInstance.updateFromDTO(data);
    }

    public static async postToArray<U extends IUpdateFromDto<U>>(url: string, type: (new (dto: any) => U), body?: any): Promise<U[]> {
        const response = await this.post(url, body);
        const data = await response!.json();
        return this.toArray(data, type)
    }

    public static async putToClass<U extends IUpdateFromDto<U>>(url: string, typeOrInstance: (new (dto: any) => U) | IUpdateFromDto<U>, body?: any): Promise<U> {
        const response = await this.put(url, body);
        const data = await response!.json();
        if(typeof typeOrInstance === "function"){
            return this.toClass(data, typeOrInstance)
        }
        return typeOrInstance.updateFromDTO(data);
    }
    
    public static toArray<U extends IUpdateFromDto<U>>(dto: any, type: new (dto: any) => U): U[] {
        return <any>(this.toClass(dto, type));
    }

    public static toClass<U extends IUpdateFromDto<U>>(dto: any, type: new (dto?: any) => U): U {
        let jsonifiedData = dto;
        if (jsonifiedData instanceof Array) {
            return <any>(jsonifiedData.map(x => new type().updateFromDTO(x)));
        } else {
            return new type().updateFromDTO(jsonifiedData);
        }
    }

    public static async fetch(input: RequestInfo, init?: RequestInit) {
        const response = await fetch(input, init);
        if(response.status == 401){
            if(window.location.pathname.toLocaleLowerCase().indexOf('/login') > -1){
                return;
            }
            window.location.pathname = "/login";
            return response;
        }
        if(response.status >= 200 && response.status < 300){
            return response;
        }
        let responseMessage = "Could not parse response message";
        try {
            responseMessage = await response.text();
        }catch (e) {}
        let url = input;
        if(typeof input !== "string"){
            url = input.url;
        }
        const error = {
            requestUrl: url as string,
            code: response.status,
            codeName: response.statusText,
            msg: responseMessage
        };
        this.errorListeners.forEach(x => x(error));
        throw error;
    };
    
    public static async get(url: string) {
        return this.fetch(url);
    };

    public static async getRawJson(url: string): Promise<any> {
        const response = await this.get(url);
        return await response!.json();
    };

    public static async post(url: string, body?: any) {
        return this.putOrPost("POST", url, body);
    };

    public static async put(url: string, body?: any) {
        return this.putOrPost("PUT", url, body);
    };

    public static async putOrPost(type: "PUT" | "POST", url: string, body?: any) {
        let bodyContent = undefined;
        if(body){
            bodyContent = JSON.stringify(body);
        }
        return this.fetch(url, {
            method: type,
            body: bodyContent,
            headers: [["Content-Type", "application/json"]]
        });
    };

    public static async putFormData(url: string, formData?: FormData) {
        return this.postOrPutFormData("PUT", url, formData);
    }

    public static async postFormData(url: string, formData?: FormData) {
        return this.postOrPutFormData("POST", url, formData);
    }

    public static async postOrPutFormData(type: "PUT" | "POST", url: string, formData?: FormData) {
        return this.fetch(url, {
            method: type,
            body: formData,
        });
    }

    public static toQueryUrl(origUrl: string, queryParams: {[key: string]: string | number | null | undefined}){
        if(!queryParams){
            return origUrl;
        }
        let urlToReturn = origUrl;
        let joiningChar = origUrl.indexOf("?") > -1 ? "&" : "?";
        for(const key in queryParams){
            const value = queryParams[key];
            if(value !== null && value !== undefined){
                urlToReturn += joiningChar; 
                urlToReturn += encodeURIComponent(key) + "=";
                urlToReturn += encodeURIComponent(value);
                joiningChar = "&";
            }
        }
        return urlToReturn;
    }
    
    public static toIsoDate(d: Date | moment.Moment){
        return moment(d).format("YYYY-MM-DD");
    }
}

interface IUpdateFromDto<T>{
    updateFromDTO(dto: any): T
}