import React, {useState, KeyboardEvent} from 'react';
import {useNavigate} from "react-router";
import {UserService} from "../Services/UserService";
import {Colors} from "../Colors";
import {BeButton, SaveButton} from "../Components/Buttons";
import {Link} from "react-router-dom";

export function Login() {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const history = useNavigate();

    const doLogin = async () => {
        if(!userName || !password){
            return;
        }
        try {
            const response = await UserService.login(userName, password);
            history("/")
        } catch (e) {
            console.error(e);
        }
    };
    
    const keyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
        if(event.key.toLocaleLowerCase() === "enter"){
            doLogin();
        }
    }

    return (
        <div style={{display: "grid", justifyContent: "center", alignContent: "center", background: "#d6d7db"}}>
            <div style={{borderRadius: 15, overflow: "hidden"}}>
                <div style={{backgroundColor: Colors.lightBlue, padding: 10, textAlign: "center", width: 500, minWidth: 500}}>
                    Buyers Edge Invoice Service
                </div>
                <div style={{backgroundColor: Colors.darkBlue, padding: 20}}>
                    <div>
                        <input
                            type="text"
                            value={userName}
                            onChange={e => setUserName(e.target.value)}
                            placeholder="Username"
                            style={{width: '100%', padding: 5}}
                            onKeyDown={keyDownHandler}
                        />
                    </div>
                    <div style={{marginTop: 10}}>
                        <input
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder="Password"
                            style={{width: '100%', padding: 5}}
                            onKeyDown={keyDownHandler}
                        />
                    </div>
                    <div style={{display: "grid",marginTop: 10, gridTemplateColumns: "1fr 1fr"}}>
                        <BeButton onClick={() => history("/signUp")} style={{width: 100}}>Create User</BeButton>
                        <div style={{display: "grid", justifyContent: "end"}}>
                            <SaveButton onClick={doLogin} disabled={!userName && !password} style={{width: 100}}>Login</SaveButton>
                        </div>
                    </div>
                    <div style={{marginTop: 5, color: Colors.lightGrey}}>
                        <Link style={{color: Colors.lightGrey}} to={`/resetPassword${userName ? '?email=' + userName : ''}`}>Reset password</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}