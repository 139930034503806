import {Attachment, UserTimeTrackingInfo, Workflow, WorkflowNote} from "../Domain/Workflow/Workflow";
import {BaseService} from "./BaseService";
import {RestSupplier, Supplier} from "../Domain/Supplier";
import {Moment} from "moment";

export class WorkflowService extends BaseService {
    static getImgSource(attachment: Attachment, cacheIndex: number | null = null) {
        if (cacheIndex) {
            return `/api/internalAttachmentGrouping/${attachment.externalId}?cacheIndex=${cacheIndex}`
        } else {
            return `/api/internalAttachmentGrouping/${attachment.externalId}`
        }

    }

    static getWorkflowById(workflowId: string) {
        return this.getToClass(`/api/invoiceWorkflow/${encodeURIComponent(workflowId)}`, Workflow)
    }

    static getRandom() {
        return this.getToClass(`/api/invoiceWorkflow/random/next`, Workflow)
    }

    static getImgSourceFromExternalId(externalId: string) {
        return `/api/internalAttachmentGrouping/${externalId}`
    }

    static async getImgFileNameFromExternalId(externalId: string): Promise<string> {
        const response = await this.get(`/api/internalAttachmentGrouping/fileName/${externalId}`);
        return await response!.json();

    }

    static async createNewSupplier(supplier: Supplier) {
        return this.postToClass(`/api/internalSupplier`, Supplier, supplier.toDTO());
    }

    static async updateImgRotation(externalId: string, rotation: number) {
        return this.post(`/api/invoiceWorkflow/img/adjust/${encodeURIComponent(externalId)}?degrees=${rotation}`)
    }

    static async addNewNote(workflow: Workflow, workflowNote: WorkflowNote) {
        return this.postToClass(`/api/invoiceWorkflow/${encodeURIComponent(workflow.id)}/note`, workflowNote, workflowNote.toDTO());
    }

    static async getRestaurantSuppliers(restId: string): Promise<Supplier[] | undefined> {
        return await this.getToArray(`api/invoiceWorkflow/restSuppliers/${encodeURIComponent(restId)}`, RestSupplier);
    }

    static async getTimeTrackingInfo(fromDate: Moment, toDate: Moment): Promise<UserTimeTrackingInfo[]> {
        return this.getToArray(`api/invoiceWorkflow/timeTrackingInfo?fromDate=${fromDate.format('YYYY-MM-DD')}&toDate=${toDate.format('YYYY-MM-DD')}`, UserTimeTrackingInfo);
    }

    static async getPercentiles(): Promise<any> {
        return this.get(`api/attachmentGrouping/percentiles`).then(x => x?.json());
    }

    static isPdf(attachment: Attachment | string) {
        let stringToUse = (attachment as any).fileKey || attachment
        return stringToUse.toLowerCase().endsWith("pdf");
    }
}