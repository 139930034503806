import React, {useState} from "react";
import {useAllUsers, UserService} from "../../Services/UserService";
import {User} from "../../Domain/User";
import {Icon} from "../../Components/Icon";
import {Loading} from "../../Components/Loading";
import {Colors} from "../../Colors";
import {Card} from "../../Components/Card";
import Select from "react-select/";
import {BeButton, CancelButton, SaveButton} from "../../Components/Buttons";

export function UserManagement() {
    const users = useAllUsers();
    const [addingUser, setAddingUser] = useState(false);
    if (!users) {
        return <div style={{width: '50vw', minWidth: 300, maxWidth: 800, margin: "50px auto 0px auto"}}>
            <Loading objToLoad={users}/>
        </div>
    }
    return <div style={{width: '50vw', minWidth: 300, maxWidth: 800, margin: "50px auto 0px auto"}}>
        <div style={{display: "grid", justifyContent: "end", gridTemplateColumns: "1fr auto", marginBottom: 10}}>
            <div>You have {users.length} users</div>
            <BeButton onClick={() => setAddingUser(!addingUser)}><Icon name="person_add"/> Add User</BeButton>
        </div>
        {addingUser && <AddUser setAddingUser={setAddingUser}/>}
        <div style={{display: "grid", rowGap: 10}}>
            {users.map(x => <UserRow key={x.username} user={x}/>)}
        </div>
    </div>
}

function AddUser(props: {setAddingUser: (v: boolean) => void}){
    const [userName, setUserName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [roles, setRoles] = useState<string[]>(["ApiAccess"]);
    const addUser = async () => {
        await UserService.addUserToTenant(userName, firstName, lastName, password, email, roles);
        props.setAddingUser(false);
        setUserName("");
        setFirstName("");
        setLastName("");
        setPassword("");
        setEmail("");
        setRoles(["ApiAccess"])
    }
    return <Card header="Add New User" style={{marginTop: 10}}>
        <div style={{display: "grid", gridTemplateRows: "1fr 1fr 1fr 1fr 1fr 1fr", rowGap: 10, margin: 10}}>
            <input
                type="text"
                placeholder="Username"
                value={userName}
                onChange={e => setUserName(e.target.value)}
                style={{width: '100%', padding: 5}}
            />
            <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                style={{width: '100%', padding: 5}}
            />
            <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                style={{width: '100%', padding: 5}}
            />
            <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                style={{width: '100%', padding: 5}}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                style={{width: '100%', padding: 5}}
            />
            <RoleSelect onChange={setRoles} value={roles}/>
            <div style={{display: "grid", gridTemplateColumns: "1fr auto"}}>
                <div><CancelButton onClick={() => props.setAddingUser(false)}>Cancel</CancelButton></div>
                <SaveButton onClick={addUser}>Add user to your tenant</SaveButton>
            </div>
        </div>
    </Card>
}

function UserRow(props: { user: User }) {
    const {user} = props;
    const [editRoles, setEditRoles] = useState(false)
    const toggleActiveStatus = async () => {
        if (UserService.user.id === user.id) {
            alert("Sorry you can not change your own active status to prevent people from locking themselves out of the system. If you need to change you own active status please let support know")
            return;
        }
        if (window.confirm(`Are you sure you want to set ${user.username} to ${user.isActive ? 'Inactive' : 'Active'}`)) {
            await UserService.changeUserActiveStatus(user, !user.isActive);
        }
    }
    const updateRoles = async () => {
        await UserService.changeUserRoles(user)
    }
    const header = <div style={{display: "grid", gridTemplateColumns: "1fr 25px", alignItems: "center"}}>
        <div>{user.username} - {user.email}</div>
        <Icon toggleCheck={user.isActive} name={["check", "cancel"]} onClick={toggleActiveStatus} color={[Colors.success, Colors.danger]}/>
    </div>
    return <Card header={header}>
        <div style={{margin: 10}}>Roles: {user.roles.map(x => x).join(", ")} <Icon size="1em" name="edit" onClick={() => setEditRoles(!editRoles)}/></div>
        {editRoles && <div style={{display:"grid", gridTemplateColumns:"1fr auto", columnGap:10}}>
            <RoleSelect onChange={x => user.roles = x} value={user.roles}/>
            <SaveButton onClick={updateRoles}>Save</SaveButton>
        </div>}
    </Card>
}

function RoleSelect(props: {onChange: (r: string[]) => void, value?: string[]}){
    const [roles, setRoles] = useState(props.value?.map(x => { return {
        value: x, label: x
    }}));
    const roleChange = (roles: any) => {
        setRoles(roles)
        if(!roles){
            props.onChange([])
        }else {
            props.onChange(roles.map((x: any) => x.value))
        }
    }
    const options = [{value: 'ApiAccess', label: 'ApiAccess'}, {value: 'TenantAdmin', label: 'TenantAdmin'}];
    return <Select placeholder="Select Roles" isMulti={true} value={roles} onChange={roleChange} options={options}/>
}