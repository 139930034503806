import {InvoiceService} from "../../Services/InvoiceService";
import React, {CSSProperties, useEffect, useRef, useState} from "react";
import _, {orderBy} from "lodash";
import AsyncSelect from "react-select/async";
import {SupplierService} from "../../Services/SupplierService";
import {PackSizeInvoiceItem, PackSize} from "../../Domain/PackSize";
import {SaveButton} from "../../Components/Buttons";
import {UomSelect} from "../../Components/UomSelect";
import {Uom} from "../../Domain/UOMService";
import {InvoiceEntryNumberInput} from "../InvoiceWorkflow/WorkflowCommonComponents";

import {Link} from "react-router-dom";
import {useNavigate, useLocation} from "react-router";
import {SupplierSearch} from "../../Components/SupplierSearch";
import {Supplier} from "../../Domain/Supplier";
import {Icon} from "../../Components/Icon";
import {DisplayServices} from "../../Services/DisplayServices";

const packSizeMergeGridStyle = {
    display: "grid",
    gridTemplateColumns: "30px auto",
    gridTemplateRows: "30px 70px auto",
    gridTemplateAreas:
        "'. .'" +
        "'. inputs'" +
        "'. packSizeDetail'",
    columnGap: 3,
    rowGap: 10,
    height: '100%',
    minHeight: 0,
};

const packSizeInputGridStyle = {
    display: "grid",
    gridArea: "inputs",
    gridTemplateColumns: "1fr 2fr 1fr 1fr",
    gridTemplateRows: "auto",
    gridTemplateAreas:
        "'supplierInput descriptionInput itemCodeInput .'",
    columnGap: 3
};

const packSizeDetailGridStyle = {
    display: "grid",
    gridArea: "packSizeDetail",
    gridTemplateRows: "40px 50px auto 1fr 30px",
    gridTemplateAreas:
        "'detail'" +
        "'detailEdit'" +
        "'packsizeList'" +
        "'purchaseList'" +
        "'.'",
    columnGap: 3,
    rowGap: 7,
    height: '100%',
    minHeight: 0,
};

const packSizeDetailEditStyle = {
    display: "grid",
    gridArea: "detailEdit",
    gridTemplateColumns: "3fr 2fr 1fr 1fr auto",
    gridTemplateAreas:
        "'description itemCode catchWeight save .'",
    columnGap: 10
}

const purchaseListStyle = {
    gridArea: "purchaseList",
    gridTemplateColumns: "3fr 1fr",
    gridTemplateAreas:
        "'purchaseListHeader .'" +
        "'purchaseListDetail .'" +
        "'. .'",
    gridTemplateRows: "50px auto 30px",
    height: '100%',
    minHeight: 0
}

const spanStyle = {
    marginRight: '10px'
}

const largeBoldStyle = {
    fontSize: '20px',
    fontWeight: 'bold' as 'bold'
}

const smallFaintStyle = {
    fontSize: '10px',
    color: 'grey',
    marginRight: '0'
}

const inputStyle = {
    margin: '0 5px',
    width: '100%'
}

const cbStyle = {
    margin: '22px 8px',
}

const cbLabelStyle = {
    marginLeft: '8px',
}

const buttonStyle = {
    marginTop: '13px'
}

const purchaseHeaderStyle = {
    display: "grid",
    gridArea: "purchaseListHeader",
    gridTemplateColumns: "77px 120px 77px 77px 77px 100px 100px 100px 2fr auto",
    gridTemplateRows: "auto",
    gridTemplateAreas:
        "'. . . . . . casePriceFilterLabel casePriceFilter . .'" +
        "'select delivery pack size uom quantity total caseprice view .'",
    columnGap: 3
}

const textStyle: CSSProperties = {
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: 'lightGrey'

}
const casePriceFilterStyle: CSSProperties = {
    textAlign: 'right',

}
const casePriceInputStyle: CSSProperties = {
    width: '100px'
}
const casePriceFilterLabelStyle: CSSProperties = {
    textAlign: 'right',
    fontWeight: 'bold',

}

const purchaseListDetail = {
    display: "grid",
    gridArea: "purchaseListDetail",
    gridTemplateColumns: "77px 120px 77px 77px 77px 100px 100px 100px 2fr auto",
    gridTemplateRows: "auto",
    gridTemplateAreas: "'select delivery pack size uom quantity total caseprice view .'",
    columnGap: 3,
}

const centerTextStyle: CSSProperties = {
    textAlign: 'center'

}
const moneyStyle: CSSProperties = {
    textAlign: 'right'
}

const inputStyleToUse = {
    ...{
        outerWidth: 'auto',
        backgroundColor: '',
        color: '',
        paddingLeft: 3,
        height: '38px',
        border: '1px solid black'
    }
};

const packSizeListGridStyle = {
    display: "grid",
    gridArea: "packsizeList",
    gridTemplateColumns: "100px 100px 200px 150px 150px 150px 150px 150px auto",
    gridTemplateAreas:
        "'pack size uom save view merge mergeSel mergePurch .'",
    columnGap: 10,
    padding: '10px 0',
    borderBottom: '1px solid lightGrey'
};

const buttonStyleToUse = {
    width: "150px",
    marginLeft: "10px",
    marginTop: "24px"
}

export function PacksizeMerge() {

    const location = useLocation();
    const selectSupplier = (x?: Supplier) => {
        setSupplierId(x?.id);
    }

    const searchParams = new URLSearchParams(location.search);
    const [supplierId, setSupplierId] = useState<any>(searchParams.get("supplierId") || '');
    const [itemCode, setItemCode] = useState<any>(searchParams.get("itemCode") || '');

    const selectItem = (x: any) => {
        if (x != null) {
            setItemCode(x.value);
            setDesc(x.label);
        }
    }

    const [desc, setDesc] = useState<any>();
    const selectDesc = (x: any) => {
        if (x != null) {
            setItemCode(x.value);
            setDesc(x.label);
        }
    }

    const [packSizes, setPacksizes] = useState<PackSize[]>([]);
    const history = useNavigate();

    const getProductData = async () => {
        setPacksizes([]);
        if (supplierId && itemCode) {
            history({search: `?supplierId=${supplierId}&itemCode=${itemCode}`,}, {
                replace: true,
            })
        } else {
            history({
                search: undefined
            }, {replace: true})
        }
        let data = await SupplierService.getPacksizes(supplierId, itemCode);
        setPacksizes(data);
    }

    useEffect(() => {
        getProductData();
    }, [itemCode, desc])

    return <div style={packSizeMergeGridStyle}>
        <div style={packSizeInputGridStyle}>
            <div>
                <label>Supplier</label>
                <SupplierSearch onChange={selectSupplier} supplier={supplierId} region={'USA'}/>
            </div>
            <div>
                <label>Description</label>
                <AsyncSelect value={desc} onChange={selectDesc} isClearable={true} isSearchable={true} loadOptions={async (x: string) => {
                    return (await SupplierService.searchItemDesc(supplierId, x)).map(s => {
                        return {value: s.itemCode, label: s.description}
                    })
                }}/>
            </div>
            <div>
                <label>Item Code</label>
                <AsyncSelect value={itemCode} onChange={selectItem} isClearable={true} isSearchable={true} loadOptions={async (x: string) => {
                    return (await SupplierService.searchItemCode(supplierId, x)).map(s => {

                        return {value: s.itemCode, label: s.itemCode}
                    })
                }}/>
            </div>
        </div>

        {packSizes && packSizes.length > 0 &&
        <PacksizeDetail data={packSizes}/>}
    </div>
}

function PacksizeDetail(props: { data: PackSize[] }) {
    const [packSizeArray, setPackSizeArray] = useState<PackSize[]>(props.data)
    const [firstRow, setFirstRow] = useState<PackSize>();
    const [isCatchWeight, setIsCatchWeight] = useState(firstRow?.isCatchWeight || false);
    const [desc, setDesc] = useState<string>('');
    const [itemCode, setItemCode] = useState<string>('');
    const [packMap, setPackMap] = useState<PackSize[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);

    const [purchaseList, setPurchaseList] = useState<PackSizeInvoiceItem[]>([]);
    const [purchaseListSortCol, setPurchaseListSortCol] = useState('deliveryDate');
    const [purchaseListSortColDir, setPurchaseListSortColDir] = useState<'asc' | 'desc'>('asc');
    const [selectedPurchases, setSelectedPurchases] = useState<PackSizeInvoiceItem[]>([])
    const [showMergePurchases, setShowMergePurchases] = useState(false);

    const [casePriceFrom, setCasePriceFrom] = useState<number>(0);
    const [casePriceTo, setCasePriceTo] = useState<number>(0);
    const [selectedPackSizeId, setSelectedPackSizeId] = useState<string>('');
    const [loadingData, setLoadingData] = useState(false);

    const updateProduct = async () => {
        let supplierId = firstRow?.supplierId as string;
        let oldItemCode = firstRow?.itemCode as string;
        let cw = isCatchWeight || false

        await SupplierService.updateProductInfo(supplierId, oldItemCode, itemCode as string, desc as string, cw);
        refreshData();

    };

    const refreshData = async () => {
        const originalPurchaseList = purchaseList;
        setPackSizeArray([]);
        setPurchaseList([]);
        let supplierId = firstRow?.supplierId as string;
        let data = await SupplierService.getPacksizes(supplierId, itemCode as string)
        setPackSizeArray(data);
        setFirstRow(data[0]);
        if(originalPurchaseList.length){
            await onViewPurchases(originalPurchaseList[0].packSizeId);
        }
    }

    const onDoMerge = async (fromIndex: number, toIndex: number) => {
        if (packMap != null) {
            let fromPack = Array.from(packMap)[fromIndex];
            let toPack = Array.from(packMap)[toIndex];
            await SupplierService.mergePackSizes(fromPack.id as string, toPack.id as string)
            refreshData();
            setSelectedIndex(-1);
        }
    };

    const onSaveProduct = async (packSizeId: string, pack: number, size: number, uom: Uom) => {
        await SupplierService.updatePackSize(packSizeId, pack, size, uom);
        refreshData();
    }

    const onViewPurchases = async (packSizeId: string) => {
        setSelectedPackSizeId(packSizeId);
        setLoadingData(true);
        let data = await InvoiceService.getInvoicesWithPackSizes(packSizeId);
        setLoadingData(false);

        if (casePriceFrom) {
            data = data.filter(row => row.casePrice >= casePriceFrom);
        }
        if (casePriceTo) {
            data = data.filter(row => row.casePrice <= casePriceTo);
        }

        setPurchaseList(data);
    }

    const onPurchaseSelected = (row: PackSizeInvoiceItem) => {
        if (selectedPurchases.find(e => e.externalInvoiceId === row.externalInvoiceId && e.packSizeId === row.packSizeId && e.deliveryDate === row.deliveryDate)) {
            selectedPurchases.removeItem(row);
        } else {
            selectedPurchases.push(row);
        }

        setSelectedPurchases(selectedPurchases);
        setShowMergePurchases(selectedPurchases.length > 0);
    }

    const onMergePurchases = async (packSizeId: string) => {
        await InvoiceService.mergePurchases(packSizeId, selectedPurchases)
        refreshData();
        setSelectedPurchases([]);
        setShowMergePurchases(false);
    }

    const onFilterCasePrice = (fromCasePrice: number, toCasePrice: number) => {
        setCasePriceFrom(fromCasePrice);
        setCasePriceTo(toCasePrice);
    }

    useEffect(() => {
        if (selectedPackSizeId !== '') {
            onViewPurchases(selectedPackSizeId);
        }
    }, [casePriceFrom, casePriceTo])

    useEffect(() => {
        setFirstRow(packSizeArray[0]);

        let lodash = _.groupBy(packSizeArray, function (b) {
            return `${b.pack}/${b.size} ${b.uom}`
        });

        let array = _.toArray(lodash);
        let packArray: PackSize[] = [];
        array.forEach(row => {
            packArray.push(row[0])
        });

        setPackMap(packArray);
        if (packSizeArray.length > 0) {
            setIsCatchWeight(packArray[0].isCatchWeight);
        }

    }, [packSizeArray])

    useEffect(() => {
        setPackSizeArray([]);
        setPurchaseList([]);
        setPackSizeArray(props.data);

    }, [props.data])

    useEffect(() => {
        setDesc(firstRow?.description || '');
        setItemCode(firstRow?.itemCode || '');
    }, [firstRow])
    
    const changeHeader = (header: string) => {
        if(header == purchaseListSortCol){
            setPurchaseListSortColDir(purchaseListSortColDir == 'asc' ? 'desc' : 'asc' )
        }else{
            setPurchaseListSortCol(header);
            setPurchaseListSortColDir('asc')
        }
    }
    
    const orderedPurchases = orderBy(purchaseList, purchaseListSortCol, purchaseListSortColDir);

    return <div style={packSizeDetailGridStyle}>
        <div style={{gridArea: "detail"}}>
            <span style={{...spanStyle, ...largeBoldStyle}} className="desc">{firstRow?.description}</span>
            <span style={{...spanStyle, ...smallFaintStyle}} className="supplier">({firstRow?.supplierName})</span>
            <span style={{...spanStyle, ...smallFaintStyle}} className="itemCode">({firstRow?.itemCode})</span>
        </div>
        <div style={packSizeDetailEditStyle}>

            <div><label style={inputStyle}>Description</label><input type="text" style={inputStyle} onChange={x => setDesc(x.target.value)} name="packsizeDesc" value={desc}/></div>
            <div><label style={inputStyle}>Item Code</label><input type="text" style={inputStyle} onChange={x => setItemCode(x.target.value)} name="packsizeItemCode" value={itemCode}/></div>
            <div><label style={cbLabelStyle}>Catch Weight</label><input style={cbStyle} type='checkbox' checked={isCatchWeight} onChange={x => setIsCatchWeight(x.target.checked)}/></div>
            <div><SaveButton style={buttonStyle} onClick={updateProduct}>Update Product</SaveButton></div>
        </div>
        <div style={{gridArea: "packsizeList"}}>
            <h1>Pack Sizes</h1>
            {packMap && Array.from(packMap.values()).map((packSize, index) =>
                <PacksizeRow row={packSize} index={index} key={index} selectedIndex={selectedIndex} onSelectIndex={setSelectedIndex} onDoMerge={onDoMerge} onSaveProduct={onSaveProduct}
                             onViewPurchases={onViewPurchases} showMergeSelected={showMergePurchases} onMergePurch={onMergePurchases}/>
            )}

        </div>
        <div style={purchaseListStyle}>
            <PurchaseRowHeader onFilterCasePrice={onFilterCasePrice} headerClicked={changeHeader}/>
            <div style={{overflowY: 'auto', height: '100%', minHeight: 0}}>
                {loadingData && <div>
                    Loading Data...
                </div>}
                {orderedPurchases && orderedPurchases.length > 0 && orderedPurchases.map((purchase, index) =>
                    <PurchaseRow row={purchase} onItemSelected={onPurchaseSelected} key={purchase.externalInvoiceId}/>
                )}
            </div>
        </div>
    </div>
}

function PurchaseRowHeader(props: { onFilterCasePrice: any, headerClicked: (s: string) => void }) {

    const {onFilterCasePrice} = props;
    const [casePriceFrom, setCasePriceFrom] = useState<any>(undefined);
    const [casePriceTo, setCasePriceTo] = useState<any>(undefined);

    const handleOnBlur = (x: any) => {
        if (onFilterCasePrice) {
            let fromPrice = 0;
            let toPrice = 0;
            if (casePriceFrom) {
                fromPrice = parseFloat(casePriceFrom);

            }
            if (casePriceTo) {
                toPrice = parseFloat(casePriceTo);

            }

            onFilterCasePrice(fromPrice, toPrice);
        }
    }

    return <div style={purchaseHeaderStyle}>
        <div style={{...casePriceFilterLabelStyle, gridArea: "casePriceFilterLabel"}}> Case Price</div>
        <div style={{...casePriceFilterStyle, gridArea: "casePriceFilter"}}>
            <input style={casePriceInputStyle} value={casePriceFrom} onChange={x => setCasePriceFrom(x.target.value)} onBlur={handleOnBlur}/>
            <input style={casePriceInputStyle} value={casePriceTo} onChange={x => setCasePriceTo(x.target.value)} onBlur={handleOnBlur}/>
        </div>
        <div style={{...textStyle, gridArea: "select"}}> Select</div>
        <div style={{...textStyle, gridArea: "delivery", cursor: 'pointer'}} onClick={() => props.headerClicked("deliveryDate")}>Delivery Date</div>
        <div style={{...textStyle, gridArea: "pack", cursor: 'pointer'}} onClick={() => props.headerClicked("pack")}>Pack</div>
        <div style={{...textStyle, gridArea: "size", cursor: 'pointer'}} onClick={() => props.headerClicked("size")}>Size</div>
        <div style={{...textStyle, gridArea: "uom", cursor: 'pointer'}} onClick={() => props.headerClicked("uom")}> UOM</div>
        <div style={{...textStyle, gridArea: "quantity", cursor: 'pointer'}} onClick={() => props.headerClicked("quantity")}> Quantity</div>
        <div style={{...textStyle, gridArea: "total", cursor: 'pointer'}} onClick={() => props.headerClicked("total")}> Total</div>
        <div style={{...textStyle, gridArea: "caseprice", cursor: 'pointer'}} onClick={() => props.headerClicked("casePrice")}>Case Price</div>
        <div style={{gridArea: "view"}}></div>
    </div>
}

const dollarUsLocale = Intl.NumberFormat('en-US', {
    style: "currency",
    currency: "USD",
});

function PurchaseRow({row, onItemSelected}: { row: PackSizeInvoiceItem, onItemSelected: any }) {

    const [hasDiff, setHasDiff] = useState(false)
    const [qty, setQty] = useState<number | undefined>(row.quantity)
    
    const handleChange = () => {
        if (onItemSelected) {
            onItemSelected(row);
        }
    }
    
    useEffect(() => {
        setHasDiff(row.quantity !== qty);
    }, [qty, row])
    

    const url = `/invoiceViewer/${(encodeURIComponent(row.invoiceId))}`
    
    const saveChanges = async () => {
        row.quantity = qty!;
        await InvoiceService.updateLineItem(row);
        setHasDiff(false);
    }

    return <div style={purchaseListDetail}>
        <div style={{...centerTextStyle, gridArea: "select"}}><input type='checkbox' onChange={handleChange}/></div>
        <div style={{...centerTextStyle, gridArea: "delivery"}}>{DisplayServices.date(row.deliveryDate)}</div>
        <div style={{...centerTextStyle, gridArea: "pack"}}>{row.pack}</div>
        <div style={{...centerTextStyle, gridArea: "size"}}>{row.size}</div>
        <div style={{...centerTextStyle, gridArea: "uom"}}> {row.uom}</div>
        <div style={{...centerTextStyle, gridArea: "quantity"}}> 
            <InvoiceEntryNumberInput onChange={setQty} value={qty} />
            {hasDiff && <span>O: {row.quantity} <Icon name="save" onClick={saveChanges}/></span>}
        </div>
        <div style={{...moneyStyle, gridArea: "total"}}> {dollarUsLocale.format(row.total as number)}</div>
        <div style={{...moneyStyle, gridArea: "caseprice"}}>{dollarUsLocale.format(row.casePrice as number)}</div>
        <div style={{gridArea: "view"}}><Link to={url} target="_blank">View</Link></div>
    </div>
}

function PacksizeRow(props: { row: PackSize, index: number, selectedIndex: number, onSelectIndex: any, onDoMerge: any, onSaveProduct: any, onViewPurchases: any, showMergeSelected: boolean, onMergePurch: any }) {
    const [uom, _setUom] = useState<Uom | undefined>(props.row.uom);
    const setUom = (n?: Uom) => {

        if (n != undefined) {
            props.row.uom = n;
            _setUom(n);
        }
    };

    const [pack, _setPack] = useState(props.row.pack);
    const setPack = (n?: number) => {
        if (n != undefined) {
            props.row.pack = n;
            _setPack(n);
        }
    };

    const [size, _setSize] = useState(props.row.size);
    const setSize = (n?: number) => {
        if (n != undefined) {
            props.row.size = n;
            _setSize(n);
        }
    };

    const saveProduct = async () => {
        props.onSaveProduct(props.row.id as string, pack, size, uom);
    };

    const viewPurchases = async () => {
        props.onViewPurchases(props.row.id);
        props.onSelectIndex(props.index);
    };

    const mergeSelected = async () => {
        props.onDoMerge(props.selectedIndex, props.index);
    };

    const mergePurch = async () => {
        props.onMergePurch(props.row.id);
    }

    const onMerge = async () => {
        if (props.selectedIndex != props.index) {
            props.onSelectIndex(props.index);
        } else {
            props.onSelectIndex(-1);
        }
    };

    return <div style={packSizeListGridStyle}>
        <div style={{gridArea: "pack"}}><label>Pack</label> <InvoiceEntryNumberInput style={inputStyleToUse} placeholder="Pack" value={pack} onChange={setPack} required={true}/></div>
        <div style={{gridArea: "size"}}><label>Size</label> <InvoiceEntryNumberInput style={inputStyleToUse} placeholder="Size" value={size} onChange={setSize} required={true}/></div>
        <div style={{gridArea: "uom"}}><label>UOM</label> <UomSelect uom={uom} onChange={setUom} required={true}/></div>
        <div style={{gridArea: "save"}}><SaveButton style={buttonStyleToUse} onClick={saveProduct}>Save Product</SaveButton></div>
        <div style={{gridArea: "view"}}><SaveButton style={buttonStyleToUse} onClick={viewPurchases}>View Purchases</SaveButton></div>
        <div style={{gridArea: "merge"}}>
            {(props.selectedIndex === -1 || props.index == props.selectedIndex) &&
            <SaveButton style={buttonStyleToUse} onClick={onMerge}>Merge</SaveButton>
            }
        </div>
        <div style={{gridArea: "mergeSel"}}>
            {props.selectedIndex !== -1 && props.selectedIndex !== props.index &&
            <SaveButton style={buttonStyleToUse} onClick={mergeSelected}>Merge Selected</SaveButton>
            }
        </div>
        <div style={{gridArea: "mergePurch"}}>
            {props.showMergeSelected === true && props.index !== props.selectedIndex &&
            <SaveButton style={buttonStyleToUse} onClick={mergePurch}>Merge Purchases</SaveButton>
            }
        </div>
    </div>
}
