import {addNewCallback, InvoiceService, updateCallbackRegistration, useCallbackRegistrations} from "../../Services/InvoiceService";
import React, {useEffect, useState} from "react";
import {CallbackRegistration} from "../../Domain/Callbacks/CallbackRegistration";
import {DisplayServices} from "../../Services/DisplayServices";
import TimeAgo from 'react-timeago';
import {Callback, CallbackTry} from "../../Domain/Callbacks/Callback";
import {Colors} from "../../Colors";
import {Icon} from "../../Components/Icon";
import {Card} from "../../Components/Card";
import {BeButton, CancelButton, ExpandButton} from "../../Components/Buttons";
import {Css} from "../../Css";
import {Loading} from "../../Components/Loading";
import moment from "moment";
import {orderBy} from "lodash";
import {MonoText} from "../../Components/Texts";
import {UserService} from "../../Services/UserService";
import {TenantSelect} from "../../Components/Selects";
import {Tenant} from "../../Domain/User";

export function CallbackHistory() {
    const [url, setUrl] = useState("");
    const [callbackType, setCallbackType] = useState("Invoice");
    const isValid = !!(url && url.match(/^https?:\/\/[^\s$.?#].[^\s]*$/i));
    const [tenant, setTenant] = useState<Tenant>();
    const user = UserService.user;
    const callbackRegs = useCallbackRegistrations(tenant);
    const isLoading = nullOrUndef(callbackRegs);
    const hasRegistrations = callbackRegs?.length !== 0;
    const _addNewCallback = async () => {
        if (isValid) {
            await addNewCallback(url, callbackType);
        } else {
            alert(`There was a problem with the url provided The url you provided was '${url}. The url should match the pattern of http(s)://URL_HERE.DOMAIN/PATH/HERE`)
        }
    }
    
    const orderedCallbacks = orderBy(callbackRegs, [x => x.type, x => x.createdTime]);
    
    if(!tenant && user.isSuperAdmin) {
        return <div style={{height: '100%', minHeight: 0, overflowY: 'auto'}}>
            <div>
                Select a tenant
            </div>
            <TenantSelect tenant={tenant} setTenant={setTenant}></TenantSelect>
        </div>
    }
    
    return <div style={{height: '100%', minHeight: 0, overflowY: 'auto'}}>
        {user.isSuperAdmin && <div style={Css.textCenter()}>
            Tenant is: {tenant?.name} <CancelButton onClick={() => setTenant(undefined)}>Change Tenant</CancelButton>
        </div>}
        <div style={{width: '50vw', minWidth: 300, maxWidth: 1200, margin: "50px auto 0px auto", display: 'grid', gridTemplateRows: "auto 1fr"}}>
            <div style={{...Css.columns("1fr auto auto", 10), marginBottom: 10}}>
                <input type="text" value={url} onChange={x => setUrl(x.target.value)} style={Colors.validStyle(isValid)} placeholder="Callback URL"/>
                <BeButton onClick={_addNewCallback}>Add new callback</BeButton>
                <select value={callbackType} onChange={x => setCallbackType(x.target.value)}>
                    <option>Invoice</option>
                    <option>Grouping</option>
                    <option>PreInvoice</option>
                </select>
            </div>
            <Loading objToLoad={callbackRegs}/>
            {!isLoading && !hasRegistrations && "No registrations"}
            {!isLoading && hasRegistrations && <div>
                <div style={Css.rows("auto", 10)}>
                    {orderedCallbacks?.map(x => <CallbackRegRow key={x.id} reg={x}/>)}
                </div>
            </div>}
        </div>
    </div>
}

function CallbackRegRow(props: { reg: CallbackRegistration }) {
    const {reg} = props;
    const [showTries, setShowTries] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tries, setTries] = useState<Callback[]>();
    const [showOnlyFailed, setShowOnlyFailed] = useState(true);
    const [start, setStart] = useState(moment().subtract(7, "days").toDate());
    const [end, setEnd] = useState(moment().add(1, "day").toDate());
    
    useEffect(() => {
        if(showTries) {
            setTries(undefined);
            setIsLoading(true);
            InvoiceService.getTriesForRegistration(reg, showOnlyFailed, start, end).then(x => setTries(orderBy(x, x => x.createdTime, 'desc'))).finally(() => setIsLoading(false));
        }
    }, [reg, showTries, start, end, showOnlyFailed])
    
    const toggleActive = async () => {
        if (window.confirm(`Are you sure you want to mark ${reg.url} as ${reg.isActive ? 'inactive' : 'active'}`)) {
            reg.isActive = !reg.isActive;
            await updateCallbackRegistration(reg);
        }
    };
    
    const test = async () => {
        if(window.confirm(`Are you sure you want to send a test call to ${reg.url}. This call will only contain test data, and will not be recorded in the history of the callbacks`)){
            await InvoiceService.sendTest(reg);
            alert(`Callback sent to ${reg.url}`);
        }
    }
    const header = <div style={{display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center"}}>
        <div><b>({reg.type})</b> {reg.url}</div>
        <div>
            <Icon onClick={test} name="call"/>
            <Icon color={[Colors.success, Colors.danger]} onClick={toggleActive} name={["play_arrow", "stop"]} toggleCheck={reg.isActive}/>
        </div>
    </div>
    return <Card header={header}>
        Id: {reg.id} Created on {DisplayServices.date(reg.createdTime)} (<TimeAgo date={reg.createdTime.toDate()}/>)
        <ExpandButton style={{marginLeft: 10}} toggleValue={showTries} setToggleValue={setShowTries}>View callback tries</ExpandButton>
        {showTries && <div style={{paddingLeft: 30}}>
            <div>
                Show only failed: <input type="checkbox" checked={showOnlyFailed} onChange={x => setShowOnlyFailed(x.target.checked)}/>
                Start <input type="date" value={moment(start).format("YYYY-MM-DD")} onChange={x => setStart(moment(x.currentTarget.value).toDate())}/>
                End <input type="date" value={moment(end).format("YYYY-MM-DD")} onChange={x => setEnd(moment(x.target.value).toDate())}/>
            </div>
            {tries && tries?.length !== 0 && <div>
                {tries!.map(x => <CallbackInfo key={x.id} cb={x}/>)}
            </div>}
            {tries?.length == 0 && <div>No callback attempts for <i>{reg.url}</i></div>}
            {isLoading && <div>
                Loading callback data for {reg.url}
            </div>}
        </div>}
    </Card>;
}

function CallbackInfo(props: { cb: Callback }) {
    const [showData, setShowData] = useState(false)
    const {cb} = props;
    const lastTry = cb.tries[cb.tries.length - 1];
    const succeedFirstTry = cb.finished && cb.tries.length == 1 && cb.wasSuccessful;
    const failedAndNoMoreTries = cb.finished && cb.wasSuccessful === false;
    const failedButWillTryAgain = cb.finished == false && cb.wasSuccessful === false;
    const succeededTookMultipleTries = cb.finished && cb.tries.length > 1 && cb.wasSuccessful;
    
    const retry = async () => {
        let shouldDo = true;
        if (succeedFirstTry || succeededTookMultipleTries) {
            shouldDo = window.confirm("These requests have already been marked as succeeded. Are you sure you want to resend them? This could result in duplicate invoices in your system if your system isn't setup to handle the same data multiple times.")
        }
        if (shouldDo) {
            await InvoiceService.retryCallback(cb);
        }
    }
    
    let message = <span>
        Error on showing callback on <TimeAgo date={cb.createdTime.toDate()}/> (<TimeAgo date={lastTry.createdTime.toDate()}/>) with id {cb.id}
    </span>;
    
    if (failedAndNoMoreTries) {
        message = <span>
            <i style={{color: Colors.danger}} className="material-icons">error</i> Callback first try was <TimeAgo date={cb.createdTime.toDate()}/> 
            last try was {DisplayServices.datetime(lastTry.createdTime)} (<TimeAgo date={lastTry.createdTime.toDate()}/>) no more attempts will be made
        </span>
    }
    if (succeedFirstTry) {
        message = <span>
            <i style={{color: Colors.success}} className="material-icons">check</i> Callback succeeded first try {DisplayServices.datetime(lastTry.createdTime)}
        </span>
    }
    if (failedButWillTryAgain) {
        message = <span>
            <i style={{color: Colors.yellowWarning}} className="material-icons">warning</i> Callback first try was <TimeAgo date={cb.createdTime.toDate()}/> 
            last try was {DisplayServices.datetime(lastTry.createdTime)} (<TimeAgo date={lastTry.createdTime.toDate()}/>) more attempts will be made
        </span>
    }
    if (succeededTookMultipleTries) {
        message = <span>
            <i style={{color: Colors.success}} className="material-icons">notification_important</i> Callback failed first try {DisplayServices.datetime(lastTry.createdTime)} 
            but worked on {DisplayServices.datetime(lastTry.createdTime)} (<TimeAgo date={lastTry.createdTime.toDate()}/>)
        </span>
    }
    
    const orderedTries = orderBy(cb.tries, x => x.createdTime, 'desc');
    
    const copyRequestBody = (tri: CallbackTry) => {
        navigator.clipboard.writeText(tri.jsonSent);
    }

    return <div>
        {message}
        <button style={{marginLeft: 10}} onClick={retry}>Retry <Icon name="refresh"/></button>
        <ExpandButton style={{marginLeft: 10}} toggleValue={showData} setToggleValue={setShowData}></ExpandButton>
        {showData && <div style={{display: "grid", rowGap: 10}}>
            {orderedTries.map(x => <div key={x.id}>
                <div style={{backgroundColor: Colors.lightestGrey, padding: 5}}>
                    Tried @ {DisplayServices.datetime(x.createdTime)} (<TimeAgo date={x.createdTime.toDate()}/>)
                    <BeButton style={{marginLeft: 10}} onClick={() => copyRequestBody(x)}>Copy Request Body</BeButton>
                </div>
                <div style={{backgroundColor: Colors.lightestGrey, padding: 5}}>
                    Received Http Code: {x.resultCode} from <MonoText>{cb.callbackUrl}</MonoText>
                </div>
                <div style={{backgroundColor: Colors.lightGrey, padding: 5}}>
                    <div>
                        Response Body
                    </div>
                    <MonoText>{x.resultMsg}</MonoText>
                </div>
            </div>)}
        </div>}
    </div>
}