import React from 'react';
import ReactDOM from 'react-dom';
import {App} from "./App";
import {GlobalExtensions} from "./GlobalExtensions";
import {UserService} from "./Services/UserService";
import * as FullStory from '@fullstory/browser';
import {createRoot} from "react-dom/client";

const rootElement = document.getElementById('root');

GlobalExtensions.setupGlobalExtensions();

FullStory.init({orgId: 'KSR2'});

UserService.fillInUser().then(x => {
    createRoot(rootElement!).render(<App/>);
}).catch(e => {
    console.error(e);
    createRoot(rootElement!).render(<App/>);
});
