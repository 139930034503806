import {WorkflowNote} from "../../Domain/Workflow/Workflow";
import React, {useContext, useEffect, useState} from "react";
import {WorkflowService} from "../../Services/WorkflowService";
import {WorkflowContext} from "../../Contexts/WorkflowContext";

export function ConfirmCategoryOnlySidebar() {
    const [note, setNote] = useState("");
    const {workflow, updateWorkflow} = useContext(WorkflowContext);
    const [isCatOnlySaving, setIsCatOnlySaving] = useState(false);
    const [isPacksizeSaving, setIsPacksizeSaving] = useState(false);
    
    useEffect(() => {
        setNote("Process this for lines items");
    }, [workflow]);
    
    const saveCategoryOnly = () => {
        if (!isCatOnlySaving) {
            setIsCatOnlySaving(true);
            markAsCatOnly()
                .then(x => setIsCatOnlySaving(false));
        }
    };

    const savePacksize = () => {
        if (!isPacksizeSaving) {
            setIsPacksizeSaving(true);
            backToPackSize()
                .then(x => setIsPacksizeSaving(false));
        }
    };
    
    const markAsCatOnly = async () => {
        workflow!.stage = "Approved";
        workflow!.stageReason = "Category only invoice";
        await updateWorkflow(workflow!);
    };
    
    const backToPackSize = async () => {
        workflow!.stage = "ItemCodeAndPrice";
        workflow!.stageReason = "Workflow moved from CategoryOnly back to ItemCodeAndPrice stage"
        const newNote = new WorkflowNote();
        newNote.note = note;
        const noteAdded = await WorkflowService.addNewNote(workflow!, newNote!);
        workflow!.notes.push(noteAdded);
        await updateWorkflow(workflow!);
    };
    
    return <div>
        <div style={{display: "grid", padding: 5}}>
            <button onClick={saveCategoryOnly} disabled={isCatOnlySaving}>Confirm Category only</button>
        </div>
        <div style={{display: "grid", padding: 5, marginTop: 30, gridTemplateRows: "auto auto", rowGap: 10}}>
            <button disabled={!note || isPacksizeSaving} onClick={savePacksize}>Send back to Item codes and prices</button>
            <textarea value={note} onChange={x => setNote(x.target.value)} style={{width: '100%'}} rows={5}/>
        </div>
    </div>
}