import {AbstractDomainObject} from "./AbstractDomainObject";
import {orderBy} from "lodash";
import moment from "moment/moment";
import {Attachment} from "./Workflow/Workflow";

export class Restaurant extends AbstractDomainObject{
    tenantId: string | undefined;
    tenantName: string | undefined;
    name!: string;
    externalId!: string;
    id!: string;
    restaurantSuppliers?: RestaurantSupplier[];
    
    updateFromDTO(dto: any): this {
        super.updateFromDTO(dto);
        if(dto.restaurantSuppliers){
            this.restaurantSuppliers = this.toArray(dto.restaurantSuppliers, RestaurantSupplier);
            this.restaurantSuppliers = orderBy(this.restaurantSuppliers, x => x.supplierHouseName);
        }
        return this;
    }
}

export class RestaurantSupplier extends AbstractDomainObject{
    externalId: string | undefined;
    supplierId: string | undefined;
    supplierName: string | undefined;
    supplierHousePlatformId?: string;
    supplierHouseName?: string;
    supplierHouseZip?: string;
    accountNumber?: string;
}

export class UpdateRestaurantSupplierHouseRequest{
    restaurantId!: string;
    supplierId!: string;
    restaurantSupplierHouseExternalId!: string;
    supplierHouseId?: string;
    accountNumber?: string;

    constructor(restaurantId: string,
                supplierId: string,
                restaurantSupplierHouseExternalId: string,
                supplierHouseId?: string,
                accountNumber?: string) {
        this.restaurantId = restaurantId;
        this.supplierId = supplierId;
        this.restaurantSupplierHouseExternalId = restaurantSupplierHouseExternalId;
        this.supplierHouseId = supplierHouseId;
        this.accountNumber = accountNumber;
    }
}

export class UpdateRestaurantSupplierRequest{
    restaurantId!: string;
    supplierId!: string;
    supplierHouseId?: string;
    accountNumber?: string;
    updateRelatedInvoices?: boolean;

    constructor(restaurantId: string, supplierId: string, supplierHouseId?: string,
                accountNumber?: string, updatedRelatedInvoices?: boolean) {
        this.restaurantId = restaurantId;
        this.supplierId = supplierId;
        this.supplierHouseId = supplierHouseId;
        this.accountNumber = accountNumber;
        this.updateRelatedInvoices = updatedRelatedInvoices;
    }
}

export class UnverifiedRestaurantSupplierHouse extends AbstractDomainObject{
    tenantId!: string;
    tenantName!: string;
    restaurantId!: string;
    restaurantName!: string;
    restaurantSupplier!: RestaurantSupplier;
    attachments!: Attachment[];
}