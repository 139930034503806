import React, {FocusEventHandler, RefObject, useState} from "react";

type CategoryOnlyInputProps = {
    supplierId: string;
    rowData?: string;
    onChange?: (s?: string) => void;
    onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
    eRef?: RefObject<HTMLInputElement>;
    onFocus?: FocusEventHandler
    fieldType?: "code" | "description" | "total";
}

export function CategoryOnlyTextInput({
                                          rowData,
                                          onChange,
                                          onBlur,
                                          eRef,
                                          onFocus,
                                          fieldType
                                      }: CategoryOnlyInputProps) {
    const [value, _setValue] = useState(rowData);
    const setValue = (s?: string) => {
        _setValue(s);
        if (onChange) {
            onChange(s)
        }
    };

    return (
        <input type="text"
               value={value}
               onChange={x => setValue(x.target.value)}
               data-field-type={fieldType}
               onBlur={onBlur} ref={eRef} onFocus={onFocus}
               style={{width: "100%"}}/>
    );
}