import React, {useEffect, useRef, useState} from 'react';
import {Attachment} from '../../Domain/Workflow/Workflow';
import {RestaurantSupplierHouseSidebar} from './RestaurantSupplierHouseSidebar';
import {SaveButton} from '../../Components/Buttons';
import {WorkflowService} from '../../Services/WorkflowService';
import {UnverifiedRestaurantSupplierHouse} from "../../Domain/Restaurant";
import {RestaurantService} from "../../Services/RestaurantService";
import {CopyableText} from "../../Components/CopyableText";

export function RestaurantSupplierHouseWorkflowPage() {
    const [unverifiedHouse, setUnverifiedHouse] =
        useState<UnverifiedRestaurantSupplierHouse | null>();
    
    const getNextWorkflow = async (): Promise<void> => {
        // This is currently necessary to prevent optimistic concurrency errors
        // Time value should be the lowest value tested that mostly prevents the errors
        await new Promise(resolve => setTimeout(resolve, 10));

        const data = await RestaurantService.getNextUnverifiedRestSuppHouse();
        setUnverifiedHouse(data);
    };

    useEffect(() => {
        getNextWorkflow();
    }, []);

    useEffect(() => {
        setZoomPercent(100);
        setRotation(0);
    }, [unverifiedHouse])

    const [rotation, setRotation] = useState<number>(0);
    const [zoomPercent, setZoomPercent] = useState<number>(100);
    
    const keyDownHandler = (event: KeyboardEvent) => {
        let handled = false;
        if (event.altKey && event.key === "ArrowRight") {
            setRotation(x => x + 90);
            handled = true;
        } else if (event.altKey && event.key === "ArrowLeft") {
            setRotation(x => x - 90);
            handled = true;
        }
        if (event.altKey && event.key === "+") {
            setZoomPercent(x => x + 10);
            handled = true;
        }
        if (event.altKey && event.key === "-") {
            setZoomPercent(x => x - 10);
            handled = true;
        }
        if (handled) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    if (unverifiedHouse === undefined) {
        return <div>
            Loading...
        </div>
    }
    if (unverifiedHouse === null) {
        return <div>
            No more restaurant supplier houses to verify
        </div>
    }

    return <div style={{display: 'flex', minHeight: '100%'}}>
        <div style={{width: '400px', padding: 10}}>
            <RestaurantSupplierHouseSidebar unverifiedHouse={unverifiedHouse} getNextWorkflow={getNextWorkflow}/>
        </div>
        <div style={{flex: '1', overflowY: 'auto', padding: 10}}>
            <div style={{height: '100%'}}>
                <div>
                    <InfoSection display={unverifiedHouse.restaurantName}
                                 value={unverifiedHouse.restaurantId} label='Restaurant'/>
                </div>
                <div>
                    <InfoSection value={unverifiedHouse.restaurantSupplier.externalId}
                                 label='RestaurantSupplierHouse External ID'/>
                </div>
                <div>
                    <InfoSection display={unverifiedHouse.restaurantSupplier.supplierName}
                                 value={unverifiedHouse.restaurantSupplier.supplierId} label='Supplier'/>
                    <InfoSection display={unverifiedHouse.tenantName}
                                 value={unverifiedHouse.tenantId} label='Tenant (ID)'/>
                </div>
                <div style={{height: '100%'}}>
                    {unverifiedHouse.attachments.map(x => <AttachmentPicture attachment={x}
                        key={'attachment' + x.externalId}
                        rotation={rotation}
                        rotationChanged={() => setRotation(0)}
                        zoomPercentage={zoomPercent}/>)
                    }
                </div>
            </div>
        </div>
    </div>
}

function InfoSection({value, display, label}: { value?: string, display?: string, label?: string }) {
    return <span style={{margin: '0 5px', cursor: 'pointer'}}>
        <label style={{fontWeight: 'bold', margin: '0 5px'}}>{label}</label>
        <span id={`info-section-${label}`}>{display} - (<CopyableText text={value}/>)</span>
    </span>
}

export function AttachmentPicture(props: { attachment: Attachment,
    rotation: number,
    rotationChanged: () => void,
    zoomPercentage: number, 
    customStyle?: React.CSSProperties,
    handleAttachmentClick?: (attachment: Attachment) => void }) {
    
    const {attachment, rotation, rotationChanged, zoomPercentage, customStyle, handleAttachmentClick} = props;
    const isPdf = WorkflowService.isPdf(attachment);
    const imgRef = useRef<HTMLImageElement>(null);
    const [imgSource, setImgSource] = useState<string>(WorkflowService.getImgSource(attachment));
    const [imgCacheIndex, setImgCacheIndex] = useState<number>(0);
    const [origWidthInPixels, setOrigWidthInPixels] = useState<number>(0);
    const [origHeightInPixels, setOrigHeightInPixels] = useState<number>(0);
    const [widthInPixels, setWidthInPixelsToUse] = useState<number>(origWidthInPixels);
    const [heightInPixels, setHeightInPixelsToUse] = useState<number>(origHeightInPixels);
    const [marginTop, setMarginTop] = useState<any>()
    const [marginLeft, setMarginLeft] = useState<any>()
    const [heightToUse, setHeightToUse] = useState<any>("auto")
    const [widthToUse, setWidthToUse] = useState<any>("100%")
    const [zoomChanged, setZoomChanged] = useState(false)
    const [layoutChanged, setLayoutChanged] = useState(false)
    
    useEffect(() => {
        if (imgRef.current?.height && !origHeightInPixels) {
            setOrigHeightInPixels(imgRef.current?.height ?? 0);
            setHeightInPixelsToUse(imgRef.current?.height ?? 0);

        }
        if (imgRef.current?.width && !origWidthInPixels) {
            setOrigWidthInPixels(imgRef.current?.width ?? 0);
            setWidthInPixelsToUse(imgRef.current?.width ?? 0);
        }
        setZoomChanged(!zoomChanged);

    }, [zoomPercentage]);

    useEffect(() => {
        if (imgRef.current?.height && !origHeightInPixels) {
            setOrigHeightInPixels(imgRef.current?.height ?? 0);
            setHeightInPixelsToUse(imgRef.current?.height ?? 0);
        }

        if (imgRef.current?.width && !origWidthInPixels) {
            setOrigWidthInPixels(imgRef.current?.width ?? 0);
            setWidthInPixelsToUse(imgRef.current?.width ?? 0);
        }

        if (widthInPixels && heightInPixels) {

            let marginAdjust = 1.0;
            marginAdjust = Math.abs(widthInPixels - heightInPixels) / 2;

            let thisRotation = Math.abs(rotation % 360);

            if (widthInPixels > heightInPixels) {
                setMarginTop((thisRotation == 90 || thisRotation == 270) ? (marginAdjust) + 'px' : 'auto');
                setMarginLeft((thisRotation == 90 || thisRotation == 270) ? '-' + (marginAdjust) + 'px' : 'auto');
            } else {
                setMarginTop((thisRotation == 90 || thisRotation == 270) ? '-' + (marginAdjust) + 'px' : 'auto');
                setMarginLeft((thisRotation == 90 || thisRotation == 270) ? (marginAdjust) + 'px' : 'auto');
            }
        }
    }, [rotation, layoutChanged]);

    useEffect(() => {
        if (origWidthInPixels && origHeightInPixels) {
            setWidthInPixelsToUse(origWidthInPixels * (zoomPercentage / 100));
            setHeightInPixelsToUse(origHeightInPixels * (zoomPercentage / 100));
            setLayoutChanged(!layoutChanged);
        }
    }, [zoomChanged]);

    useEffect(() => {
        if (origHeightInPixels && origWidthInPixels) {
            if (widthInPixels > heightInPixels) {
                setWidthToUse((rotation == 90 || rotation == -90) ? 'auto' : (widthInPixels) + 'px');
                setHeightToUse((rotation == 90 || rotation == -90) ? (heightInPixels) + 'px' : 'auto');

            } else {
                setHeightToUse((rotation == 90 || rotation == -90) ? (heightInPixels) + 'px' : 'auto');
                setWidthToUse((rotation == 90 || rotation == -90) ? 'auto' : (widthInPixels) + 'px');

            }
            setLayoutChanged(!layoutChanged);
        }

    }, [widthInPixels, heightInPixels, rotation]);

    useEffect(() => {
        setImgSource(WorkflowService.getImgSource(attachment, imgCacheIndex));
    }, [imgCacheIndex, attachment]);

    const saveRotation = async () => {
        await WorkflowService.updateImgRotation(attachment.externalId, rotation);
        rotationChanged();
        setImgCacheIndex(imgCacheIndex + 1);
        setOrigWidthInPixels(0);
        setOrigHeightInPixels(0);
    };
    
    if (isPdf) {
        return <embed style={{width: '100%', height: '100%'}} src={imgSource} type="application/pdf"/>
    }
    
    return <div style={customStyle}>
        {rotation != 0 && <div>
            <SaveButton onClick={saveRotation}>Save Rotation</SaveButton>
        </div>}
        <img src={imgSource}
             ref={imgRef}
             alt={'missing or bad image'}
             onClick={() => handleAttachmentClick ? handleAttachmentClick(attachment) : null}
             style={{
                 transform: 'rotate(' + rotation + "deg)",
                 height: heightToUse,
                 width: widthToUse,
                 marginLeft: marginLeft,
                 marginTop: marginTop,
                 marginBottom: marginTop,
                 display: 'block',
             }}/>
    </div>
}