import {Colors} from "../../Colors";
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from "react-select/async";
import {SupplierService} from "../../Services/SupplierService";
import React, {useContext, useEffect, useState} from "react";
import {WorkflowService} from "../../Services/WorkflowService";
import {RestSupplier, Supplier, SupplierHouse} from "../../Domain/Supplier";
import {BeButton, DangerButton, SaveButton} from "../../Components/Buttons";
import Select from "react-select";
import {toSelectOption} from "../../Components/SelectUtils";
import {BadPictureModal} from "./WorkflowCommonComponents";
import {WorkflowContext} from "../../Contexts/WorkflowContext";
import {PlatformSupplier} from "../../Domain/PlatformSupplier";
import debounce from 'lodash/debounce';
import {orderBy} from "lodash";
import {Css} from "../../Css";
import {Address} from "../../Domain/Address";

const standardColumns = Css.columns("1fr 3fr", 10);

export function NewSupplierSidebar() {
    const [stage, setStage] = useState("selectSupplier");
    const [supplier, setSupplier] = useState<Supplier>();
    const [showBadPicModal, setShowBadPicModal] = useState(false);
    const {
        workflow,
        updateWorkflow,
        currentAttachment,
        updateAttachment
    } = useContext(WorkflowContext);
    const [supplierHouse, setSupplierHouse] = useState<SupplierHouse>();
    const [restaurantSuppliers, setRestaurantSuppliers] = useState<RestSupplier[]>();

    useEffect(() => {
        setSupplier(undefined)
        setShowBadPicModal(false)
        setStage("selectSupplier")
        setSupplierHouse(undefined);
        setRestaurantSuppliers([]);
        WorkflowService.getRestaurantSuppliers(workflow!.restaurantId!)
            .then(sups => setRestaurantSuppliers(orderBy(sups, x => x.name)));
    }, [workflow, currentAttachment])

    if (!workflow || (workflow.shouldProcessAsMultiInvoice && !currentAttachment)) {
        return <div></div>
    }

    const moveToBadPictureQ = async (reason: string) => {
        if (workflow.shouldProcessAsMultiInvoice && currentAttachment) {
            currentAttachment.badPictureReason = reason;
            currentAttachment.isBadPicture = true;
            setShowBadPicModal(false);
            updateAttachment(currentAttachment, workflow);
        } else {
            workflow.stage = "PictureIssue";
            workflow.level = 0;
            workflow.stageReason = reason;
            setShowBadPicModal(false);
            await updateWorkflow(workflow);
        }
    }

    const supplierSelected = (s: Supplier) => {
        setSupplier(s)

        const maybeSupplierAlreadyOnRestaurant = restaurantSuppliers?.find(x => x.id === s.id);
        if (maybeSupplierAlreadyOnRestaurant) {
            const supplierHouse = s.supplierHouses?.find(houseName => houseName.name === maybeSupplierAlreadyOnRestaurant.houseName);
            setSupplierHouse(supplierHouse);
            setStage("done");
            void handleUpdateDocument(s, supplierHouse);
            return;
        }

        if (s.platformId) {
            const restSupplier = restaurantSuppliers?.find(x => x.id === s.id)
            if (restSupplier?.housePlatformId) {
                if (restSupplier?.accountNumber) {
                    setStage("done");
                    void handleUpdateDocument(s);
                } else {
                    setStage("enterDCN");
                }
            } else {
                setStage("selectHouse");
            }
        } else {
            setStage("enterDCN");
        }
    }

    const houseSelected = (s?: SupplierHouse) => {
        setSupplierHouse(s);
        setStage("enterDCN");
    }

    const dcnEntered = async (s: string) => {
        if (s && s.length > 0) {
            if (workflow.shouldProcessAsMultiInvoice && currentAttachment) {
                currentAttachment.restaurantSupplierAccountNumber = s;
            } else {
                workflow.restaurantSupplierAccountNumber = s;
            }
        }
        setStage("done");
        await handleUpdateDocument(supplier!, supplierHouse);
    }

    const handleUpdateDocument = async (s: Supplier, sh?: SupplierHouse) => {
        if (!s) {
            alert("No supplier selected");
            return;
        }

        if (workflow.shouldProcessAsMultiInvoice && currentAttachment) {
            await _updateAttachmentForWorkflow(s, sh);
        } else {
            await _updateWorkflow(s, sh);
        }
    }

    const _updateWorkflow = async (s: Supplier, sh?: SupplierHouse) => {
        workflow!.supplierId = s.id;
        workflow!.supplierHouseId = sh?.id;
        workflow!.stage = "SupplierEntry";
        workflow!.stageReason = "Workflow updated from SupplierEntry";
        await updateWorkflow(workflow!);
    }

    const _updateAttachmentForWorkflow = async (s: Supplier, sh?: SupplierHouse) => {
        if (!currentAttachment) {
            return;
        }
        currentAttachment.supplierId = s.id;
        currentAttachment.supplierHouseId = sh?.id;
        currentAttachment.supplierName = s.name;
        updateAttachment(currentAttachment, workflow);
    };

    return <div>
        <div>
            <button onClick={() => setShowBadPicModal(true)}>Bad Picture</button>
        </div>
        <div>
            {stage === "selectSupplier" &&
                <SelectSupplier restSuppliers={restaurantSuppliers} supplierSelected={supplierSelected}/>}
            {stage === "selectHouse" && <SelectHouse supplier={supplier!} houseSelected={houseSelected}/>}
            {stage === "enterDCN" && <EnterDcn dcnEntered={dcnEntered}/>}
        </div>
        <BadPictureModal setShowBadPicModal={setShowBadPicModal} showBadPicModal={showBadPicModal}
                         onSet={moveToBadPictureQ}/>
    </div>
}

interface SelectSupplierProps {
    supplierSelected: (s: Supplier) => void;
    restSuppliers?: Supplier[];
}

function SelectSupplier({
                            supplierSelected,
                            restSuppliers,
                        }: SelectSupplierProps) {
    const {workflow, splitWorkflowImagesAndSendToMISupplierEntry} = useContext(WorkflowContext);
    const [supplierSearch, setSupplierSearch] = useState("");
    const [supplierSelectValue, setSupplierSelectValue] = 
        useState<{ value: Supplier, label: string }>();
    const [supplier, setSupplier] = useState<Supplier>();
    const [platformSupplier, setPlatformSupplier] = 
        useState<{ value: PlatformSupplier, label: string }>();

    const createNewSupplier = async () => {
        if (!supplierSelectValue) {
            alert("Tried to create a supplier with no information set. This should be impossible, please contact a developer");
            throw "Tried to create a supplier with no information set. This should be impossible, please contact a developer";
        }
        if (supplierSelectValue.value.id) {
            return supplierSelectValue.value;
        }
        const newSupplier = new Supplier();
        newSupplier.region = workflow!.region;
        newSupplier.name = supplierSelectValue!.label;
        return await WorkflowService.createNewSupplier(newSupplier);
    }

    const setSFSupplierToSupplier = async () => {
        let supplierToUse = supplier;
        if (!supplierToUse?.id) {
            supplierToUse = await createNewSupplier();
        }
        supplierToUse.supplierHouses = await SupplierService.setPlatformSupplier(platformSupplier!.value.platformId, supplierToUse);
        supplierToUse.platformId = platformSupplier!.value.platformId;
        setPlatformSupplier(undefined);
        alert(`Set platform ${platformSupplier!.value.platformId} to supplier: ${supplierToUse.name}`);
        supplierSelected(supplierToUse);
    }

    const noSFSupplier = async () => {
        if (!supplier?.id) {
            const newSupplier = await createNewSupplier();
            supplierSelected(newSupplier);
        } else {
            supplierSelected(supplier!);
        }
    }

    const handleSplitWorkflow = async () => {
        if (!window.confirm("Are you sure you want to split this workflow?")) {
            return;
        }

        await splitWorkflowImagesAndSendToMISupplierEntry(workflow!);
    }

    useEffect(() => {
        const action = async () => {
        }
        void action();
        setSupplierSearch("");
        setSupplierSelectValue(undefined);
        setSupplier(undefined);
        setPlatformSupplier(undefined);
    }, [workflow])

    const debouncedLookup = React.useCallback(
        debounce((x: string, callback: any) => {
            SupplierService.searchPlatformSuppliers(x)
                .then(x => x.map(s => 
                    toSelectOption(s, y => `${y.supplierName} - ${y.city}, ${y.state} ${y.zip}`)))
                .then(callback);
        }, 200),
        []
    );

    let restSuppliersToShow = restSuppliers;
    if (restSuppliers && supplierSearch?.length > 0) {
        restSuppliersToShow = restSuppliers?.filter(x => x.name.toLocaleLowerCase().indexOf(supplierSearch.toLowerCase()) > -1);
    }

    const existingSupplierChosen = async (supplier: Supplier) => {
        if (!supplier.id) {
            supplierSelected(supplier);
            return;
        }

        const completeSupplier = await SupplierService.getById(supplier.id)
        setSupplier(completeSupplier);
        supplierSelected(completeSupplier);
    }

    const supplierChosen = async (s: { value: Supplier | string, label: string } | Supplier) => {
        let supplierToUse;
        
        if (s instanceof Supplier) {
            supplierToUse = s;
        } else if (nullOrUndef(s?.value)) {
            return;
        } else {
            if (typeof(s.value) === 'string') {
                supplierToUse = new Supplier();
                supplierToUse.name = s.value;
                setSupplierSelectValue(s as any);
            } else {
                supplierToUse = s.value;
                setSupplierSelectValue(s as any);
            }
        }
        if (!supplierToUse.id) {
            setSupplier(supplierToUse);
            return;
        }
        const completeSupplier = await SupplierService.getById(supplierToUse.id)
        setSupplier(completeSupplier);
        if (completeSupplier.platformId) {
            supplierSelected(completeSupplier);
        }
    }

    if (!workflow) {
        return <div>Loading Workflow...</div>
    }

    const loadCreatableSelectOptions = async (searchTerm: string) => {
        let suppliersToShow = await SupplierService
            .searchSuppliers(searchTerm.trim(), workflow.region, undefined, true, 500);
        const lowered = searchTerm.toLocaleLowerCase();
        let filteredSuppliers = [...suppliersToShow.filter(s => s.name.toLocaleLowerCase().includes(lowered))
            .sort((a, b) => (b.restaurantCount! - a.restaurantCount!))];

        return filteredSuppliers
            .map(s => toSelectOption(s, x => `${x.name} - ${x.restaurantCount}`));
    };

    return <div style={{display: "grid", gridTemplateRows: "auto 75% auto"}}>
        {!workflow.restaurantId && <div style={Css.center()}>No restaurant</div>}
        {restSuppliersToShow && <div style={{display: 'grid'}}>
            <div style={Css.center()}>
                Existing Rest Suppliers
            </div>
            <div
                style={{
                    height: '25vh',
                    overflowY: "auto",
                    display: 'grid',
                    rowGap: 5,
                    columnGap: 5,
                    gridTemplateColumns: '1fr 1fr',
                    gridTemplateRows: 'auto'
                }}>
                {restSuppliersToShow.map((x, i) => <button
                    style={{maxHeight: 25, backgroundColor: supplier?.name === x.name ? Colors.lightBlue : ''}}
                    key={`${x.id}-${i}`}
                    onClick={() => existingSupplierChosen(x)}>{x.name}</button>)}
            </div>
        </div>}
        <div style={standardColumns}>
            <div>Select Supplier</div>
            <div><AsyncCreatableSelect onMenuClose={() => setSupplierSearch(supplierSearch)} value={supplierSelectValue}
                                       inputValue={supplierSearch} onInputChange={setSupplierSearch}
                                       onChange={supplierChosen as any} isClearable={true} isSearchable={true}
                                       loadOptions={loadCreatableSelectOptions}/>
            </div>
        </div>
        {supplier && !supplier.platformId && <div style={standardColumns}>
            <div>Search SF Dist</div>
            <div><AsyncSelect value={platformSupplier} onChange={(x: any) => setPlatformSupplier(x)}
                              isClearable={true} isSearchable={true} loadOptions={debouncedLookup}/>
            </div>
        </div>}
        {platformSupplier && <div>
            Are you sure you want to set the platform supplier {platformSupplier.value.supplierName} to be
            to be associated with {supplier!.name}. This is a permanent change, and should only be done
            if your positive this is the correct thing to do.
            <DangerButton onClick={setSFSupplierToSupplier}>Yes I want to set it</DangerButton>
        </div>}
        {supplier && !supplier.platformId && <div style={standardColumns}>
            <div>Not in SF</div>
            <div><DangerButton onClick={() => noSFSupplier()}>Not In SF</DangerButton></div>
        </div>}
        <div>
            <button onClick={handleSplitWorkflow}>Split MultiInvoice Workflow</button>
        </div>
    </div>
}

function SelectHouse({supplier, houseSelected}: { supplier: Supplier, houseSelected: (h?: SupplierHouse) => void }) {
    const [supplierHouse, setSupplierHouse] = useState<{ value: SupplierHouse, label: string }>();
    const [supplierHouses, setSupplierHouses] = useState<{ value: SupplierHouse, label: string }[]>();
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [addressObj, _] = useState<Address>(new Address());

    useEffect(() => {
        setSupplierHouses(supplier.supplierHouses?.map(x => toSelectOption(x, y => `${y.name} - ${y.zipCode}`)))
    }, [supplier]);

    useEffect(() => {
        if (supplierHouses && supplierHouses.length === 1) {
            setSupplierHouse(supplierHouses[0]);
        }
    }, [supplierHouses]);

    const handleAddressInput = async () => {
        if (addressObj.isValidAddress()) {
            await SupplierService.createNewHouse(supplier, addressObj);
        }
        houseSelected();
    }

    return <div>
        <div style={standardColumns}>
            <div>Select supplier house</div>
            <Select value={supplierHouse} onChange={x => setSupplierHouse(x!)} options={supplierHouses}/>
        </div>
        <div style={standardColumns}>
            <div>New</div>
            <div><DangerButton onClick={() => setShowCreateNew(true)}>Create new house</DangerButton></div>
        </div>
        {supplierHouse && <div style={standardColumns}>
            <div>Done</div>
            <div><BeButton onClick={() => houseSelected(supplierHouse.value)}>Use {supplierHouse.label}</BeButton></div>
        </div>}
        {showCreateNew && <div>
            <EnterAddress objToPutAddressOn={addressObj} handleAddressInput={handleAddressInput}/>
        </div>}
    </div>
}

function EnterDcn({dcnEntered}: { dcnEntered: (s: string) => void }) {
    const {workflow} = useContext(WorkflowContext);
    const [dcn, setDcn] = useState(workflow?.restaurantSupplierAccountNumber ?? "");

    useEffect(() => {
        setDcn(workflow?.restaurantSupplierAccountNumber ?? "");
    }, [workflow])

    return <div>
        <div style={standardColumns}>
            <div>DCN</div>
            <div style={Css.columns("1fr auto")}>
                <div><input type="text" value={dcn} onChange={x => setDcn(x.target.value)}
                            placeholder="DCN/Account Number" style={{width: '100%'}}/></div>
                <div>{!dcn && <BeButton onClick={() => setDcn("NONE")}>None</BeButton>}</div>
            </div>
        </div>
        {!dcn && <div style={Colors.validStyle(!!dcn)}>
            No DCN Supplied, are you sure this supplier doesn't have a customer number/dcn?
        </div>}
        {dcn && <div style={standardColumns}>
            <div>Save</div>
            <div><SaveButton onClick={() => dcnEntered(dcn)}>Save {dcn}</SaveButton></div>
        </div>}
    </div>
}

function EnterAddress({objToPutAddressOn, handleAddressInput}: { objToPutAddressOn: Address, handleAddressInput: () => void }) {
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("US");
    const [website, setWebsite] = useState("")
    const [phone, setPhone] = useState("");

    const done = () => {
        objToPutAddressOn.street = address;
        objToPutAddressOn.street2 = address2;
        objToPutAddressOn.city = city;
        objToPutAddressOn.state = state;
        objToPutAddressOn.zip = zip;
        objToPutAddressOn.country = country;
        objToPutAddressOn.website = website;
        objToPutAddressOn.phone = phone;
        handleAddressInput();
    }

    const noAddressDone = () => {
        handleAddressInput();
    }

    return <div style={{...standardColumns, rowGap: 5}}>
        <div>
            Address
        </div>
        <div>
            <input type="text" value={address} onChange={x => setAddress(x.target.value)} placeholder="Address"
                   style={{width: '100%'}}/>
        </div>
        <div>
            Address 2
        </div>
        <div>
            <input type="text" value={address2} onChange={x => setAddress2(x.target.value)} placeholder="Address 2"
                   style={{width: '100%'}}/>
        </div>
        <div>
            City
        </div>
        <div>
            <input type="text" value={city} onChange={x => setCity(x.target.value)} placeholder="City"
                   style={{width: '100%'}}/>
        </div>
        <div>
            State
        </div>
        <div>
            <select onChange={x => setState(x.currentTarget.value)}>
                <option value="">NO STATE SELECTED</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
            </select>
        </div>
        <div>
            Zip
        </div>
        <div>
            <input type="text" value={zip} onChange={x => setZip(x.target.value)} placeholder="Zip"
                   style={{width: '100%'}}/>
        </div>
        <div>
            Country
        </div>
        <div>
            <input type="text" value={country} onChange={x => setCountry(x.target.value)} placeholder="Country"
                   style={{width: '100%'}}/>
        </div>
        <div>
            Website
        </div>
        <div>
            <input type="text" value={website} onChange={x => setWebsite(x.target.value)} placeholder="Website"
                   style={{width: '100%'}}/>
        </div>
        <div>
            Phone
        </div>
        <div>
            <input type="text" value={phone} onChange={x => setPhone(x.target.value)} placeholder="Phone"
                   style={{width: '100%'}}/>
        </div>
        <div>
            None
        </div>
        <div>
            <DangerButton onClick={noAddressDone}>No Address</DangerButton>
        </div>
        <div>
            Done
        </div>
        <div>
            <BeButton onClick={done}>Done</BeButton>
        </div>
    </div>
}