import {Colors} from "../../Colors";
import React, {CSSProperties, useContext, useEffect, useMemo, useRef, useState} from "react";
import moment from "moment";
import {BadPictureModal, InvoiceEntryNumberInput} from "./WorkflowCommonComponents";
import {BeButton, CancelButton, DangerButton, SaveButton} from "../../Components/Buttons";
import {SupplierSearch} from "../../Components/SupplierSearch";
import {Supplier} from "../../Domain/Supplier";
import {useWorkflowContext} from "../../Contexts/WorkflowContext";
import {StandardModal} from "../../Components/Modal";
import {Css} from "../../Css";
import {SupplierService} from "../../Services/SupplierService";
import {UserService} from "../../Services/UserService";

export function SupplierEntrySideBar() {
    const {
        setAttachmentToBadPictureAndGetNextItem,
        moveToBadPictureQueue,
        workflow,
        checkForDuplicate,
        currentAttachment,
        updateAttachment,
        updateWorkflow,
        splitWorkflowImagesAndSendToMISupplierEntry,
    } = useWorkflowContext();
    const [showBadPicModal, setShowBadPicModal] = useState(false);
    let presetSupplier: any = null;
    const [supplier, setSupplier] = useState<Supplier | undefined>(presetSupplier);
    const [deliveryDate, setDeliveryDate] = useState<string | undefined>(currentAttachment?.deliveryDate?.format("YYYY-MM-DD") || '');
    const [invoiceNumber, setInvoiceNumber] = useState<string | undefined>(currentAttachment?.invoiceNumber || '');
    const [total, setTotal] = useState<number | undefined>(currentAttachment?.invoiceTotal);
    const [duplicates, setDuplicates] = useState<any[]>([]);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const supplierInputRef = useRef<any>();

    const checkDeliveryDate = () => {
        if (moment(deliveryDate).isBefore(moment().subtract(3, "years"))) {
            setDeliveryDate('');
            alert(`You put in the date ${deliveryDate}, this is more than 3 years in the past, this is not allowed. Please check the date you entered. Or send to error Q if the date really is more than 3 years ago`)
        }
    }

    useEffect(() => {
        clearFields();
        if (workflow?.shouldProcessAsMultiInvoice) {
            handleExtractDataFromAttachment();
        } else {
            handleExtractDataFromWorkflow();
        }
        
        if (supplierInputRef.current) {
            supplierInputRef.current.focus();
        }

        if (workflow?.stage === 'MISupplierEntry' && currentAttachment) {
            presetSupplier = {
                name: currentAttachment.supplierName,
                id: currentAttachment.supplierId
            }
        } else if (workflow) {
            presetSupplier = {
                name: workflow.supplierName,
                id: workflow.supplierId
            } 
        }
    }, [workflow, currentAttachment]);

    const handleExtractDataFromWorkflow = () => {
        if (workflow) {
            setDeliveryDate(workflow.deliveryDate?.format("YYYY-MM-DD") || '');
            setInvoiceNumber(workflow.invoiceNumber || '');
            setTotal(workflow.invoiceTotal);
            if (supplierInputRef.current) {
                supplierInputRef.current.focus();
            }
            if (workflow?.supplierId) {
                SupplierService.getById(workflow.supplierId).then(x => {
                    setSupplier(x);
                });
            }
        }
    }
    
    const handleExtractDataFromAttachment = () => {
        if (currentAttachment) {
            setDeliveryDate(currentAttachment.deliveryDate?.format("YYYY-MM-DD") || '');
            setInvoiceNumber(currentAttachment.invoiceNumber || '');
            setTotal(currentAttachment.invoiceTotal);
            if (currentAttachment?.supplierId) {
                SupplierService.getById(currentAttachment.supplierId).then(x => {
                    setSupplier(x);
                });
            }
            if (supplierInputRef.current) {
                supplierInputRef.current.focus();
            }
        }
    }

    const _updateWorkflowAndAttachment = async () => {
        setShowDuplicateModal(false);
        
        if (total! < 2 && total! > 0) {
            if (window.confirm(`The total (${total}) was set to a very low value are you sure the total is correct?`) == false) {
                return;
            }
        }
        if (total === 0) {
            if (window.confirm(`The total (${total}) was set to 0, are you sure the total is correct?`) == false) {
                return;
            }
        }
        if (moment(deliveryDate).isBefore(moment().subtract(3, "month"))) {
            if (window.confirm(`The Delivery date of ${deliveryDate} is older than three months, are you sure the delivery date is correct?`) === false) {
                return;
            }
        }
        if (moment(deliveryDate).isAfter(moment().add(1, "day"))) {
            if (window.confirm(`The Delivery date of ${deliveryDate} is in the future, are you sure the delivery date is correct?`) === false) {
                return;
            }
        }
        
        currentAttachment!.invoiceNumber = invoiceNumber ?? undefined;
        
        if (deliveryDate) {
            currentAttachment!.deliveryDate = moment(deliveryDate);
        }
        currentAttachment!.supplierId = supplier?.id;
        currentAttachment!.supplierName = supplier?.name;
        currentAttachment!.invoiceTotal = total;
        currentAttachment!.hasBeenReviewed = true;
        currentAttachment!.isNewSupplier = false;
        updateAttachment(currentAttachment!, workflow!);
        clearFields();
    };

    const _updateWorkflow = async () => {
        setShowDuplicateModal(false);
        if (nullUndefOrEmpty(invoiceNumber)) {
            alert("The invoice number was not set");
            return;
        }
        if (nullUndefOrEmpty(deliveryDate)) {
            alert("The delivery date was not set");
            return;
        }
        if (nullUndefOrEmpty(total)) {
            alert("The total was not set");
            return;
        }
        if (nullUndefOrEmpty(supplier?.id)) {
            alert("The supplier was not set");
            return;
        }
        if (total! < 2 && total! > 0) {
            if (window.confirm(`The total (${total}) was set to a very low value are you sure the total is correct?`) == false) {
                return;
            }
        }
        if (total === 0) {
            if (window.confirm(`The total (${total}) was set to 0, are you sure the total is correct?`) == false) {
                return;
            }
        }
        if (moment(deliveryDate).isBefore(moment().subtract(3, "month"))) {
            if (window.confirm(`The Delivery date of ${deliveryDate} is older than three months, are you sure the delivery date is correct?`) == false) {
                return;
            }
        }
        if (moment(deliveryDate).isAfter(moment().add(1, "day"))) {
            if (window.confirm(`The Delivery date of ${deliveryDate} is in the future, are you sure the delivery date is correct?`) == false) {
                return;
            }
        }

        workflow!.invoiceNumber = invoiceNumber;
        workflow!.deliveryDate = moment(deliveryDate);
        workflow!.supplierId = supplier!.id;
        workflow!.supplierName = supplier!.name;
        workflow!.invoiceTotal = total;
        workflow!.stage = "ItemCodeAndPrice";
        workflow!.stageReason = "Workflow moved to ItemCodeAndPrice stage from SupplierEntry";
        await updateWorkflow(workflow!);
    };
    
    const clearFields = () => {
        setSupplier(undefined);
        setDeliveryDate(undefined);
        setInvoiceNumber(undefined);
        setTotal(undefined);
    }

    const quickDateSelectors = []
    for (let i = 3; i >= 0; i--) {
        quickDateSelectors.push(moment().subtract(i, 'days').format("YYYY-MM-DD"));
    }

    const missingData = nullUndefOrEmpty(invoiceNumber) || nullUndefOrEmpty(total) || nullUndefOrEmpty(deliveryDate) || nullUndefOrEmpty(supplier);

    async function handleBadPicture(reason: string) {
        if (workflow?.shouldProcessAsMultiInvoice) {
            await handleBadAttachmentPicture(reason);
        } else {
            await moveToBadPictureQueue(workflow!, reason, setShowBadPicModal);
        }
    }
    
    const handleBadAttachmentPicture = async (reason: string) => setAttachmentToBadPictureAndGetNextItem(
        workflow!, currentAttachment!, reason, setShowBadPicModal
    );
    
    const handleSplitImages = async () => {
        if (!workflow) {
            return;
        }
        
        if (workflow.shouldProcessAsMultiInvoice && currentAttachment) {
            alert("This invoice has already been split into multiple invoices, please contact a developer if you think this is an error");
            return;
        }
        
        if (window.confirm("Are you sure you want to split this workflow into multiple invoices?")) {
            await splitWorkflowImagesAndSendToMISupplierEntry(workflow!);
        }
    }
    
    const handleMoveToNewSupplier = async () => {
        if (workflow?.shouldProcessAsMultiInvoice && currentAttachment) {
            await moveMultiInvoiceWorkflowToNewSupplier();
        } else {
            await moveWorkflowToNewSupplier();
        }
    }

    const moveWorkflowToNewSupplier = async () => {
        if (window.confirm(`Are you sure you want to move this to new supplier?`)) {
            workflow!.stage = "NewSupplier";
            workflow!.level = 0;
            workflow!.stageReason = `${UserService.user.username} moved to new supplier from Supplier Entry`;
            await updateWorkflow(workflow!);
        }
    }
    
    const moveMultiInvoiceWorkflowToNewSupplier = async () => {
        if (window.confirm(`Are you sure you want to move this to new supplier?`)) {
            currentAttachment!.isNewSupplier = true;
            updateAttachment(currentAttachment!, workflow!);
        }
    }

    const checkForDuplicateAndShowModal = async () => {
        setDuplicates([]);
        checkForDuplicate(workflow!).then(async (x) => {
            setDuplicates(x);
            const hasDuplicate = x.length > 0;
            setShowDuplicateModal(hasDuplicate);
            if (hasDuplicate === false) {
                await handleUpdateDocument();
            }
        })
    }
    
    async function handleUpdateDocument() {
        if (workflow?.shouldProcessAsMultiInvoice) {
            await _updateWorkflowAndAttachment();
        } else {
            await _updateWorkflow();
        }
    }

    const pageNumber = useMemo(() => getPageNumber(), [workflow, currentAttachment]);
    
    function getPageNumber() {
        if (!workflow || !currentAttachment) {
            return 1;
        }
        const index = workflow.attachments.indexOf(currentAttachment);
        return index === -1 ? 1 : index + 1;
    }

    if (!workflow || (workflow.shouldProcessAsMultiInvoice && !currentAttachment)) {
        return <div/>
    }

    let duplicateMessage = undefined;
    if (duplicates.length) {
        const anyDuplicate = duplicates[0];
        let duplicateReason = "Supplier";
        if (anyDuplicate.invoiceNumber != invoiceNumber) {
            duplicateReason = "Invoice Number"
        }
        if (anyDuplicate.deliveryDate != deliveryDate) {
            duplicateReason = "Delivery Date"
        }
        if (anyDuplicate.expectedTotal != total) {
            duplicateReason = "Total"
        }
        duplicateMessage = "We think this might be a potential duplicate invoice, please verify all the information before moving forward. Please check the " + duplicateReason;
    }

    const standardEntryStyle: CSSProperties = {
        display: 'grid',
        textAlign: "left",
        padding: 5
    };
    const sectionStyle: CSSProperties = {borderBottom: 'solid black 1px', padding: 5};
    const standardEntryRowStyle: CSSProperties = {
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        padding: 5,
        alignContent: "center",
        justifyContent: "space-between"
    };

    return <div style={{backgroundColor: Colors.lightGrey, padding: 4}}>
        <div>
            {currentAttachment && workflow.shouldProcessAsMultiInvoice &&<div style={{display: "flex", justifyContent: "space-between"}}>
                <span>Select Supplier:</span>
                {workflow.stage === 'MISupplierEntry' && <span>Page: {pageNumber} / {workflow.attachments.length}</span>}
            </div>}
            <SupplierSearch onChange={setSupplier} supplier={supplier} eRef={supplierInputRef}
                            restId={workflow.restaurantId} region={workflow!.region}/>
            <button onClick={handleMoveToNewSupplier}>New Supplier</button>
        </div>
        <div style={sectionStyle}>
            <div>Delivery Date</div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: quickDateSelectors.map(x => "1fr").join(" "),
                columnGap: 5,
                padding: 5
            }}>
                {quickDateSelectors.map(x => <BeButton onClick={() => setDeliveryDate(x)} key={x}>{x}</BeButton>)}
            </div>
            <div style={{textAlign: 'center', marginBottom: '5px'}}><input type="date" value={deliveryDate}
                                                                           onChange={x => setDeliveryDate(x.target.value)}
                                                                           onBlur={checkDeliveryDate}/></div>
        </div>

        <div style={sectionStyle}>
            <div style={{...standardEntryStyle}}>
                <div style={standardEntryRowStyle}>
                    <span>Invoice Number
                        <span style={{marginLeft: 4}}>
                            {deliveryDate &&
                                <button onClick={() => setInvoiceNumber(deliveryDate)}>Use Date</button>
                            }
                        </span>
                    </span>
                    <div>
                        <input type="text" value={invoiceNumber} onChange={x => setInvoiceNumber(x.target.value)}
                               placeholder={"Invoice Number?"}
                               style={{
                                   border: "none",
                                   backgroundColor: nullUndefOrEmpty(invoiceNumber) ? Colors.danger : '',
                                   color: nullUndefOrEmpty(invoiceNumber) ? Colors.dangerText : '',
                               }}/>                
                    </div>
                </div>
                <div style={standardEntryRowStyle}>
                    <span>Invoice Total
                        <span style={{marginLeft: 4}}>
                        </span>
                    </span>
                    <div>
                        <InvoiceEntryNumberInput value={total} onChange={setTotal} placeholder="Total?" required={true}/>
                    </div>
                </div>
            </div>
        </div>

        <div style={sectionStyle}>
            <div style={{...standardEntryStyle}}>
                <div style={standardEntryRowStyle}></div>
            </div>
        </div>
        <div style={{textAlign: "center", marginTop: 20, display: "grid", justifyContent: "center", gridTemplateColumns: "50%", gridTemplateRows: "auto auto", gap: 4}}>
            {missingData && <DangerButton disabled={missingData}>
                {nullUndefOrEmpty(supplier) && <div>Supplier Missing</div>}
                {nullUndefOrEmpty(deliveryDate) && <div>Delivery Date Missing</div>}
                {nullUndefOrEmpty(invoiceNumber) && <div>Invoice Number Missing</div>}
                {nullUndefOrEmpty(total) && <div>Total Missing</div>}
            </DangerButton>}
            {workflow.stage === 'MISupplierEntry' && <SaveButton onClick={checkForDuplicateAndShowModal}>Save</SaveButton>}
            {workflow.stage === 'SupplierEntry' && !missingData && <SaveButton onClick={checkForDuplicateAndShowModal}>Save</SaveButton>}
        </div>
        <div style={{
            textAlign: "center",
            marginTop: 10,
            marginBottom: 10,
            paddingBottom: 3,
            backgroundColor: Colors.danger,
            color: Colors.dangerText
        }}>
            Issue?
            <div style={{display: "grid", gridTemplateColumns: workflow.stage !== 'MISupplierEntry' ? "1fr 1fr" : ".50fr", columnGap: 5, justifyContent: "center"}}>
                {workflow.stage !== 'MISupplierEntry' && <button onClick={handleSplitImages}>Multiple Invoices</button>}
                <button onClick={() => setShowBadPicModal(true)}>Bad Picture</button>
            </div>
        </div>
        <BadPictureModal setShowBadPicModal={setShowBadPicModal} showBadPicModal={showBadPicModal}
                         onSet={handleBadPicture}/>
        <StandardModal shouldShow={showDuplicateModal} closeFn={setShowDuplicateModal} header="Possible duplicate"
                       footer={<div style={Css.columns('1fr 1fr', 10)}><CancelButton
                           onClick={() => setShowDuplicateModal(false)}>Go Back</CancelButton> <DangerButton
                           onClick={() => handleUpdateDocument()}>I've verified everything</DangerButton></div>}>
            <div style={{
                textAlign: "center",
                marginTop: 20,
                color: Colors.white,
                backgroundColor: Colors.danger,
                padding: 5
            }}>
                {duplicateMessage}
            </div>
        </StandardModal>
    </div>
}
