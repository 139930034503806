import React from "react";
import './loading.css'
import {Css} from "../Css";

export function Loading(props: { objToLoad?: any }) {
    if (props.objToLoad) {
        return null;
    }
    return <div style={Css.center()}>
        <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
}