import {WorkflowContext} from "../../Contexts/WorkflowContext";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Colors} from "../../Colors";
import {BadPictureModal, InvoiceEntryNumberInput} from "./WorkflowCommonComponents";
import {SaveButton} from "../../Components/Buttons";
import {CategoryOnlyWorkflowRow} from "../../Domain/Workflow/Workflow";
import {DisplayServices} from "../../Services/DisplayServices";
import {CategoryOnlyTextInput} from "../../Components/CategoryOnlyTextInput";

export function GlCodeAndCatOnlySidebar() {
    const {workflow, updateWorkflow, addCategoryOnlyRow, moveToBadPictureQueue, stage} = useContext(WorkflowContext);
    const [changeCounter, setChangeCounter] = useState(0);
    const [rowToAutoFocus, setRowToAutoFocus] = useState(0);
    const [showBadPicModal, setShowBadPicModal] = useState(false);
    const [taxes, _setTaxes] = useState(workflow!.taxes);
    const [deliveryCharge, _setDeliveryCharge] = useState(workflow!.deliveryCharge);
    const [otherCharge, _setOtherCharge] = useState(workflow!.otherCharge);
    const [expectedTotal, _setExpectedTotal] = useState(workflow!.invoiceTotal);
    const [hasNormalRowInformation, setHasNormalRowInformation] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const setValuesFromWorkflow = () => {
        if (!workflow) {
            return;
        }

        _setTaxes(workflow.taxes || 0);
        _setDeliveryCharge(workflow.deliveryCharge || 0);
        _setOtherCharge(workflow.otherCharge || 0);
        _setExpectedTotal(workflow.invoiceTotal || 0);
        setHasNormalRowInformation(workflow?.rows.some(r => r.rowId));
    }

    useEffect(() => setRowToAutoFocus(0), [workflow]);

    useEffect(() => setValuesFromWorkflow(), [workflow]);

    if (!workflow) {
        return <div>Loading...</div>;
    }

    if (workflow.categoryOnlyRows.length === 0) {
        workflow.addNewCategoryOnlyRow();
    }

    const setTaxes = (value?: number) => {
        _setTaxes(value);
        workflow!.taxes = value;
    };

    const setDeliveryCharge = (value?: number) => {
        _setDeliveryCharge(value);
        workflow!.deliveryCharge = value;
    };

    const setOtherCharge = (value?: number) => {
        _setOtherCharge(value);
        workflow!.otherCharge = value;
    };

    const setExpectedTotal = (value?: number) => {
        _setExpectedTotal(value);
        workflow!.invoiceTotal = value;
    };

    const saveAndApproveCategoryOnlyWorkflow = () => {
        if (!isSaving) {
            setIsSaving(true);
            _updateWorkflow()
                .then(x => setIsSaving(false));
        }
    };
    
    const _updateWorkflow = async () => {
        if (workflow.categoryOnlyRows.length === 0 && !workflow.totalsMatch) {
            const isUserCertainTotalsAreFine = window.confirm(`Are you sure this Invoice does not have any Item Rows (it may not, but double check)?`);
            if (!isUserCertainTotalsAreFine) {
                return;
            }
        }

        workflow.stage = "Approved";
        workflow.stageReason = `Workflow approved from ${stage}`;
        await updateWorkflow(workflow);
    };

    const changed = () => void setChangeCounter(changeCounter + 1);
    
    const checkForLastRowHit = (r: CategoryOnlyWorkflowRow) => {
        if (r.rowNumber == workflow!.categoryOnlyRows.length - 1) {
            addCategoryOnlyRow();
        }
    };

    const canSave = () => workflow.categoryOnlyRows.every(r => workflow.stage === 'GlCode' 
        ? !!(r.code && r.total || !r.code && !r.total) 
        : !!((r.code && r.total || r.description && r.total) || (!r.code && !r.total && !r.description)));

    const handleMoveToBadPictureQueue = async (reason: string) => await moveToBadPictureQueue(workflow!, reason, setShowBadPicModal);

    const handleClearNormalRowInformation = () => {
        workflow.rows = [];
        setHasNormalRowInformation(false);
    }

    return (
        <div style={{
            display: "grid",
            gridTemplateAreas: "'header' 'body' 'footer'",
            gridTemplateRows: 'auto 1fr auto',
            height: '100%',
            backgroundColor: Colors.lightGrey,
            padding: '0 3px',
            borderRight: 'solid ' + Colors.grey + ' 1px',
            minHeight: 0
        }}>
            <div style={{gridArea: 'header', paddingBottom: 3, borderBottom: 'black solid 1px'}}>
                <div style={workflow.stage === 'GlCode' ? glCodeEntryStyle : categoryOnlyEntryStyle}>
                    <div style={{gridArea: 'code', textAlign: "center"}}>Code</div>
                    {workflow.stage === 'CategoryOnly' && <div style={{gridArea: 'description', textAlign: "center"}}>Description</div>}
                    <div style={{gridArea: 'total', textAlign: "center"}}>Total</div>
                </div>
            </div>
            <div style={{
                gridArea: 'body',
                overflowY: 'auto',
                height: '100%',
                paddingBottom: 20,
                boxSizing: 'border-box',
            }}>
                {workflow.categoryOnlyRows.map(x => <CategoryOnlyItemRow row={x}
                                                                             isGlCode={workflow.stage === "GlCode"}
                                                                             key={'glcoderow' + x.rowId}
                                                                             changed={changed}
                                                                             autofocus={x.rowNumber == rowToAutoFocus}
                                                                             field={"total"}
                                                                             onFocus={checkForLastRowHit}/>)
                    }
                <SpacerRow/>
                <div style={{fontSize: 12, display: "flex", flexFlow: "column nowrap", gap: 4}}>
                    <div>
                        <div>Taxes</div>
                        <InvoiceEntryNumberInput onChange={setTaxes} value={taxes} placeholder="Taxes"/>
                    </div>
                    <div>
                        <div>Delivery Charge</div>
                        <InvoiceEntryNumberInput onChange={setDeliveryCharge} value={deliveryCharge}
                                                 placeholder="Delivery Charge"/>
                    </div>
                    <div>
                        <div>Other Charges</div>
                        <InvoiceEntryNumberInput onChange={setOtherCharge} value={otherCharge}
                                                 placeholder="Other Charges"/>
                    </div>
                    <div>
                        <div>Expected Total</div>
                        <InvoiceEntryNumberInput onChange={setExpectedTotal} value={expectedTotal}
                                                 placeholder="Expected Total"/>
                    </div>
                </div>
                <div style={{gridArea: 'footer'}}>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                        gap: 8,
                        justifyContent: "space-evenly",
                        marginTop: 8,
                    }}>
                        <div>
                            <button style={{height: 32}} onClick={() => setShowBadPicModal(true)}>Bad Picture</button>
                        </div>
                        <SaveButton disabled={!canSave() || isSaving} onClick={saveAndApproveCategoryOnlyWorkflow}>{isSaving ? 'Saving...' : 'Save'}</SaveButton>
                    </div>
                    <SpacerRow/>
                    {hasNormalRowInformation &&
                        <div style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: Colors.danger,
                            color: "white"
                        }}>
                            <div>This workflow has ItemCode & Price Information</div>
                            <button onClick={() => handleClearNormalRowInformation()}>Clear Items</button>
                        </div>
                    }
                    <SpacerRow/>
                    {workflow.categoryOnlyRows.length > 0 && !workflow.categoryOnlyRows.every(r => !r.total && !r.code) && !workflow.totalsMatch &&
                        <div style={{backgroundColor: Colors.danger, padding: 10, color: "white"}}>
                            Warning: Totals do not match.
                            <div
                                style={{textAlign: "right"}}>Expected: {DisplayServices.money(workflow.invoiceTotal)}</div>
                            <div style={{textAlign: "right", textDecoration: 'underline'}}>-
                                Actual: {DisplayServices.money(workflow.calculatedTotal)}</div>
                            <div
                                style={{textAlign: "right"}}>{DisplayServices.money(workflow.invoiceTotal! - workflow.calculatedTotal)}</div>
                        </div>
                    }
                </div>
                <BadPictureModal setShowBadPicModal={setShowBadPicModal} showBadPicModal={showBadPicModal}
                                 onSet={handleMoveToBadPictureQueue}/>
            </div>
        </div>
    )
}

export function CategoryOnlyItemRow(props: CategoryOnlyItemRowProps) {
    return <div>
        <CategoryOnlyItemFields {...props}/>
    </div>
}

export const categoryOnlyEntryStyle = {
    display: "grid",
    gridTemplateAreas: "'code description total'",
    gridTemplateColumns: 'auto 60% auto',
    columnGap: 3,
    margin: 5,
};

export const glCodeEntryStyle = {
    ...categoryOnlyEntryStyle,
    gridTemplateAreas: "'code total'",
    gridTemplateColumns: '75% auto',
};

interface CategoryOnlyItemRowProps {
    row: CategoryOnlyWorkflowRow;
    changed: () => void;
    autofocus: boolean;
    field: "total" | "description" | undefined;
    isGlCode: boolean;
    onFocus?: (row: CategoryOnlyWorkflowRow) => void;
}

export function CategoryOnlyItemFields({row, changed, autofocus, field, isGlCode = false, onFocus}: CategoryOnlyItemRowProps) {
    const {workflow} = useContext(WorkflowContext);
    const [glCode, _setCode] = useState<string | undefined>(row.code);
    const [description, _setDescription] = useState<string | undefined>(row.description);

    const totalInput = useRef<HTMLInputElement>(null);
    const glCodeInput = useRef<HTMLInputElement>(null);
    const descriptionInput = useRef<HTMLInputElement>(null);
    const setTotal = (n?: number) => {
        row.total = n;
        changed();
    };

    const setCode = (code?: string) => {
        row.code = code;
        _setCode(code);
        
        if (isGlCode) {
            setDescription(code);
        }
        
        changed();
    };

    const setDescription = (desc?: string) => {
        row.description = desc;
        _setDescription(desc);
        changed()
    };
    const doNothing = () => {
    };

    const onFocusToUse = onFocus || doNothing;

    const handleKeyDown: any = (e: KeyboardEvent, value: number, updateFn: (x: any) => void) => {
        const isNumberKey = isFinite(e.key as any);
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            e.preventDefault();
        } else if (isNumberKey && e.ctrlKey) {
            const valToUse = isFinite(value) ? value : "";
            updateFn(valToUse + "" + e.key);
            e.preventDefault();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (autofocus) {
            if (totalInput?.current && field === "total") {
                totalInput.current.focus();
            } else if (descriptionInput?.current && field === undefined) {
                descriptionInput.current.focus();
            }
        }
    }, [autofocus, field]);

    return (
        <div>
            <div style={isGlCode ? glCodeEntryStyle : categoryOnlyEntryStyle} id={'row' + row.rowNumber}
                 data-row-number={row.rowNumber}>
                <div style={{gridArea: "code"}}>
                    <CategoryOnlyTextInput supplierId={workflow!.supplierId!}
                                           onChange={setCode}
                                           rowData={glCode}
                                           eRef={glCodeInput}
                                           fieldType="code"
                                           onFocus={() => onFocusToUse(row)}/>
                </div>
                {!isGlCode && <div style={{gridArea: "description"}}>
                    <CategoryOnlyTextInput supplierId={workflow!.supplierId!}
                                           onChange={setDescription}
                                           rowData={description}
                                           eRef={descriptionInput}
                                           fieldType="description"
                                           onFocus={() => onFocusToUse(row)}/>
                </div>}
                <div style={{gridArea: "total"}}>
                    <InvoiceEntryNumberInput style={{gridArea: 'total'}}
                                             onChange={setTotal}
                                             value={row.total}
                                             placeholder="Total"
                                             eRef={totalInput}
                                             onFocus={() => onFocusToUse(row)}
                                             fieldType="total"
                                             required={true}/>
                </div>
            </div>
        </div>
    );
}

const SpacerRow = () => <div style={{height: 8}}/>;